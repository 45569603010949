import {
    ACondition,
    AFilter,
    AObjectService,
    CacheService,
    ConfigurationService,
    MetadataService,
    PlatformService,
} from '@congacommerce/core';
import { Injectable, Injector, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { EglCartLight } from '../../../../models/apttus/tables/cart/egl-cart-light';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    skipWhile,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';
import { CartService } from '@congacommerce/ecommerce';
import * as _ from 'lodash';
import { AptSalesProcess } from '../../../../enums/apttus/apt-sales-process';
import { AppInsightsService } from '../../../shared/app-insights.service';
import * as moment from 'moment';
import { cloneDeep, isNil } from 'lodash';
import { Store } from '@ngrx/store';
import { EglState } from '../../../../../store/reducers';
import { selectSalesProcess } from '../../../../../store/selectors/order-entry.selectors';
import { LoggerService } from '../../../shared/logger.service';

@Injectable({
    providedIn: 'root',
})
export class EglCartLightService extends AObjectService {
    aObject: any;
    type = EglCartLight;
    private _state: BehaviorSubject<EglCartLight>;

    constructor(
        cacheSrv: CacheService,
        plSrv: PlatformService,
        mtSrv: MetadataService,
        confSrv: ConfigurationService,
        inj: Injector,
        private appInsightsSrv: AppInsightsService,
        private ngZone: NgZone,
        private store: Store<EglState>,
        private logger: LoggerService
    ) {
        super(cacheSrv, plSrv, mtSrv, confSrv, inj);
        this._state = new BehaviorSubject(null);
    }

    globalFilter(): Observable<AFilter[]> {
        return of([
            new AFilter(this.type, [new ACondition(this.type, 'CreatedDate', 'GreaterThan', new Date('2020-05-18'))]),
        ]);
    }

    // Return cached currentCart
    currentCart(): Observable<EglCartLight> {
        return this._state.pipe(filter((c) => !isNil(c)));
    }

    refresh() {
        this.getMyCart().subscribe((c) => this.publish(c));
    }

    getMyCart(): Observable<EglCartLight> {
        const start = new Date().getTime();
        const cartId = CartService.getCurrentCartId() || 'active';

        return of(cartId).pipe(
            mergeMap((cartId) => {
                if (cartId === 'active') {
                    return of(null);
                }
                return this.where([new ACondition(this.type, 'Id', 'Equal', cartId)]);
            }),
            take(1),
            map((carts: EglCartLight[]) => (carts || [])[0] || null),
            tap(async (cart) => {
                if (cart) {
                    CartService.setCurrentCartId(cart.Id);
                }
                this.publish(cart);
            }),
            tap(() => {
                const end = new Date().getTime();
                this.appInsightsSrv.logMetric(`get-my-cart-light`, (end - start) / 1000);
            })
        );
    }

    /**
     * @param date: data di acquisto del prodotto, in caso di tempovarianza
     */
    updateCurrentCartPricingDate(date: Date): Observable<EglCartLight> {
        return this.getMyCart().pipe(
            switchMap((cart) => {
                cart.pricingDate = moment(date).format('YYYY-MM-DD');
                return this.updateAndPublish(cart);
            })
        );
    }

    updateSalesProcess(salesProcess: AptSalesProcess): Observable<EglCartLight> {
        return this.getMyCart().pipe(
            mergeMap((cart) => {
                if (cart?.egl_sales_process !== salesProcess) {
                    cart.egl_sales_process = salesProcess;
                    return this.updateAndPublish(cart);
                }
                return of(cart);
            })
        );
    }

    // /**
    //  * @description: Verifica se il carrello corrente è vuoto
    //  */
    // isCartEmpty(): Observable<boolean> {
    //     return this.cartSrv.getMyCart().pipe(map((cart) => _.isEmpty(cart.LineItems)));
    // }

    /**
     * @description Update cart sales process based on flowtype
     */
    updateCartSalesProcess(salesProcess: AptSalesProcess): Observable<EglCartLight> {
        return this.getMyCart().pipe(
            take(1),
            filter((cart) => !!cart && cart.egl_sales_process !== salesProcess),
            tap((cart) =>
                this.logger.info(`updating egl_sales_process: ${cart?.egl_sales_process} to ${salesProcess}`)
            ),
            map((cart) => Object.assign(cart, { egl_sales_process: salesProcess })),
            mergeMap((cart) => this.updateAndPublish(cart)),
            tap((outCart) => this.logger.info(`update cart egl_sales_process: ${outCart?.egl_sales_process} success`)),
            catchError((err) => {
                this.logger.error(err);
                return of(null);
            })
        );
    }

    updateAndPublish(cart: EglCartLight): Observable<EglCartLight> {
        return this.update([cart]).pipe(
            take(1),
            map(([updatedCart]) => updatedCart as EglCartLight),
            tap((updatedCart) => {
                this.publish(updatedCart);
            })
        );
    }

    private publish(cart: EglCartLight) {
        this.ngZone.run(() => this._state.next(cloneDeep(cart)));
    }
}
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../models/user-state';
import { AgentInfo, VirtualAgent } from '../../common/models/user/agent';
import { D365ChannelCode } from '../../common/enums/d365/d365-channel-code';
import { SelectContactLead } from '../models/select-contact-lead';
import { D365AgentType } from '../../common/enums/d365/d365-agent-type';
import { D365AgentProfileType } from '../../common/enums/d365/d365-agent-profile-type';
import { Contact } from '../../common/models/user/contact';
import { CustomerInfo } from '../../common/models/user/customer';
import { Lead } from '../../common/models/user/lead';

const getSelectFeature = createFeatureSelector<UserState>('user');
export const selectUserState = createSelector(getSelectFeature, (state: UserState) => state);
export const selectContact = createSelector(getSelectFeature, (state: UserState) => state.contact);
export const selectLead = createSelector(getSelectFeature, (state: UserState) => state.lead);
export const selectAgentInfo = createSelector(getSelectFeature, (state: UserState) => state.agentInfo);
export const selectFromCrm = createSelector(getSelectFeature, (state: UserState) => state.fromCrm);
export const selectCustomer = createSelector(getSelectFeature, (state: UserState) => state.customerInfo);
export const selectCurrentVirtualAgent = createSelector(selectAgentInfo, (agInfo: AgentInfo) => {
    return agInfo?.VirtualAgents?.find((x) => x.IsCurrentSelected === true);
});

export const selectChannelCode = createSelector(selectCurrentVirtualAgent, (va) => {
    if (va?.VirtualAgency) {
        return va.VirtualAgency.Channel.Code;
    }
    return '' as D365ChannelCode;
});

export const selectCurrentProfile = createSelector(
    selectCurrentVirtualAgent,
    selectAgentInfo,
    selectChannelCode,
    (selectedVa: VirtualAgent, currentAgInfo: AgentInfo, channelCode: D365ChannelCode) => {
        if (selectedVa && currentAgInfo) {
            return {
                selectedVirtualAg: selectedVa,
                currentAgentInfo: currentAgInfo,
                agentName: currentAgInfo.Agent.Name,
                agentCode: currentAgInfo.Agent.Code,
                agentType: currentAgInfo.Agent.AgentType as D365AgentType,
                channelCode,
                userProfile: currentAgInfo.Type as D365AgentProfileType,
            };
        }
        return null;
    }
);
export const selectIsSiebelCustomer = createSelector(getSelectFeature, (state: UserState) => !!state.isSiebelCustomer);

export const selectContactLead = createSelector(
    selectContact,
    selectLead,
    selectFromCrm,
    selectCustomer,
    selectIsSiebelCustomer,
    (
        contactState: Contact,
        leadState: Lead,
        fromCrmState: boolean,
        customerInfoState: CustomerInfo,
        isSiebelCustomer: boolean
    ): SelectContactLead => ({
        contact: contactState,
        lead: leadState,
        fromCrm: fromCrmState,
        customerInfo: customerInfoState,
        customer: contactState || leadState,
        isSiebelCustomer,
    })
);

export const selectEmailAndTel = createSelector(selectUserState, (state): EmailAndTelSelection => {
    if (state && state.contact) {
        return {
            email: state.contact.emailaddress1,
            tel: state.contact.mobilephone || state.contact.telephone1,
            prefix: state.contact.prefixMobilephone || state.contact.prefixTelephone1,
            phone2: state.contact.telephone2,
            preferredContact: state.contact.preferredContact,
        };
    }
    return null;
});

export const selectLastUsedSegment = createSelector(selectAgentInfo, (agentInfo: AgentInfo) => {
    if (agentInfo && agentInfo.UserConfiguration) {
        return agentInfo.UserConfiguration.LastUsedCustomerSegment;
    }
    return null;
});

export const selectCartSegment = createSelector(getSelectFeature, (agentInfo) => agentInfo.cartSegment);

export const selectUserProfile = createSelector(selectAgentInfo, (agentInfo: AgentInfo) =>
    agentInfo ? { profile: agentInfo.ProfileCode, canSell: agentInfo.CanSell } : null
);

export const selectCurrentAgentMail = createSelector(
    selectCurrentVirtualAgent,
    (currentVirtualAg: VirtualAgent) => currentVirtualAg.DefaultAgencyBranch.Email
);

export interface EmailAndTelSelection {
    email: string;
    tel: string;
    prefix: string;
    phone2: string;
    preferredContact: string;
}

import { FlowType } from '../../store/models/flow-type';

export const FLOW_TYPE_LABELS_MAP: {
    [key in FlowType]?: string;
} = {
    [FlowType.SwitchIn]: 'OPERATIVE_MODES.SWITCH_IN',
    [FlowType.SwitchInAmmVip]: 'OPERATIVE_MODES.SWITCH_IN_AMM_VIP',
    [FlowType.SwitchInAmmRecupero2A]: 'OPERATIVE_MODES.SWITCH_IN_AMM_RECUPERO',
    [FlowType.SwitchInPertinenza]: 'OPERATIVE_MODES.SWITCH_IN_PERTINENZA',
    [FlowType.SwitchInVoltura]: 'OPERATIVE_MODES.SWITCH_IN_VOLTURA',
    [FlowType.SwitchInAmmDelibera153]: 'OPERATIVE_MODES.SWITCH_IN_AMM_DEL153',
    [FlowType.SwitchInAmmRecupero2A]: 'OPERATIVE_MODES.SWITCH_IN_AMM_RECUPERO',
    [FlowType.SwitchInAmmRecupero2B]: 'OPERATIVE_MODES.SWITCH_IN_AMM_RECUPERO',
    [FlowType.SwitchInAmmRecupero2C]: 'OPERATIVE_MODES.SWITCH_IN_AMM_RECUPERO',

    [FlowType.CPCommerciale]: 'OPERATIVE_MODES.CHANGE_PROD_COMMERCIAL',
    [FlowType.CPDelibera]: 'OPERATIVE_MODES.CHANGE_PROD_RETROACTIVE',
    [FlowType.CPAmministrativo]: 'OPERATIVE_MODES.CHANGE_PROD_DELIBERATE',
    [FlowType.DomiciliazioneAttivazione]: 'OPERATIVE_MODES.DOMICILIATION_ATT',
    [FlowType.DomiciliazioneAttivazioneInserimentoIban]: 'OPERATIVE_MODES.DOMICILIATION_ATT',
    [FlowType.DomiciliazioneModifica]: 'OPERATIVE_MODES.DOMICILIATION_MOD',
    [FlowType.DomiciliazioneModificaInserimentoIban]: 'OPERATIVE_MODES.DOMICILIATION_MOD',
    [FlowType.DomiciliazioneRevoca]: 'OPERATIVE_MODES.DOMICILIATION_REV',
    [FlowType.Cessazione]: 'OPERATIVE_MODES.TERMINATION',
    [FlowType.Sospensione]: 'OPERATIVE_MODES.SUSPENSION',
    [FlowType.AnnullamentoSospensione]: 'OPERATIVE_MODES.SUSPENSION_CANCEL',
    [FlowType.VariazioneCommerciale]: 'OPERATIVE_MODES.VAR_COMM',
    [FlowType.ScontoStandalone]: 'OPERATIVE_MODES.SCONTO_STANDALONE',
    [FlowType.Interruzione]: 'OPERATIVE_MODES.INTERRUPTION',
    [FlowType.AnnullamentoInterruzione]: 'OPERATIVE_MODES.INTERRUPTION_CANCEL',
    // [FlowType.CambioProdotto]: 'OPERATIVE_MODES.CambioProdotto',
    [FlowType.Voltura]: 'OPERATIVE_MODES.VOLTURA',
    [FlowType.Attivazione]: 'OPERATIVE_MODES.ACTIVATION',
    [FlowType.AttivazioneGasConContatore]: 'OPERATIVE_MODES.ACTIVATION_GAS',
    [FlowType.AttivazioneGasSenzaContatore]: 'OPERATIVE_MODES.ACTIVATION_GAS',
    [FlowType.AttivazionePwrS01]: 'OPERATIVE_MODES.ACTIVATION_PWR',
    [FlowType.Riapertura]: 'OPERATIVE_MODES.RESUMING',
    [FlowType.AnnullamentoRiapertura]: 'OPERATIVE_MODES.RESUMING_CANCEL',
    [FlowType.VariazioneTecnicaVerifiche]: 'OPERATIVE_MODES.VARIAZIONE_TECNICA_VERIFICHE',
    [FlowType.VariazioneTecnicaLavori]: 'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI',
};

export enum D365OperationMode {
    SwitchIn = 100000000,
    CambioProdotto = 100000001,
    CambioProdottoDelibera = 100000002,
    CambioProdottoRetroattivo = 100000003,
    Domiciliazione = 100000004,
    Cessazione = 100000005,
    SwitchInConVoltura = 100000006,
    SwitchInAmmVip = 100000007,
    SwitchInAmmRecupero = 100000008,
    SwitchInAmmRipensamento = 100000009,
    Attivazione = 100000100,
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectFullState, selectSkipResidentialAddress } from '../../../../store/selectors/common.selectors';
import { catchError, map, mergeMap, share, take } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import {
    CartToQuoteRequest,
    Account,
    Address,
    Contact,
    IdentityDocumentInfo,
    CreditCheck,
    Privacy,
    BillingAccount,
    PaymentTool,
    ElectronicInvoicing,
    Products,
    ChannelAndAgency,
    InvalidCf,
    LeadAndCampaign,
    Signature,
    ExecutionType,
    AttachmentInfo,
    HolderPaymentTool,
    CommodityAppointment,
    CartToQuoteAppointment,
    ProductPrices,
} from '../../../models/apttus/request-response/cart-to-quote-request';
import { D365CustomerSegment } from '../../../enums/d365/d365-customer-segment';
import { PrivacyType } from '../../../enums/shared/privacy-question-type';
import { D365ConfirmationType } from '../../../enums/d365/d365-confirmation-type';
import {
    Appointment,
    CostRecord,
    OrderEntryState,
    ProductInfo,
    TermCosts,
} from '../../../../store/models/order-entry-state';
import { VirtualAgent } from '../../../models/user/agent';
import { getAgentD365toApt } from '../../../functions/remap.functions';
import { UtilityService } from '../../shared/utility.service';
import { D365ChannelCode } from '../../../enums/d365/d365-channel-code';
import { UserState } from '../../../../store/models/user-state';
import { Indirizzo } from '../../../../modules/switch-in/order-entry/models/indirizzi';
import {
    isChangeProduct,
    isDomiciliationStandAlone,
    isAdministrativeSwitchIn,
    isVariazioneCommerciale,
} from '../../../functions/verifications.functions';
import { AptQuoteStatus } from '../../../enums/apttus/apt-quote-status';
import { AptQuoteSubStatus } from '../../../enums/apttus/apt-quote-sub-status';
import { AptAddressType } from '../../../enums/apttus/apt-address-type';
import { AptPaymentInstrument } from '../../../enums/apttus/apt-payment-instrument';
import { EglState } from '../../../../store/reducers';
import { cleanObj, newGuid, parseToDate } from '../../../functions/misc.functions';
import { SaveQuoteProvider } from '../../../providers/save-quote.provider';
import { ApttusService } from '../apttus.service';
import { LoggerService } from '../../shared/logger.service';
import { LoadingService } from '../../shared/loading.service';
import { FeatureToggleService } from '../../shared/feature-toggle.service';
import { UserService, User, Cart, CartService } from '@congacommerce/ecommerce';
import { AptLineStatus } from '../../../enums/apttus/apt-line-status';
import { APT_BILL_TYPE_TO_DELIVERY_CHANNEL_QUOTE_MAP } from '../../../enums/apttus/apt-bill-type';
import { EglQuoteLight } from '../../../models/apttus/tables/quote/egl-quote-light';
import { TelemetryMetricService } from '../../app/telemetry-metric.service';
import { EglCartExtended } from '../../../models/apttus/tables/cart/egl-cart-extended';
import { CacheService } from '@congacommerce/core';
import { AptContactRole } from '../../../enums/apttus/apt-mb-representative';
import { selectFlowType, selectProductsInfo } from '../../../../store/selectors/order-entry.selectors';
import { setCombinedSale, setCommodityCartId } from '../../../../store/actions/order-entry.actions';
import { getFullAddressStringFromVals } from '../../../functions/string-format.functions';
import { reverseCf } from '../../../functions/cf-piva.functions';
import moment from 'moment';
import { FlowType } from '../../../../store/models/flow-type';
import { AttributeFactory } from './attribute-factory';
import { FullState } from '../../../../store/models/full-state';
import { EglSalesupStateService } from '../tables/egl-salesup-state.service';
import { AptDeliveryChannelQuote } from '../../../enums/apttus/apt-delivery-channel';
import { PrivateConfigurationService } from '../../shared/private-configuration.service';

@Injectable({
    providedIn: 'root',
})
export class CartToQuoteService {
    private pendingQuotes: { [key: string]: Observable<EglQuoteLight> } = {};
    constructor(
        private store: Store<EglState>,
        private utilitySrv: UtilityService,
        private quotePrv: SaveQuoteProvider,
        private apttusSrv: ApttusService,
        private logger: LoggerService,
        private toggleSrv: FeatureToggleService,
        private userSrv: UserService,
        private telemetrySrv: TelemetryMetricService,
        private cacheSrv: CacheService,
        private salesupStateSrv: EglSalesupStateService
    ) {}

    /**
     * Gestisce la dualita' acceso spento della nuova convertCartToQuote
     * @param type
     * @param cart
     * @returns
     */

    saveQuoteV2_Wrapper(type: 'default' | 'term' | 'cp' = 'default'): Observable<EglQuoteLight> {
        return this.store.select(selectFlowType).pipe(
            take(1),
            mergeMap((flowType) => {
                const isSaveQuoteV2Enabled = this.toggleSrv.config?.flowSaveQuoteV2Enabled?.some((r) =>
                    RegExp(r).test(flowType)
                );
                this.logger.info('calling saveQuoteV2_Wrapper. V2 enabled: ', false, isSaveQuoteV2Enabled);
                if (isSaveQuoteV2Enabled) {
                    return this.saveQuoteV2();
                }

                switch (type) {
                    case 'term':
                        return this.saveQuoteV1(this.apttusSrv.saveQuoteForTERM);
                    case 'cp':
                        return this.saveQuoteV1(this.apttusSrv.saveQuoteForCP);
                    default:
                        return this.saveQuoteV1(this.apttusSrv.saveQuote);
                }
            })
        );
    }

    private saveQuoteV1(fSaveQuote: () => void): Observable<null> {
        return this.salesupStateSrv.saveSupState(this.getCartId()).pipe(map(() => (fSaveQuote(), null)));
    }

    private saveQuoteV2(): Observable<EglQuoteLight> {
        const cartId = this.getCartId();
        this.pendingQuotes[cartId] =
            this.pendingQuotes[cartId] ||
            this.salesupStateSrv.saveSupState(cartId).pipe(
                LoadingService.loaderOperator('Creazione quote in corso'),
                mergeMap(() =>
                    this.getNewConvertCartToQuoteReq().pipe(
                        mergeMap((req) => this.quotePrv.createQuoteFromCart(req)),
                        mergeMap((response) => this.apttusSrv.finalizeFlow_obs(response)),
                        mergeMap((quote) => this.manageCombinedSales().pipe(map(() => quote))),
                        map((quote) => {
                            this.logger.info(
                                `saveQuote_v2. quoteId: ${quote?.Id} flowUnlock:${!!quote?.egl_flowunlock}`
                            );
                            console.table(quote);
                            this.cacheSrv.refresh(Cart);
                            CartService.deleteLocalCart();
                            this.apttusSrv
                                .createNewCart(new EglCartExtended(), null, false, null, false, false, false)
                                .toPromise();
                            return quote;
                        }),
                        catchError((err) => {
                            console.trace(err);
                            this.logger.error(null, 'saveQuote_V2 have an error', err, true);
                            delete this.pendingQuotes[cartId];
                            throw err;
                        }),
                        take(1),
                        this.telemetrySrv.rxTelemetry('saveQuote_v2')
                    )
                ),
                share()
            );

        return this.pendingQuotes[cartId];
    }

    manageCombinedSales(): Observable<void> {
        return this.store.select(selectProductsInfo).pipe(
            take(1),
            map((x) => x.find((prod) => prod?.configuration?.isCombinedSaleInsurance) !== undefined),
            map((combined) => {
                if (combined) {
                    this.store.dispatch(setCombinedSale({ combinedSale: true }));
                    this.store.dispatch(setCommodityCartId({ commodityCartId: this.getCartId() }));
                }
            })
        );
    }

    /**
     * @description: returns the request for new cart to quote
     */
    getNewConvertCartToQuoteReq(): Observable<Partial<CartToQuoteRequest>> {
        /**
         * IL PRIMO CHE AGGIUNGE SELETTORI A QUESTA COMBINELATEST SENZA UNA REALE NECESSITA'
         * LO VENGO A CERCARE DI NOTTE! LA REQUEST VIENE COSTRUITA SULLA BASE DELLO STATE.
         *
         * *******************************************************************************************
         * ***************** ☠ NON METTERE IN QUESTO MAPPER LOGICHE DI BUSINESS! ☠ *****************
         * *******************************************************************************************
         *
         * es1: in caso di una certa modalità operativa lo stato della quote deve essere sempre 'COMPLETATO'.
         *      NON è qui che devi mettere l'IF.
         */

        const loaderFinalizer = LoadingService.loaderOperator('Recupero dati in corso');

        return combineLatest([
            this.store.select(selectFullState),
            this.userSrv.me(),
            this.store.select(selectSkipResidentialAddress),
        ]).pipe(
            take(1),
            map(([fullState, owner, skipHomeAddr]) => {
                const { user, orderEntry, salesProcess, isDelayInsertion } = fullState;
                return cleanObj<CartToQuoteRequest>({
                    cartId: this.getCartId(orderEntry),
                    salesProcess: salesProcess,
                    quoteStatus: this.getQuoteStatus(orderEntry).quoteStatus,
                    quoteSubStatus: this.getQuoteStatus(orderEntry).quoteSubstatus,
                    account: this.getAccount(orderEntry, user, skipHomeAddr),
                    contact: this.getContact(orderEntry, user),
                    identityDocument: this.getIdentityDocumentInfo(orderEntry),
                    creditCheckDetails: this.getCreditCheck(orderEntry),
                    privacy: this.getPrivacy(orderEntry),
                    isWinBack: orderEntry.isWinBack,
                    winBackType: orderEntry.winBackType,
                    contractCode: this.getContractCode(orderEntry),
                    billingAccount: this.getBillingAccount(orderEntry, user),
                    electronicInvoicing: this.getElectronicInvoicing(orderEntry),
                    products: this.getProducts(fullState),
                    realEstateOwnership: orderEntry.realEstateOwnership,
                    channelAndAgency: this.getChannelAndAgency(orderEntry, user, owner),
                    invalidCf: this.getInvalidCf(orderEntry),
                    leadAndCampaign: this.getLeadAndCampaign(user),
                    signature: this.getSignature(orderEntry, isDelayInsertion),
                    executionType: this.getExecutionType(orderEntry),
                    attachments: this.getAttachments(orderEntry),
                    isMortisCausa: orderEntry.termination?.isMortisCausa,
                    partNumber: orderEntry.partNumber,
                    appointment: this.getAppointment(orderEntry),
                    combinedSaleQuoteId: orderEntry.combinedSaleQuoteId,
                    incidentId: orderEntry?.incident?.selectedCaseId,
                });
            }),
            loaderFinalizer
        );
    }
    private getCartId(orderEntry?: OrderEntryState): string {
        return orderEntry?.cartInfo?.cartId || CartService.getCurrentCartId();
    }

    private getContractCode(orderEntry: OrderEntryState): string {
        return orderEntry?.numeroPlico?.code || 'NOT_FOUND';
    }

    private getQuoteStatus(orderEntry: OrderEntryState): { quoteStatus: string; quoteSubstatus: string } {
        /**
         * NON aggiungere logiche qui dentro. Dispatchale nello state prima di chiamare il saveQuote.
         * in modo che
         *      erderEntry.quoteStateModel?.status
         *      orderEntry.quoteStateModel?.subStatus
         * abbia il valore che si vuole mappare nella request
         */
        return isChangeProduct(orderEntry.flowType)
            ? {
                  quoteStatus: AptQuoteStatus.Confermato,
                  quoteSubstatus: AptQuoteSubStatus.AttesaSottomissioneOrdine,
              }
            : {
                  quoteStatus: orderEntry.quoteStateModel?.status,
                  quoteSubstatus: orderEntry.quoteStateModel?.subStatus,
              };
    }

    private getSegment(user: UserState): D365CustomerSegment {
        return user?.cartSegment;
    }

    private getHomeAddress(orderEntry: OrderEntryState): Address {
        return orderEntry.indirizzi?.stessoIndirizzoResidenza
            ? this.getSupplyOrShippingAddress(orderEntry)
            : this.getAddress(orderEntry.indirizzi?.indirizzoResidenza);
    }

    private getCommunicationAddress(orderEntry: OrderEntryState): Address {
        return orderEntry.indirizzi?.stessoIndirizzoComunicazioni
            ? this.getSupplyOrShippingAddress(orderEntry)
            : this.getAddress(orderEntry.indirizzi?.indirizzoComunicazioni);
    }

    private getSupplyOrShippingAddress(orderEntry: OrderEntryState): Address {
        const addr = orderEntry.indirizzi?.indirizzoFornitura || orderEntry.indirizzi?.indirizzoSpedizione;
        return this.getAddress(addr);
    }

    private getCompanyAddress(orderEntry: OrderEntryState, user: UserState): Address {
        if (this.isMicroBusiness(user) && orderEntry.anagraficaMb) {
            return this.getAddress(orderEntry.anagraficaMb?.companyAddress);
        }
        return null;
    }

    private isMicroBusiness(user: UserState): boolean {
        return this.getSegment(user) === D365CustomerSegment.Microbusiness;
    }

    private isResidential(user: UserState): boolean {
        return this.getSegment(user) === D365CustomerSegment.Residential;
    }

    private getAccount(orderEntry: OrderEntryState, user: UserState, skipHomeAddr: boolean): Account {
        return {
            customerCode: user.contact?.egl_customercode,
            segment: this.getSegment(user),
            firstname: user.contact?.firstname,
            lastname: user.contact?.lastname,
            companyName: orderEntry.anagraficaMb?.companyName,
            isSiebelCustomer: !!user.isSiebelCustomer,
            taxCode: user.contact?.egl_taxcode,
            vatCode: user.contact?.egl_vatcode,
            emailAddress: user.contact?.emailaddress1,
            telephone1prefix: user.contact?.prefixMobilephone || user.contact?.prefixTelephone1,
            telephone1: user.contact?.mobilephone || user.contact?.telephone1,
            telephone2prefix: user.contact?.prefixTelephone2,
            telephone2: user.contact?.telephone2,
            companyAddress: this.getCompanyAddress(orderEntry, user),
            homeAddress: skipHomeAddr ? null : this.getHomeAddress(orderEntry),
            communicationAddress: this.getCommunicationAddress(orderEntry),
            companyLegalForm: orderEntry.anagraficaMb?.legalForm,
            companyAtecoCode: orderEntry.atecoMb?.codeAtecoPower || orderEntry.atecoMb?.codeAtecoGas,
            companyAtecoActivity: orderEntry.atecoMb?.attivita,
            companyAtecoSector: orderEntry.atecoMb?.settore,
            favoriteCommunicationChannel: user.contact?.preferredContact,
            oldTelephone1: orderEntry.termination?.termContacts[0]?.changedContact
                ? orderEntry.termination?.termContacts[0]?.oldContact
                : null,
            oldEmailAddress: orderEntry.termination?.termContacts[1]?.changedContact
                ? orderEntry.termination?.termContacts[1]?.oldContact
                : null,
        };
    }

    private getAddress(address: Indirizzo): Address {
        if (
            address?.toponomastica &&
            address?.via &&
            address?.civico &&
            address?.cap &&
            address?.comune &&
            address?.prov &&
            address?.codiceIstatComune
        ) {
            return {
                toponym: address.toponomastica,
                street: address.via,
                civic: address.civico,
                municipality: address.comune,
                shortProvince: address.prov,
                province: address.province,
                cap: address.cap,
                country: address.country || 'Italia',
                istatCodeProv: address.codiceIstatProvincia,
                istatCodeMunicipality: address.codiceIstatComune,
                civicEgonCode: address.codiceEgon,
                streetEgonCode: address.codiceEgonStreet,
                certified: !!address.codiceEgon || !!address.codiceEgonStreet,
                fullAddress: getFullAddressStringFromVals(
                    address.toponomastica,
                    address.via,
                    address.civico,
                    address.comune,
                    address.prov,
                    address.cap
                ),
            };
        }
    }

    private getContact(orderEntry: OrderEntryState, user: UserState): Contact {
        if (this.isMicroBusiness(user)) {
            return {
                type: orderEntry.anagraficaMb?.representativeType,
                firstname: orderEntry.anagraficaMb?.nameLegal,
                lastname: orderEntry.anagraficaMb?.surnameLegal,
                taxcode: orderEntry.anagraficaMb?.cfLegal,
            };
        } else {
            let contacData = {};
            if (user.contact?.egl_taxcode) {
                const { gender, birthplace, birthplaceProvincia, day, month, year } =
                    reverseCf(user.contact?.egl_taxcode) || {};
                contacData = {
                    gender,
                    birthCountry: birthplaceProvincia === 'EE' ? birthplace : 'ITALIA',
                    birthDate: moment([year, month - 1, day]).toDate(),
                    birthPlace: birthplaceProvincia === 'EE' ? null : birthplace,
                    birthProvince: birthplaceProvincia === 'EE' ? null : birthplaceProvincia,
                };
            }
            return {
                type: AptContactRole.Cliente,
                firstname: user.contact?.firstname,
                lastname: user.contact?.lastname,
                taxcode: user.contact?.egl_taxcode,
                gender: user.contact?.gender as 'F' | 'M',
                birthCountry: user.contact?.birthCountry,
                birthDate: user.contact?.birtDate,
                birthPlace: user.contact?.birthPlace,
                birthProvince: user.contact?.birthProvince,
                ...contacData,
            };
        }
    }

    private getIdentityDocumentInfo(orderEntry: OrderEntryState): IdentityDocumentInfo {
        return {
            documentType: orderEntry.fotoDocumenti?.tipoDocumento,
            documentNumber: orderEntry.fotoDocumenti?.numeroDocumento,
            issuedBy: orderEntry.fotoDocumenti?.emessoDa,
            issueDate: parseToDate(orderEntry.fotoDocumenti?.rilasciatoIl),
        };
    }

    private skipCC(flowType: FlowType): boolean {
        return (
            isVariazioneCommerciale(flowType) ||
            isDomiciliationStandAlone(flowType) ||
            isChangeProduct(flowType) ||
            isAdministrativeSwitchIn(flowType)
        );
    }

    private getCreditCheck(orderEntry: OrderEntryState): CreditCheck {
        const skipCC = this.skipCC(orderEntry.flowType);
        if (!skipCC && orderEntry?.creditCheckStatus) {
            return {
                blacklist: orderEntry.creditCheckStatus?.ccDetails?.blacklist,
                blacklist2: orderEntry.creditCheckStatus?.ccDetails?.blacklist2,
                newBlacklist: orderEntry.creditCheckStatus?.ccDetails?.newBlacklist,
                whitelist: orderEntry.creditCheckStatus?.ccDetails?.whitelist,
                unsolvedNds: orderEntry.creditCheckStatus?.ccDetails?.unsolvedNds,
                avgInvoiceAmount: orderEntry.creditCheckStatus?.ccDetails?.avgInvoiceAmount,
                residualNds: orderEntry.creditCheckStatus?.ccDetails?.residualNds,
                unsolvedTaxCodeNds: orderEntry.creditCheckStatus?.ccDetails?.unsolvedTaxCodeNds,
                unsolvedTaxCodeNdsResult: orderEntry.creditCheckStatus?.ccDetails?.unsolvedTaxCodeNdsResult,
                paymentScore: orderEntry.creditCheckStatus?.ccDetails?.paymentScore,
                ceaseReasonCode: orderEntry.creditCheckStatus?.ccDetails?.ceaseReasonCode,
                unsolvedIbanNds: orderEntry.creditCheckStatus?.ccDetails?.unsolvedIbanNds,
                cribis: orderEntry.creditCheckStatus?.ccDetails?.cribis,
                checkDate: parseToDate(orderEntry.creditCheckStatus?.checkDate),
                taxVatScipafi: orderEntry.creditCheckStatus?.taxVatDetails?.scipafi,
                taxVatSdi: orderEntry.creditCheckStatus?.taxVatDetails?.sdi,
            };
        }
    }

    private getPrivacy(orderEntry: OrderEntryState): Privacy {
        return {
            privacy1: orderEntry.privacyTrattDatiPers[PrivacyType.InizPromozionaliEgl],
            privacy2: orderEntry.privacyTrattDatiPers[PrivacyType.AnalisiMercato],
            privacy3: orderEntry.privacyTrattDatiPers[PrivacyType.InizPromoTerzeP],
        };
    }

    private getBillingAccount(orderEntry: OrderEntryState, user: UserState): BillingAccount {
        return {
            type: orderEntry.infoProdotti?.find((p) => !!p?.configuration?.paymentType)?.configuration?.paymentType,
            billingAddress: this.getCommunicationAddress(orderEntry),
            paymentTool: this.getPaymentTool(orderEntry, user),
            deliveryChannel: this.getDeliveryChannel(orderEntry),
        };
    }

    private getDeliveryChannel(orderEntry: OrderEntryState): AptDeliveryChannelQuote {
        const prod = (orderEntry.infoProdotti || []).find(
            (x) => x?.status !== AptLineStatus.Cancelled && !!x?.configuration?.billType
        );
        return APT_BILL_TYPE_TO_DELIVERY_CHANNEL_QUOTE_MAP[
            prod?.configuration?.billType || orderEntry?.deliveryChannel
        ];
    }

    private getInstrumentType(orderEntry: OrderEntryState): AptPaymentInstrument {
        const paymentInstrument = (orderEntry.infoProdotti?.map((x) => x.configuration?.paymentInstrument) || [])[0];
        if (
            paymentInstrument === AptPaymentInstrument.AddebitoCC ||
            orderEntry.datiPagamento?.formBonifico?.iban ||
            orderEntry.datiPagamento?.existingPaymentTool
        ) {
            return AptPaymentInstrument.AddebitoCC;
        }

        return paymentInstrument;
    }

    private getPaymentTool(orderEntry: OrderEntryState, user: UserState): PaymentTool {
        return {
            instrumentType: this.getInstrumentType(orderEntry),
            iban: orderEntry.datiPagamento?.formBonifico?.iban || orderEntry.datiPagamento?.existingPaymentTool,
            oldIban: orderEntry.domiciliationStandAlone?.oldIbanPaymentTool,
            billingPreferenceCode: orderEntry?.domiciliationStandAlone?.billingPreferenceId,
            holder: this.getHolderPaymentToolOrDefault(orderEntry, user),
            sepaSubscriber: {
                taxcode: orderEntry.datiPagamento?.formBonifico?.sottoscrittoreSepa?.cf,
                firstname: orderEntry.datiPagamento?.formBonifico?.sottoscrittoreSepa?.nome,
                lastname: orderEntry.datiPagamento?.formBonifico?.sottoscrittoreSepa?.cognome,
            },
        };
    }

    private getHolderPaymentToolOrDefault(orderEntry: OrderEntryState, user: UserState): HolderPaymentTool {
        if (this.getInstrumentType(orderEntry) !== AptPaymentInstrument.AddebitoCC) return;
        if (orderEntry.datiPagamento?.existingPaymentTool) return; // in caso in cui è stato selezionato un iban esistente l'holder non va inviato in quanto gia' censito su base dati. SUP non sa a chi è intestato l'iban selezionato

        const definedHolder = cleanObj<HolderPaymentTool>({
            companyName: orderEntry?.datiPagamento?.formBonifico?.titolareConto?.ragioneSociale,
            companyVatcode: orderEntry?.datiPagamento?.formBonifico?.titolareConto?.piva,
            taxcode:
                orderEntry?.datiPagamento?.formBonifico?.titolareConto?.cf ||
                orderEntry?.datiPagamento?.formBonifico?.titolareConto?.cfAzienda,
            firstname: orderEntry?.datiPagamento?.formBonifico?.titolareConto?.nome,
            lastname: orderEntry?.datiPagamento?.formBonifico?.titolareConto?.cognome,
        });
        if (Object.keys(definedHolder).length > 0) {
            return definedHolder as HolderPaymentTool;
        }

        // Default Holder from User
        if (this.isResidential(user)) {
            return {
                companyName: null,
                companyVatcode: null,
                taxcode: user.contact?.egl_taxcode,
                firstname: user.contact?.firstname,
                lastname: user.contact?.lastname,
            };
        } else {
            return {
                companyName: user.contact?.name,
                companyVatcode: user.contact?.egl_vatcode,
                taxcode: user.contact?.egl_taxcode,
                firstname: user.contact?.firstname,
                lastname: user.contact?.lastname,
            };
        }
    }

    private getElectronicInvoicing(orderEntry: OrderEntryState): ElectronicInvoicing {
        return {
            recipientCode: orderEntry.fatturazioneElettronica?.codiceDestinatario,
            pec: orderEntry.fatturazioneElettronica?.pec,
        };
    }

    private getProducts(fullState: FullState): Products[] {
        const appointmentMap = [
            ...(fullState?.orderEntry?.termination?.termAppointment?.meters || []),
            ...(fullState?.orderEntry?.activation?.actiAppointment?.meters || []),
        ]
            .map((meter) => ({
                id: meter?.lineItemId,
                isAvailable: meter?.isAvailable,
                location: meter?.location,
                notes: meter?.notes,
            }))
            .reduce(
                (aggr, { id, ...data }) => ({ ...aggr, [id]: data }),
                {} as { [key in string]: CommodityAppointment }
            );
        const pricesMap: { [key: string]: ProductPrices } = [
            ...(fullState?.orderEntry?.termination?.termCosts || ([] as TermCosts[])).map(
                ({ idAsset, priceSDR, priceOPS }) => ({
                    id: idAsset,
                    priceSDR,
                    priceOPS,
                })
            ),
            ...(fullState?.orderEntry?.costs?.records || ([] as CostRecord[])).map(({ id, eniCosts, otherCosts }) => ({
                id,
                priceSDR: typeof otherCosts === 'number' ? otherCosts.toString() : 'N/A',
                priceOPS: typeof eniCosts === 'number' ? eniCosts.toString() : 'N/A',
            })),
        ].reduce(
            (aggr, { id, ...data }) => ({ ...aggr, [id]: data }),
            {} as { [key in string]: { priceOPS: string; priceSDR: string } }
        );

        return (fullState?.orderEntry.infoProdotti || []).map((currentProd) => ({
            productId: currentProd.id,
            attributeValues: AttributeFactory.getAttributes(fullState, currentProd).keyValue(),
            shippingAddress: this.getShippingAddress(fullState?.orderEntry, currentProd),
            supplyAddress: [null, AptAddressType.Fornitura].includes(currentProd.addressType)
                ? this.getAddress(fullState?.orderEntry.indirizzi?.indirizzoFornitura)
                : null,
            appointment: appointmentMap[currentProd.lineItemId],
            prices: pricesMap[currentProd.lineItemId],
        }));
    }

    private getShippingAddress({ indirizzi }: OrderEntryState, currentProduct: ProductInfo): Address {
        if (currentProduct.addressType === AptAddressType.Spedizione) {
            return this.getAddress(indirizzi?.indirizzoSpedizione) || this.getAddress(indirizzi?.indirizzoFornitura);
        }
        return null;
    }

    private getChannelAndAgency(orderEntry: OrderEntryState, user: UserState, owner: User): ChannelAndAgency {
        const agent = user.agentInfo?.VirtualAgents?.find((x) => x.IsCurrentSelected) || new VirtualAgent();
        let instoreSale = null;
        if (agent.VirtualAgency?.Channel?.CanSetIsInStore) {
            instoreSale = agent.IsInStore ? 'Si' : 'No';
        }
        return {
            userId: owner.Id,
            userProfile: getAgentD365toApt(user.agentInfo?.Type),
            salesAgent: agent.AgentCode,
            agencyCode: agent.VirtualAgency?.Code,
            userCode: user.agentInfo?.Agent.Code,
            salesChannel: agent.VirtualAgency?.Channel?.Code,
            employeeLastname: user.agentInfo?.Agent?.Name,
            numberSignRUIagency: agent.VirtualAgency?.RuiEnrollmentNumber,
            dateSignRUIagency: this.utilitySrv.convertDateD365ToApt(agent.VirtualAgency?.RuiEnrollmentDate),
            companyName: user.agentInfo?.Agency.Name,
            addressAgency: agent.VirtualAgency?.RegisteredOfficeAddress,
            systemOrigin:
                agent.VirtualAgency?.Channel.Code === D365ChannelCode.TelesellingInbound ? 'CALL CENTER' : 'AGENZIA',
            salesAgentBlacklist: user.agentInfo?.Blacklisted,
            instoreSale: instoreSale,
            intermediaryFirstname: orderEntry.branchAgenziaAgente?.ResponsibleFirstName,
            seatName: orderEntry.branchAgenziaAgente?.Name,
            mailSeat: orderEntry.branchAgenziaAgente?.Email,
            pecSeat: orderEntry.branchAgenziaAgente?.Pec,
            intermediaryLastname: orderEntry.branchAgenziaAgente?.ResponsibleLastName,
            addressSeat: orderEntry.branchAgenziaAgente?.AgencyBranchAddress,
            pecAgency: orderEntry.branchAgenziaAgente?.Pec,
            phoneSeat: orderEntry.branchAgenziaAgente?.Telephone,
            dateSignRUIintermediary: this.utilitySrv.convertDateD365ToApt(
                orderEntry.branchAgenziaAgente?.ResponsibleRuiEnrollmentDate
            ),
            numberSignintermediary: orderEntry.branchAgenziaAgente?.ResponsibleRuiEnrollmentNumber,
            operationalSeats: orderEntry.agencyBranchForMonitoring || orderEntry.branchAgenziaAgente?.Name,
            coCode: agent?.CurrentCode,
        };
    }

    private getInvalidCf(orderEntry: OrderEntryState): InvalidCf {
        return {
            previousFiscalcode: orderEntry.invalidCf?.siebelOldCf,
            customerCode: orderEntry.invalidCf?.siebelCustomerCode,
        };
    }

    private getLeadAndCampaign(user: UserState): LeadAndCampaign {
        return {
            campaignId: user.lead?.campaignId,
            leadId: user.lead?.campaignId && user.lead?.leadid ? user.lead?.leadid : '',
            campaignName: user.lead?.campaignId && user.lead?.campaignName ? user.lead?.campaignName : '',
            campaignCode: user.lead?.campaignId && user.lead?.campaignCode ? user.lead?.campaignCode : '',
            campaignTypeCode: user.lead?.campaignId && user.lead?.campaignTypeCode ? user.lead?.campaignTypeCode : '',
        };
    }

    private getSignature(orderEntry: OrderEntryState, isDelayInsertion: boolean): Signature {
        /**
         * NON aggiungere logiche qui dentro. Dispatchale nello state prima di chiamare il saveQuote.
         * in modo che orderEntry.firma
         * abbia il valore che si vuole mappare nella request
         */

        return {
            mode: orderEntry.firma?.d365SignatureType, // viene transcodificato lato apim
            date: orderEntry.firma?.signedDate ? parseToDate(orderEntry.firma?.signedDate) : null,
            checkcallBlocking: orderEntry.firma
                ? orderEntry.firma?.confirmationType === D365ConfirmationType.TacitApprovalSMS
                : null,
            confirmMode: orderEntry.firma?.confirmationType,
            mp3Sent: orderEntry.mp3Info?.uploaded,
            mp3Date: orderEntry.mp3Info?.uploadDate,
            isDelayInsertion: isDelayInsertion,
        };
    }

    private getExecutionType({ tipoEsecuzione, activation }: OrderEntryState): ExecutionType {
        return {
            commodityQuickPassage: tipoEsecuzione?.passaggioRapidoCommodity || activation?.immediateEffect,
            commodityEstimatedDate: tipoEsecuzione?.dataStimataCommodity || activation?.actDate,
            maintenanceQuickPassage: tipoEsecuzione?.passaggioRapidoManutenzione,
            maintenanceEstimatedDate: tipoEsecuzione?.dataStimataManutenzione,
        };
    }

    private getAttachments(orderEntry: OrderEntryState): AttachmentInfo[] {
        const att: AttachmentInfo[] = [];
        if (orderEntry.fotoDocumenti?.nomeFronte) {
            att.push({
                id: newGuid(),
                type: AttachmentType.IDENTITY_CARD_FRONT,
                fileName: orderEntry.fotoDocumenti?.nomeFronte,
                fileSize: orderEntry.fotoDocumenti?.dimensioneFronte?.toString(),
            });
        }
        if (orderEntry.fotoDocumenti?.nomeRetro) {
            att.push({
                id: newGuid(),
                type: AttachmentType.IDENTITY_CARD_BACK,
                fileName: orderEntry.fotoDocumenti?.nomeRetro,
                fileSize: orderEntry.fotoDocumenti?.dimensioneRetro?.toString(),
            });
        }
        return att;
    }

    private getAppointment({ activation, termination }: OrderEntryState): CartToQuoteAppointment {
        const appointment = {
            ...(termination?.termAppointment || ({} as Appointment)),
            ...(activation?.actiAppointment || ({} as Appointment)),
        };
        return {
            contactFirstName: appointment?.altContactFName,
            contactLastName: appointment?.altContactLName,
            contactPrefix: appointment?.altContactPrefix,
            contactPhone: appointment?.altContactPhone,
            timeSlot: appointment?.timeSlot,
            interphoneAvailable: appointment?.interphoneAvailable,
            interphoneNotes: appointment?.interphoneNotes,
        };
    }
}

enum AttachmentType {
    IDENTITY_CARD_FRONT = 'IDENTITY_CARD_FRONT',
    IDENTITY_CARD_BACK = 'IDENTITY_CARD_BACK',
}

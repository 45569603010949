import { ApiService } from '../services/shared/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiMngApi, BaseProvider } from './base-provider';
import { PrivateConfigurationService } from '../services/shared/private-configuration.service';
import { EglQuoteLight } from '../models/apttus/tables/quote/egl-quote-light';
import { map, tap } from 'rxjs/operators';
import { BaseApiResponse, StatusResponse } from '../interfaces/base-api-response';
import { TelemetryMetricService } from '../services/app/telemetry-metric.service';
import { CartToQuoteRequest } from '../models/apttus/request-response/cart-to-quote-request';
import { LoggerService } from '../services/shared/logger.service';

@Injectable({ providedIn: 'root' })
export class SaveQuoteProvider extends BaseProvider {
    constructor(
        private api: ApiService,
        protected configSrv: PrivateConfigurationService,
        private telemetrySrv: TelemetryMetricService,
        private logger: LoggerService
    ) {
        super(configSrv);
    }

    createQuoteFromCart(req: Partial<CartToQuoteRequest>): Observable<EglQuoteLight> {
        const telemetryFinalizer = this.telemetrySrv.rxTelemetry('createQuoteFromCart');
        return this.api
            .postAsync<CreateQuoteFromCartResponse>(this.getApiMngApiUrl(ApiMngApi.CreateQuoteFromCart), req)
            .pipe(
                tap((res: CreateQuoteFromCartResponse) => {
                    if (res?.status !== StatusResponse.Success) {
                        throw new Error(res?.errorManagement?.errorDescription || (res as any));
                    }
                    const isWrongResponse = !Object.keys(res?.response || {}).some((v) => (v || '').startsWith('egl_'));
                    if (isWrongResponse) {
                        this.logger.error('createQuoteFromCart', 'wrong response', null, true, res?.response || res);
                        throw new Error(
                            'Wrong response from createQuoteFromCart. Returned Quote object not contains all required properties'
                        );
                    }
                }),
                telemetryFinalizer,
                map(({ response }) => Object.assign(new EglQuoteLight(), response))
            );
    }
}

interface CreateQuoteFromCartResponse extends BaseApiResponse {
    response: EglQuoteLight;
}

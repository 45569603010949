import { AttributeValues } from './base-attribute-values';

export class ServiceAttributeValues extends AttributeValues {
    attributesMapping(): void {
        super.attributesMapping();

        this.attr['egl_POD__c'] = this.orderEntry?.pod;
        this.attr['egl_PDR__c'] = this.orderEntry?.pdr;
    }
}

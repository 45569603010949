import { SalesProcessPartNumber } from '../../store/models/order-entry-state';
import { AptSaleabilityOperationType, AptSalesProcess } from '../enums/apttus/apt-sales-process';
import { D365OperationMode } from '../enums/d365/d365-operation-mode';
import { FlowType } from '../../store/models/flow-type';

/**
 * @description La mappatura va definita per ogni operationType della matrice di vendibilità;
 * L'ordine dei FlowType/SalesProcess è molto importante: Da quello di default a quelli con maggiore granularità
 */
export const PROCESS_OPERATION_MAPPINGS: {
    [key in AptSaleabilityOperationType]?: {
        [key in FlowType]?: AptSalesProcess | AptSalesProcess[];
    };
} = {
    [AptSaleabilityOperationType.SwitchIn]: {
        [FlowType.SwitchIn]: AptSalesProcess.SwitchIn,
        [FlowType.SwitchInPertinenza]: AptSalesProcess.SwitchIn,
        [FlowType.SwitchInAmmVip]: AptSalesProcess.SwitchInAmmVip,
        [FlowType.SwitchInAmmRecupero2A]: AptSalesProcess.SwitchInAmmRecupero,
        [FlowType.SwitchInAmmRecupero2B]: AptSalesProcess.SwitchInAmmRecupero,
        [FlowType.SwitchInAmmRecupero2C]: AptSalesProcess.SwitchInAmmRecupero,
        [FlowType.SwitchInAmmDelibera153]: AptSalesProcess.SwitchInAmmDelibera153,
    },
    [AptSaleabilityOperationType.Attivazione]: {
        [FlowType.AttivazioneGasConContatore]: AptSalesProcess.AttivazioneSemplice,
        [FlowType.AttivazioneGasSenzaContatore]: AptSalesProcess.AttivazioneSemplice,
        [FlowType.AttivazioneN02]: null, // AptSalesProcess.xxx,
        [FlowType.AttivazionePwrS01]: AptSalesProcess.Subentro,
        [FlowType.AttivazioneA40]: null, // AptSalesProcess.xxx,
        [FlowType.AttivazionePwrA01]: null, // AptSalesProcess.xxx,
        [FlowType.AttivazionePN1]: null, // AptSalesProcess.xxx,
        [FlowType.Attivazione]: AptSalesProcess.AttivazioneSemplice, // Default
    },
    [AptSaleabilityOperationType.CambioProdotto]: {
        [FlowType.CPCommerciale]: AptSalesProcess.CambioProdotto,
    },
    [AptSaleabilityOperationType.CambioProdottoAmministrativo]: {
        [FlowType.CPAmministrativo]: AptSalesProcess.CambioProdottoAmministrativo,
    },
    [AptSaleabilityOperationType.CambioProdottoDaDelibera]: {
        [FlowType.CPDelibera]: AptSalesProcess.CambioProdottoDaDelibera,
    },
    [AptSaleabilityOperationType.VariazioneCommerciale]: {
        [FlowType.VariazioneCommerciale]: AptSalesProcess.VariazioneCommerciale,
    },
    [AptSaleabilityOperationType.ScontoStandalone]: {
        [FlowType.ScontoStandalone]: AptSalesProcess.ScontoStandalone,
    },
    [AptSaleabilityOperationType.Voltura]: {
        [FlowType.Voltura]: null,
        [FlowType.SwitchInVoltura]: [
            AptSalesProcess.VolturaConSwitchIn,
            AptSalesProcess.VolturaConCambioFornAmmRecupero,
            AptSalesProcess.VolturaConCambioFornAmmVip,
        ],
        // [FlowType.SwitchInVoltura]: AptSalesProcess.VolturaConCambioFornAmmRecupero,
        // [FlowType.SwitchInVoltura]: AptSalesProcess.VolturaConCambioFornAmmVip,
    },
    [AptSaleabilityOperationType.DomiciliazioneStandAlone]: {
        [FlowType.DomiciliazioneAttivazione]: AptSalesProcess.AttivazioneDomiciliazione,
        [FlowType.DomiciliazioneAttivazioneInserimentoIban]: AptSalesProcess.AttivazioneDomiciliazione,
        [FlowType.DomiciliazioneModifica]: AptSalesProcess.ModificaDomiciliazione,
        [FlowType.DomiciliazioneModificaInserimentoIban]: AptSalesProcess.ModificaDomiciliazione,
        [FlowType.DomiciliazioneRevoca]: AptSalesProcess.RevocaDomiciliazione,
    },
    [AptSaleabilityOperationType.Verifica]: {
        [FlowType.VariazioneTecnicaVerifiche]: AptSalesProcess.VariazioneTecnicaVerifiche,
    },

    [AptSaleabilityOperationType.Lavori]: {
        [FlowType.VariazioneTecnicaLavori]: AptSalesProcess.VariazioneTecnicaLavori,
    },
    [AptSaleabilityOperationType.Nessuno]: {
        [FlowType.Cessazione]: AptSalesProcess.Cessazione,
    }
};

export const ACTIVATION_POWER_FLOW_TYPES = [
    FlowType.AttivazionePwrS01,
    FlowType.AttivazionePwrA01,
    FlowType.AttivazioneN02,
];

export const ACTIVATION_GAS_FLOW_TYPES = [
    FlowType.AttivazioneGasConContatore,
    FlowType.AttivazioneGasSenzaContatore,
    FlowType.AttivazioneA40,
    FlowType.AttivazionePN1,
];

export const FLOW_PART_NUMBER_MAP: {
    [key in FlowType]?: SalesProcessPartNumber;
} = {
    [FlowType.AttivazioneGasConContatore]: SalesProcessPartNumber.GAS_ACTIVATION_WITH_METER,
    [FlowType.AttivazioneGasSenzaContatore]: SalesProcessPartNumber.GAS_ACTIVATION_WITHOUT_METER,
    [FlowType.AttivazionePwrS01]: SalesProcessPartNumber.POWER_ACTIVATION_SIMPLE,
};

export const SIEBEL_SDR_COMMODITY_MAP: {
    [key in FlowType]?: string;
} = {
    [FlowType.AttivazioneGasConContatore]: 'COLLOCA MISURATORE E ATTIVAZIONE SERVIZIO',
    [FlowType.AttivazioneGasSenzaContatore]: 'POSA MISURATORE E ATTIVAZIONE SEMPLICE',
    [FlowType.AttivazionePwrS01]: 'ATTIVAZIONE CONTATORE DISALIMENTATO',
    [FlowType.AttivazionePwrA01]: 'ATTIVAZIONE CONTATORE DISALIMENTATO',
    [FlowType.AttivazioneN02]: 'ATTIVAZIONE CONTATORE DISALIMENTATO',
};

export const ACTIVATION_FLOW_TYPES: FlowType[] = Object.keys(
    PROCESS_OPERATION_MAPPINGS[AptSaleabilityOperationType.Attivazione]
) as FlowType[];
export const SWITCH_IN_FLOW_TYPES: FlowType[] = Object.keys(
    PROCESS_OPERATION_MAPPINGS[AptSaleabilityOperationType.SwitchIn]
) as FlowType[];
export const ACTIVATION_SALES_PROCESSES: AptSalesProcess[] = Object.values(
    PROCESS_OPERATION_MAPPINGS[AptSaleabilityOperationType.Attivazione]
).filter(
    (salesProcess, i, salesProcesses) => salesProcess && salesProcesses.indexOf(salesProcess) === i
) as AptSalesProcess[];

export const D365_OPERATION_MODE: {
    [key in FlowType]?: D365OperationMode;
} = {
    [FlowType.SwitchIn]: D365OperationMode.SwitchIn,
    [FlowType.SwitchInVoltura]: D365OperationMode.SwitchInConVoltura,
    [FlowType.SwitchInAmmVip]: D365OperationMode.SwitchInAmmVip,
    [FlowType.SwitchInAmmRecupero2A]: D365OperationMode.SwitchInAmmRecupero,
    [FlowType.SwitchInAmmRecupero2B]: D365OperationMode.SwitchInAmmRecupero,
    [FlowType.SwitchInAmmRecupero2C]: D365OperationMode.SwitchInAmmRecupero,
    [FlowType.SwitchInAmmDelibera153]: D365OperationMode.SwitchInAmmRipensamento,

    [FlowType.CPAmministrativo]: D365OperationMode.CambioProdottoRetroattivo,
    [FlowType.CPDelibera]: D365OperationMode.CambioProdottoDelibera,
    [FlowType.CPCommerciale]: D365OperationMode.CambioProdotto,

    [FlowType.DomiciliazioneAttivazione]: D365OperationMode.Domiciliazione,
    [FlowType.DomiciliazioneModifica]: D365OperationMode.Domiciliazione,
    [FlowType.DomiciliazioneRevoca]: D365OperationMode.Domiciliazione,
    [FlowType.DomiciliazioneAttivazioneInserimentoIban]: D365OperationMode.Domiciliazione,
    [FlowType.DomiciliazioneModificaInserimentoIban]: D365OperationMode.Domiciliazione,
};

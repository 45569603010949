/**
 * @author Marco Ricupero ft Andreea Stegariu, Felice Lombardi
 * @version 1.6
 */
import { map } from 'rxjs/operators';
import {
    selectFirmaState,
    selectFlowType,
    selectProductsTypes,
    selectSkipPrivacy,
    selectTipoPagamento,
} from '../../../../store/selectors/order-entry.selectors';
import { selectCartSegment, selectChannelCode } from '../../../../store/selectors/user.selectors';
import { D365CustomerSegment } from '../../../enums/d365/d365-customer-segment';
import { combineLatest } from 'rxjs';
import { D365ChannelCode } from '../../../enums/d365/d365-channel-code';
import { selectEnabledPartnerships } from '../../../../store/selectors/partnership.selectors';
import { AptPartnerships } from '../../../../modules/common/partnership/enums/apt-partnerships';
import { AptPaymentInstrument } from '../../../enums/apttus/apt-payment-instrument';
import { selectCurrentVaProductTypes } from '../../../../store/selectors/common.selectors';
import { D365SignatureType } from '../../../enums/d365/d365-signature-type';
import { D365ConfirmationType } from '../../../enums/d365/d365-confirmation-type';
import { isFlowTypeActivation } from '../../../functions/verifications.functions';
import { RoutesPaths } from '../../../config/routes-paths';
import { DragonRouterSkippingFunction } from './dragon-router.type';
import { FlowType } from '../../../../store/models/flow-type';
const selectPartnership = () => ((location.pathname.match(/partnership\/([^\/;]+)/i) || [])[1] || '').trim();

/**
 * Skipping rules generiche
 */
export const GLOBAL_SKIPPING_RULES: {
    [key in RoutesPaths]?: {
        skip: DragonRouterSkippingFunction;
    };
} = {
    [RoutesPaths.ModalitaAcquisizione]: {
        skip: ({ eglState }) =>
            combineLatest([
                eglState.select(selectCartSegment),
                eglState.select(selectFlowType),
                eglState.select(selectProductsTypes),
                eglState.select(selectChannelCode),
            ]).pipe(
                map(
                    ([segment, flowType, prodTypes, currentChannel]) =>
                        segment === D365CustomerSegment.Microbusiness ||
                        flowType === FlowType.SwitchInVoltura ||
                        !prodTypes.containsCommodity ||
                        ![
                            D365ChannelCode.Agenzia,
                            D365ChannelCode.NegoziESE,
                            D365ChannelCode.AgenziaMicrobusiness,
                            D365ChannelCode.AgenteSingolo,
                            D365ChannelCode.Flagship,
                        ].includes(currentChannel)
                )
            ),
    },
    [RoutesPaths.TroubleShootingAttivazione]: {
        skip: ({ eglState }) =>
            eglState.select(selectFlowType).pipe(map((flowType) => !isFlowTypeActivation(flowType))),
    },
    [RoutesPaths.PsFastwebPersonalData]: {
        skip: ({ eglState }) =>
            eglState.select(selectEnabledPartnerships).pipe(
                map((enabledPartnerships) => ({
                    enabledPartnerships: enabledPartnerships.map((enabledPartnership) =>
                        enabledPartnership.toUpperCase()
                    ) as AptPartnerships[],
                    partnership: selectPartnership().toUpperCase() as AptPartnerships,
                })),
                map(
                    ({ enabledPartnerships, partnership }) =>
                        !enabledPartnerships?.length ||
                        (enabledPartnerships.includes(partnership) && ![AptPartnerships.FASTWEB].includes(partnership))
                )
            ),
    },
    [RoutesPaths.PsFastwebActiveContracts]: {
        skip: ({ eglState }) =>
            eglState.select(selectEnabledPartnerships).pipe(
                map((enabledPartnerships) => ({
                    enabledPartnerships: enabledPartnerships.map((enabledPartnership) =>
                        enabledPartnership.toUpperCase()
                    ) as AptPartnerships[],
                    partnership: selectPartnership().toUpperCase() as AptPartnerships,
                })),
                map(
                    ({ enabledPartnerships, partnership }) =>
                        !enabledPartnerships?.length ||
                        (enabledPartnerships.includes(partnership) && ![AptPartnerships.FASTWEB].includes(partnership))
                )
            ),
    },
    [RoutesPaths.CodiceAteco]: {
        skip: ({ eglState }) =>
            eglState.select(selectCartSegment).pipe(map((segment) => segment !== D365CustomerSegment.Microbusiness)),
    },
    [RoutesPaths.PotenzaImpegnata]: {
        skip: ({ eglState }) =>
            combineLatest([eglState.select(selectCartSegment), eglState.select(selectProductsTypes)]).pipe(
                map(([segment, prodTypes]) => segment !== D365CustomerSegment.Microbusiness || !prodTypes.containsPower)
            ),
    },
    [RoutesPaths.ConsumoLuce]: {
        skip: ({ eglState }) =>
            combineLatest([eglState.select(selectCartSegment), eglState.select(selectProductsTypes)]).pipe(
                map(([segment, prodTypes]) => segment !== D365CustomerSegment.Residential || !prodTypes.containsPower)
            ),
    },
    [RoutesPaths.PotenzaImpegnata]: {
        skip: ({ eglState }) =>
            combineLatest([eglState.select(selectCartSegment), eglState.select(selectProductsTypes)]).pipe(
                map(([segment, prodTypes]) => segment !== D365CustomerSegment.Microbusiness || !prodTypes.containsPower)
            ),
    },
    [RoutesPaths.DatiPod]: {
        skip: ({ eglState }) =>
            eglState.select(selectProductsTypes).pipe(map((productType) => !productType.containsPower)),
    },
    [RoutesPaths.ConsumoGas]: {
        skip: ({ eglState }) =>
            eglState.select(selectProductsTypes).pipe(map((productType) => !productType.containsGas)),
    },
    [RoutesPaths.DatiPdr]: {
        skip: ({ eglState }) =>
            eglState.select(selectProductsTypes).pipe(map((productType) => !productType.containsGas)),
    },
    [RoutesPaths.DatiPdrAttivazione]: {
        skip: ({ eglState }) =>
            eglState.select(selectProductsTypes).pipe(map((productType) => !productType.containsGas)),
    },
    [RoutesPaths.DatiTecniciAmministrativi]: {
        skip: ({ eglState }) =>
            eglState.select(selectProductsTypes).pipe(map((productType) => !productType.containsCommodity)),
    },
    [RoutesPaths.DatiPagamento]: {
        skip: ({ eglState }) =>
            eglState
                .select(selectTipoPagamento)
                .pipe(
                    map(
                        ({ paymentType, isDigitalCommunications }) =>
                            paymentType === AptPaymentInstrument.Bollettino && isDigitalCommunications
                    )
                ),
    },
    [RoutesPaths.UploadDocumento]: {
        skip: ({ eglState, configSrv }) =>
            combineLatest([
                eglState.select(selectFlowType),
                eglState.select(selectProductsTypes),
                eglState.select(selectCurrentVaProductTypes),
            ]).pipe(
                map(
                    ([flowType, prodTypes, currentVaProductTypes]) =>
                        flowType !== FlowType.SwitchInVoltura &&
                        (!currentVaProductTypes?.currentVirtualAg?.VirtualAgency?.Channel?.UploadDocument ||
                            (prodTypes.allTypes.some(
                                (prodType) => !(configSrv.config?.productCategoriesWithCI || []).includes(prodType)
                            ) &&
                                flowType !== FlowType.DomiciliazioneAttivazione))
                )
            ),
    },
    [RoutesPaths.TipoEsecuzione]: {
        skip: ({ eglState }) =>
            combineLatest([
                eglState.select(selectCartSegment),
                eglState.select(selectFlowType),
                eglState.select(selectProductsTypes),
                eglState.select(selectCurrentVaProductTypes),
            ]).pipe(
                map(
                    ([segment, flowType, prodTypes, currentVaProductTypes]) =>
                        segment === D365CustomerSegment.Microbusiness ||
                        flowType === FlowType.SwitchInVoltura ||
                        currentVaProductTypes?.currentVirtualAg?.VirtualAgency?.Channel?.CanSkipExecution ||
                        !(prodTypes?.containsCommodity || prodTypes?.containsMaintenance)
                )
            ),
    },
    [RoutesPaths.PrivacyDatiPersonali]: {
        skip: ({ eglState }) => eglState.select(selectSkipPrivacy).pipe(map((skipPrivacy) => !!skipPrivacy)),
    },
    [RoutesPaths.FirmaPreviaStampa]: {
        skip: ({ eglState }) =>
            eglState
                .select(selectFirmaState)
                .pipe(map((signature) => signature?.d365SignatureType !== D365SignatureType.PaperPrintedBySystem)),
    },
    [RoutesPaths.FirmaCartacea]: {
        skip: ({ eglState }) =>
            eglState
                .select(selectFirmaState)
                .pipe(map((signature) => signature?.d365SignatureType !== D365SignatureType.Cartacea)),
    },
    [RoutesPaths.FirmaDoiFull]: {
        skip: ({ eglState }) =>
            eglState
                .select(selectFirmaState)
                .pipe(map((signature) => signature?.d365SignatureType !== D365SignatureType.Doi)),
    },
    [RoutesPaths.FirmaVocalOrder]: {
        skip: ({ eglState }) =>
            eglState
                .select(selectFirmaState)
                .pipe(map((signature) => signature?.d365SignatureType !== D365SignatureType.VocalOrder)),
    },
    [RoutesPaths.SceltaContatto]: {
        skip: ({ eglState }) =>
            eglState
                .select(selectFirmaState)
                .pipe(
                    map(
                        (signature) =>
                            !signature?.d365SignatureType ||
                            [D365SignatureType.Doi, D365SignatureType.DoiLight].includes(
                                signature?.d365SignatureType
                            ) ||
                            (signature?.d365SignatureType === D365SignatureType.VocalOrder &&
                                signature?.confirmationType === D365ConfirmationType.TacitApprovalSMS)
                    )
                ),
    },
};

export enum FlowType {
    SwitchIn = 'SW_IN',
    SwitchInPertinenza = 'SW_IN_PERTINENZA',

    SwitchInAmmVip = 'SW_IN_AMM_VIP', // SWIN Amm. scenario 1, VIP
    SwitchInAmmRecupero2A = 'SW_IN_AMM_RECUPERO_2A', // SWIN Amm. scenario 2A (cliente presente, asset presente in stato cessato)
    SwitchInAmmRecupero2B = 'SW_IN_AMM_RECUPERO_2B', // SWIN Amm. scenario 2B (cliente presente, asset assente)
    SwitchInAmmRecupero2C = 'SW_IN_AMM_RECUPERO_2C', // SWIN Amm. scenario 2C (cliente assente, asset assente)
    SwitchInAmmDelibera153 = 'SW_IN_AMM_DEL153', // SWIN Amm. scenario 3, come SWIN Amm. scenario 2A. Cliente EGL passato a ENEL involontariamente

    SwitchInVoltura = 'VOL_SW_IN',
    CPCommerciale = 'CP_COM',
    CPDelibera = 'CP_DEL',
    CPAmministrativo = 'CP_AMM',
    DomiciliazioneAttivazione = 'DOM_ATTIVAZ',
    DomiciliazioneAttivazioneInserimentoIban = 'DOM_ATTIVAZ_NEW_IBAN',
    DomiciliazioneRevoca = 'DOM_REVOCA',
    DomiciliazioneModifica = 'DOM_MODIFICA',
    DomiciliazioneModificaInserimentoIban = 'DOM_MODIFICA_NEW_IBAN',
    Cessazione = 'CESSAZIONE',
    Sospensione = 'SOSPENSIONE',
    AnnullamentoSospensione = 'ANNULLAMENTO_SOSPENSIONE',
    VariazioneCommerciale = 'VAR_COMM',
    Interruzione = 'INTERRUZIONE',
    AnnullamentoInterruzione = 'ANNULLAMENTO_INTERRUZIONE',
    Riapertura = 'RIAPERTURA',
    AnnullamentoRiapertura = 'ANNULLAMENTO_RIAPERTURA',
    Voltura = 'VOLTURA',
    Attivazione = 'ATTIVAZIONE', // flusso virtuale
    AttivazioneGasConContatore = 'ATTIVAZIONE_SEMP_GAS_CONTATORE', // GAS - SUBENTRO Con Contatore
    AttivazioneGasSenzaContatore = 'ATTIVAZIONE_SEMP_GAS_NO_CONTATORE', // GAS - SUBENTRO Senza Contatore
    AttivazioneA40 = 'ATTIVAZIONE_COMP', // GAS - Attivazione soggetta a delibera 40/41
    AttivazionePN1 = 'ATTIVAZIONE_PREV', // GAS - Preventivo nuovo impianto
    AttivazionePwrS01 = 'ATTIVAZIONE_SEMP_LUCE', // LUCE - SUBENTRO
    AttivazioneN02 = 'ATTIVAZIONE_CONNESSIONE', // LUCE - Nuova conessione con attivazione
    AttivazionePwrA01 = 'ATTIVAZIONE_CONDIZIONI', // LUCE - Attivazione preposati a parità di condizioni di connessione
    ScontoStandalone = 'SC_STA',
    VariazioneTecnicaVerifiche = 'VARIAZIONE_TECNICA_VERIFICHE',
    VariazioneTecnicaLavori = 'VARIAZIONE_TECNICA_LAVORI',
    DomiciliazioneBackOffice = 'DOMICILIATION',
}

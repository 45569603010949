import { Injectable, NgZone } from '@angular/core';
import { LoggerService } from './logger.service';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { setCurrentPage } from '../../../store/actions/app.actions';
import { AppState } from '../../../store/models/app-state';
import { Store } from '@ngrx/store';
import { RoutesPaths } from '../../config/routes-paths';
import { Location } from '@angular/common';
import {
    selectFirmaState,
    selectFlowType,
    selectProductsTypes,
    selectSkipPrivacy,
    selectTipoPagamento,
} from '../../../store/selectors/order-entry.selectors';
import { Firma } from '../../../store/models/order-entry-state';
import { D365ConfirmationType } from '../../enums/d365/d365-confirmation-type';
import { AptProductType } from '../../enums/apttus/apt-product-type';
import { AptPaymentInstrument } from '../../enums/apttus/apt-payment-instrument';
import { D365SignatureType } from '../../enums/d365/d365-signature-type';
import { selectCartSegment, selectChannelCode } from '../../../store/selectors/user.selectors';
import { D365ChannelCode } from '../../enums/d365/d365-channel-code';
import { catchError, map, take } from 'rxjs/operators';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';
import { selectCurrentVaProductTypes } from '../../../store/selectors/common.selectors';
import { ApttusService, QuoteEventStatus, QuoteServiceEvent } from '../apttus/apttus.service';
import { selectEnabledPartnerships } from '../../../store/selectors/partnership.selectors';
import { PrivateConfigurationService } from './private-configuration.service';
import { LoadingService } from './loading.service';
import { EglSalesupStateService } from '../apttus/tables/egl-salesup-state.service';
import { isFlowTypeActivation, isSkipSignature } from '../../functions/verifications.functions';
import { isDomiciliationStandAlone } from '../../functions/verifications.functions';
import { CartToQuoteService } from '../apttus/cart-to-quote/cart-to-quote.service';
import { from, of } from 'rxjs';
import { FlowType } from '../../../store/models/flow-type';

@Injectable({
    providedIn: 'root',
})
export class RouterService {
    private signature: Firma;
    private vaCanSkipTipoEsecuzione: boolean;
    private tipoPagamento: AptPaymentInstrument;
    private isComunicazioneDigitale: boolean;
    private currentChannel: D365ChannelCode;
    private channelAllowUpload: boolean;
    private prodTypes: AptProductType[];
    private quoteId: string;
    private quoteName: string;
    private segment: D365CustomerSegment;
    private containsCommodityPower: boolean;
    private containsCommodityGas: boolean;
    private containsCommodity: boolean;
    private skipPrivacy: boolean;
    private flowType: FlowType;
    // private _initDomiciliationUrl: string;
    private d2dChannles = [
        D365ChannelCode.Agenzia,
        D365ChannelCode.NegoziESE,
        D365ChannelCode.AgenziaMicrobusiness,
        D365ChannelCode.AgenteSingolo,
        D365ChannelCode.Flagship,
    ];

    constructor(
        protected router: Router,
        protected ngZone: NgZone,
        protected store: Store<AppState>,
        protected logger: LoggerService,
        protected location: Location,
        protected apttusSrv: ApttusService,
        protected configSrv: PrivateConfigurationService,
        protected eglSalesupStateService: EglSalesupStateService,
        protected quoteSrv: CartToQuoteService
    ) {
        this.handleRouteChanges();
        this.backNavHandler();
        this.mapStateToProps();
        this.apttusSrv.emitterQuoteService.subscribe((quoteResponse) =>
            this.legacyConvertCartToQuoteCallback(quoteResponse || ({} as QuoteServiceEvent))
        );
    }
    /**
     * Mappa lo  state su proprietà locali
     */
    private mapStateToProps(): void {
        this.store.select(selectFirmaState).subscribe((f: Firma) => {
            this.signature = f;
        });

        this.store.select(selectProductsTypes).subscribe((prodTypes) => {
            if (prodTypes) {
                this.prodTypes = prodTypes.allTypes;
                this.containsCommodityGas = prodTypes.containsGas;
                this.containsCommodityPower = prodTypes.containsPower;
                this.containsCommodity = prodTypes.containsCommodity;
            }
        });

        this.store.select(selectTipoPagamento).subscribe((p) => {
            this.isComunicazioneDigitale = p.isDigitalCommunications;
            this.tipoPagamento = p.paymentType;
        });

        this.store.select(selectCurrentVaProductTypes).subscribe((state) => {
            if (state?.currentVirtualAg?.VirtualAgency?.Channel) {
                this.channelAllowUpload = state.currentVirtualAg.VirtualAgency.Channel.UploadDocument;
                this.vaCanSkipTipoEsecuzione = state.currentVirtualAg.VirtualAgency.Channel.CanSkipExecution;
            }
        });

        this.store.select(selectChannelCode).subscribe((c: D365ChannelCode) => {
            this.currentChannel = c;
        });

        this.store.select(selectCartSegment).subscribe((s: D365CustomerSegment) => {
            this.segment = s;
        });

        this.store.select(selectSkipPrivacy).subscribe((s: boolean) => {
            this.skipPrivacy = s;
        });

        this.store.select(selectFlowType).subscribe((f) => {
            this.flowType = f;
        });
    }

    /**
     * Intercetta i tentativi di back navigation
     */
    private backNavHandler(): void {
        addEventListener('popstate', () => {
            if (this.isNoBackPage(this.currentLocation)) {
                history.pushState(null, null, null);
            }
        });
    }

    private newConvertCartToQuote(): Promise<void> {
        return this.quoteSrv
            .saveQuoteV2_Wrapper()
            .pipe(
                map((quote) => ({
                    quoteId: quote?.Id,
                    quoteName: quote?.Name,
                    status: QuoteEventStatus.UnlockedFlow,
                })),
                catchError(() =>
                    of({
                        status: QuoteEventStatus.GenericError,
                    })
                ),
                map((quoteData) => this.legacyConvertCartToQuoteCallback(quoteData))
            )
            .toPromise();
    }

    private legacyConvertCartToQuoteCallback({ quoteId, quoteName, status }: Partial<QuoteServiceEvent>): void {
        this.quoteId = quoteId;
        this.quoteName = quoteName;

        switch (status) {
            case QuoteEventStatus.UnlockedFlow:
                if (this.signature?.d365SignatureType === D365SignatureType.PaperPrintedBySystem) {
                    this.navigate([RoutesPaths.FirmaPreviaStampa]);
                } else {
                    if (this.currentLocation !== RoutesPaths.CPDeliberaResume) {
                        this.navigate([RoutesPaths.ThankYouPage], {
                            queryParams: { qId: this.quoteId, qName: this.quoteName },
                        });
                    }
                }
                break;
            case QuoteEventStatus.GenericError:
                this.navigate([RoutesPaths.Error500]);
                break;
        }
    }

    // public set initDomiciliationUrl(url: string) {
    //     this._initDomiciliationUrl = url;
    // }

    // public goBackToDomiciliationInit() {
    //     this.navigate(this._initDomiciliationUrl ? [this._initDomiciliationUrl] : [RoutesPaths.Error401]);
    // }
    /**
     * Naviga indietro
     */
    async back(): Promise<void> {
        this.location.back();
    }

    /**
     * Naviga alla pagina successiva
     */
    async next(params?: any): Promise<boolean> {
        let cartDetailLocation = 'carts/active';
        if (this.currentLocation.startsWith('carts/')) {
            cartDetailLocation = this.currentLocation;
        }
        let partnershipLocation = '';
        if (this.currentLocation.startsWith('order-entry/partnership')) {
            partnershipLocation = this.currentLocation;
        }

        switch (this.currentLocation) {
            case cartDetailLocation:
                this.store
                    .select(selectFlowType)
                    .pipe(take(1))
                    .subscribe((res) => {
                        if (res == FlowType.VariazioneCommerciale || res == FlowType.ScontoStandalone) {
                            this.newConvertCartToQuote();
                        } else {
                            this.gotoOrderEntryStep1();
                        }
                    });
                break;
            case RoutesPaths.TroubleShootingAttivazione:
                if (this.flowType === FlowType.Attivazione) {
                    if (this.currentLocation !== RoutesPaths.TroubleShootingAttivazione) {
                        this.navigate([RoutesPaths.TroubleShootingAttivazione]);
                    } else {
                        this.navigate([RoutesPaths.DatiGenerici]);
                    }
                } else if (this.isOcrActive) {
                    this.navigate([RoutesPaths.ModalitaAcquisizione]);
                } else {
                    this.navigate([RoutesPaths.DatiGenerici]);
                }
                break;
            case RoutesPaths.VariazioneTecnica:
                this.newConvertCartToQuote();
                break;
            case RoutesPaths.SceltaFirma:
                if (this.needUploadIdentityCard && this.signature.d365SignatureType !== D365SignatureType.Cartacea) {
                    this.navigate([RoutesPaths.UploadDocumento]);
                } else {
                    if (this.skipTipoEsecuzione || this.segment === D365CustomerSegment.Microbusiness) {
                        if (this.skipPrivacy) {
                            this.navigateFromSignatureType();
                        } else {
                            this.navigate([RoutesPaths.PrivacyDatiPersonali]);
                        }
                    } else {
                        this.navigate([RoutesPaths.TipoEsecuzione]);
                    }
                }
                break;
            case RoutesPaths.UploadDocumento:
                if (this.signature.d365SignatureType !== D365SignatureType.Cartacea) {
                    if (this.skipTipoEsecuzione || this.segment === D365CustomerSegment.Microbusiness) {
                        if (this.skipPrivacy) {
                            this.navigateFromSignatureType();
                        } else {
                            this.navigate([RoutesPaths.PrivacyDatiPersonali]);
                        }
                    } else {
                        this.navigate([RoutesPaths.TipoEsecuzione]);
                    }
                } else {
                    this.navigate([RoutesPaths.SceltaContatto]);
                }
                break;
            case RoutesPaths.FirmaCartacea:
                if (this.needUploadIdentityCard) {
                    this.navigate([RoutesPaths.UploadDocumento]);
                } else {
                    this.navigate([RoutesPaths.SceltaContatto]);
                }
                break;
            case RoutesPaths.FirmaPreviaStampa:
                this.navigate([RoutesPaths.ThankYouPage], {
                    queryParams: { qId: this.quoteId, qName: this.quoteName },
                });
                break;
            case RoutesPaths.FirmaGrafometrica:
            case RoutesPaths.FirmaVocalOrder:
                if (this.signature.confirmationType === D365ConfirmationType.TacitApprovalSMS) {
                    this.newConvertCartToQuote();
                } else {
                    this.navigate([RoutesPaths.SceltaContatto]);
                }
                break;
            case RoutesPaths.SceltaContatto:
            case RoutesPaths.FirmaDoiFull:
                this.newConvertCartToQuote();
                break;
            case RoutesPaths.DatiPagamento:
                this.navigate([RoutesPaths.Riepilogo]);
                break;
            case RoutesPaths.ConsumoLuce:
                this.navigate([RoutesPaths.DatiPod]);
                break;
            case RoutesPaths.ConsumoGas:
                this.navigate([RoutesPaths.DatiPdr]);
                break;
            case RoutesPaths.DatiPod:
            case RoutesPaths.DatiPdr:
                if (this.currentLocation === RoutesPaths.DatiPod && this.flowType === FlowType.Attivazione) {
                    this.navigate([RoutesPaths.ConsumoLuce]);
                } else if (this.currentLocation === RoutesPaths.DatiPod && this.containsCommodityGas) {
                    this.navigate([RoutesPaths.ConsumoGas]);
                } else if (this.currentLocation === RoutesPaths.DatiPod && this.flowType === FlowType.SwitchInVoltura) {
                    this.navigate([RoutesPaths.DecretoCasa]);
                } else if (this.tipoPagamento === AptPaymentInstrument.Bollettino && this.isComunicazioneDigitale) {
                    this.navigate([RoutesPaths.Riepilogo]);
                } else {
                    this.navigate([RoutesPaths.DatiPagamento]);
                }
                break;
            case RoutesPaths.DecretoCasa:
                if (this.tipoPagamento === AptPaymentInstrument.Bollettino && this.isComunicazioneDigitale) {
                    this.navigate([RoutesPaths.Riepilogo]);
                } else this.navigate([RoutesPaths.DatiPagamento]);
                break;
            case RoutesPaths.PotenzaImpegnata:
                this.navigate([RoutesPaths.DatiPod]);
                break;
            case RoutesPaths.Indirizzi:
                if (isFlowTypeActivation(this.flowType)) {
                    if (this.containsCommodityGas) {
                        this.navigate([RoutesPaths.DatiPdrAttivazione]);
                    } else {
                        this.navigate([RoutesPaths.DatiPod]);
                    }
                } else if (this.segment === D365CustomerSegment.Microbusiness) {
                    this.navigate([RoutesPaths.CodiceAteco]);
                } else {
                    if (this.containsCommodityPower) {
                        this.navigate([RoutesPaths.ConsumoLuce]);
                    } else if (this.containsCommodityGas) {
                        this.navigate([RoutesPaths.ConsumoGas]);
                    } else {
                        if (this.tipoPagamento === AptPaymentInstrument.Bollettino && this.isComunicazioneDigitale) {
                            this.navigate([RoutesPaths.Riepilogo]);
                        } else {
                            this.navigate([RoutesPaths.DatiPagamento]);
                        }
                    }
                }
                break;
            case RoutesPaths.PrivacyDatiPersonali:
                if (isSkipSignature(this.flowType, this.signature.d365SignatureType)) {
                    this.newConvertCartToQuote();
                } else {
                    this.navigateFromSignatureType();
                }
                break;
            case RoutesPaths.CodiceAteco:
                if (this.containsCommodityPower) {
                    this.navigate([RoutesPaths.PotenzaImpegnata]);
                } else if (this.containsCommodityGas) {
                    this.navigate([RoutesPaths.ConsumoGas]);
                }
                break;
            case RoutesPaths.TipoEsecuzione:
                if (this.skipPrivacy) {
                    this.navigateFromSignatureType();
                } else {
                    this.navigate([RoutesPaths.PrivacyDatiPersonali]);
                }
                break;
            case RoutesPaths.CPDeliberaResume:
                this.router.navigate([RoutesPaths.CPDeliberaThankYouPage]);
                break;
            case RoutesPaths.DatiGenerici:
            case partnershipLocation:
                this.goToNextPartnership();
                break;
            case RoutesPaths.Riepilogo:
                this.router.navigate([RoutesPaths.SceltaFirma]);
                break;
            // Routes cessazione
            case RoutesPaths.TerminationCosts:
                this.router.navigate([RoutesPaths.TerminationMortisCausa]);
                break;
            case RoutesPaths.TerminationMortisCausa:
                this.router.navigate([RoutesPaths.TerminationContacts]);
                break;
            case RoutesPaths.TerminationContacts:
                this.router.navigate([RoutesPaths.TerminationDate]);
                break;
            case RoutesPaths.TerminationDate:
                this.router.navigate([RoutesPaths.TerminationAppointment]);
                break;
            case RoutesPaths.TerminationAppointment:
                this.router.navigate([RoutesPaths.TerminationRecap]);
                break;
            case RoutesPaths.DatiPdrAttivazione:
                this.router.navigate([RoutesPaths.ConsumoGas]);
                break;
            default:
                this.logger.error('RouterService', `Case: ${this.currentLocation}, not managed in switch case`, false);
                return false;
        }
    }

    /**
     * @description Esegue la navigazione alla route iniziale della prossima partnership. Nel caso non siano presenti partnership o siano già state navigate tutte le loro pagine, prosegue con il classico order entry
     * @returns void
     */
    private goToNextPartnership(): void {
        this.store
            .select(selectEnabledPartnerships)
            .pipe(take(1))
            .subscribe((enabledPartnerships: string[]) => {
                // Nel caso in cui non ho prodotti partnership nel cart, procedo con il flusso classico
                if (enabledPartnerships.length === 0) {
                    this.navigate([RoutesPaths.DatiContatto]);
                } else {
                    const uriSegments = this.currentLocation.split('/');
                    const psSegmentIndex = uriSegments.indexOf('partnership');
                    const currentPartnership = uriSegments[psSegmentIndex + 1];
                    const nextPartnership = enabledPartnerships[enabledPartnerships.indexOf(currentPartnership) + 1];
                    let nextStep: string = RoutesPaths.DatiContatto;
                    if (nextPartnership) {
                        nextStep = `order-entry/partnership/${nextPartnership}`;
                    }
                    this.navigate([nextStep]);
                }
            });
    }

    public gotoOrderEntryStep1() {
        if (
            this.flowType === FlowType.VariazioneTecnicaVerifiche ||
            this.flowType === FlowType.VariazioneTecnicaLavori
        ) {
            // TODO: GOTO PAOLA PAGE (che parametri passare)
            this.navigate([RoutesPaths.VariazioneTecnica]);
        } else if (this.isOcrActive) {
            this.navigate([RoutesPaths.ModalitaAcquisizione]);
        } else {
            this.navigate([RoutesPaths.DatiGenerici]);
        }
    }

    private navigateFromSignatureType() {
        switch (this.signature.d365SignatureType) {
            case D365SignatureType.Cartacea:
                this.navigate([RoutesPaths.FirmaCartacea]);
                break;
            case D365SignatureType.PaperPrintedBySystem:
                this.navigate([RoutesPaths.SceltaContatto]);
                break;
            case D365SignatureType.Doi:
                this.navigate([RoutesPaths.FirmaDoiFull]);
                break;
            case D365SignatureType.DoiLight:
                this.newConvertCartToQuote();
                break;
            case D365SignatureType.Grafometrica:
                this.navigate([RoutesPaths.SceltaContatto]);
                break;
            case D365SignatureType.VocalOrder:
                this.navigate([RoutesPaths.FirmaVocalOrder, { backToBO: false }]);
                break;
            default:
                this.logger.error(
                    'NavigateFromSignature',
                    `Case: ${this.currentLocation}, not managed in switch case`,
                    false
                );
                return;
        }
    }

    /**
     * @description: Naviga alla pagina specificata
     * @param commands: pagina di atterraggio
     * @param extras: parametri di navigazione
     * @param forceReload: dopo la navigazione forza un location.reload()
     */
    navigate(commands: any[], extras?: NavigationExtras): void {
        LoadingService.show();
        this.ngZone
            .run(() => this.router.navigate(commands, extras))
            .then((navigateSuccess) => {
                if (!navigateSuccess) {
                    this.logger.error(`Navigation to '${commands}' failed`);
                }
            })
            .finally(() => LoadingService.hide());
    }

    /**
     * Questa funzione intercetta i cambi di pagina
     */
    private handleRouteChanges(): void {
        this.store.dispatch(setCurrentPage({ url: document.location.pathname }));
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.store.dispatch(setCurrentPage({ url: val.url }));
                if (this.isNoBackPage(this.currentLocation)) {
                    history.pushState(null, null, null);
                }
                window['_uxa'] = window['_uxa'] || [];
                window['_uxa'].push([
                    'trackPageview',
                    window.location.pathname + window.location.hash.replace('#', '?__'),
                ]);
            }
        });
    }

    private isNoBackPage(currentPage: string): boolean {
        if (this.configSrv.config) {
            return this.configSrv.config.pagesWithoutBackBtn.find((p) => RoutesPaths[p] === currentPage) !== undefined;
        }
        return false;
    }

    //#region Getter
    get needUploadIdentityCard(): boolean {
        if (this.flowType === FlowType.SwitchInVoltura) {
            return true;
        } else {
            // TODO-DOM aggiungere prodotti dom?
            const productsNeedsUpload = this.prodTypes.some(
                (r) => (this.configSrv.config?.productCategoriesWithCI || []).indexOf(r) >= 0
            );
            return (
                this.channelAllowUpload && (productsNeedsUpload || this.flowType === FlowType.DomiciliazioneAttivazione)
            );
        }
    }

    get skipTipoEsecuzione(): boolean {
        if ([FlowType.SwitchInVoltura].includes(this.flowType) || isDomiciliationStandAlone(this.flowType)) {
            return true;
        }
        let skip = this.vaCanSkipTipoEsecuzione;
        if (!this.vaCanSkipTipoEsecuzione && this.prodTypes && this.prodTypes.length > 0) {
            const productsWithTipoEsecuzione = [
                AptProductType.TariffaCommodityGas,
                AptProductType.ProdottoCommodityGas,
                AptProductType.TariffaCommodityLuce,
                AptProductType.ProdottoCommodityLuce,
                AptProductType.Manutenzione,
            ];
            skip = this.prodTypes.filter((x: AptProductType) => productsWithTipoEsecuzione.includes(x)).length === 0;
        }
        return skip;
    }

    private get isOcrActive(): boolean {
        if (this.segment === D365CustomerSegment.Microbusiness || this.flowType === FlowType.SwitchInVoltura) {
            return false;
        }
        return this.containsCommodity && this.d2dChannles.includes(this.currentChannel);
    }

    private get currentLocation(): string {
        return location.pathname.substring(1).split(';')[0];
    }
    //#endregion Getter
}

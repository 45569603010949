import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationLocationType, AppState } from '../../../../store/models/app-state';
import { RoutesPaths } from '../../../config/routes-paths';
import { RouterService } from '../../../services/shared/router.service';
import { selectAppState } from '../../../../store/selectors/app.selectors';
import { HeaderDdlElement, HeaderDropdownlist, MainHeaderService } from './service/main-header.service';
import { EglModalWithFooterComponent } from '../../modals/egl-modal-with-footer/egl-modal-with-footer.component';
import { FeatureToggleService } from '../../../services/shared/feature-toggle.service';
import { CartUtilityService } from '../../../services/apttus/tables/cart/cart-utility.service';
import { TranslateService } from '@ngx-translate/core';
import { SurveyService } from '../../../services/shared/survey.service';
import { FLOW_TYPE_LABELS_MAP } from '../../../map/flow-type-labels-map';

@Component({
    selector: 'egl-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('confirmModal', { static: false }) refModalConfirm: EglModalWithFooterComponent;
    modalConfirm = new ModalConfirm();
    subs: Subscription[] = [];
    showBack: boolean;
    showLogo: boolean;
    showMiniCart: boolean;
    showSwiDropdown: boolean;
    show: boolean;
    linkToCatalog: string;
    canSwitchInVip: boolean;
    isSurveyAvailable: boolean;
    currentPage: string;
    readonly hideShowBack = [
        RoutesPaths.SettingsNoBack,
        RoutesPaths.Dashboard,
        RoutesPaths.DashboardInitial,
        RoutesPaths.ThankYouPage,
        RoutesPaths.CartActive,
        RoutesPaths.OrderEntry,
        RoutesPaths.TroubleShooting,
        RoutesPaths.CP,
    ];

    readonly showMiniCartPages = [
        RoutesPaths.Dashboard,
        RoutesPaths.DashboardInitial,
        RoutesPaths.OrderEntry,
        RoutesPaths.TroubleShootingCommodity,
        RoutesPaths.TroubleShootingManutenzione,
        RoutesPaths.AllProducts,
        RoutesPaths.CartActive,
    ];

    constructor(
        private store: Store<AppState>,
        private routerSrv: RouterService,
        private headerSrv: MainHeaderService,
        private toggleSrv: FeatureToggleService,
        private cartUtilitySrv: CartUtilityService,
        private translateSrv: TranslateService,
        private surveyService: SurveyService
    ) {}

    ngOnInit(): void {
        this.subscribeOnNavigationChange();
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    onDropdownSelect(element: HeaderDdlElement) {
        this.cartUtilitySrv.currentCartIsEmpty().subscribe((isEmpty) => {
            if (isEmpty) {
                element.selected = true;
                this.headerSrv.performActionDdl(element);
            } else {
                this.showConfirmModal(
                    'HEADER.CONFIRM_BEFORE_NEW_CART',
                    FLOW_TYPE_LABELS_MAP[element.value.flowType],
                    () => (this.headerSrv.performActionDdl(element), (element.selected = true))
                );
            }
        });
    }

    onDiscardSelect(element: HeaderDdlElement) {
        this.cartUtilitySrv.currentCartIsEmpty().subscribe((isEmpty) => {
            if (isEmpty) {
                element.selected = false;
                this.headerSrv.discardActionDdl(element);
            } else {
                this.showConfirmModal(
                    'HEADER.DISCARD_BEFORE_NEW_CART',
                    FLOW_TYPE_LABELS_MAP[element.value.flowType],
                    () => (this.headerSrv.discardActionDdl(element), (element.selected = false))
                );
            }
        });
    }

    subscribeOnNavigationChange(): void {
        this.subs.push(
            this.store.select(selectAppState).subscribe((state: AppState) => {
                this.showLogo = state.applicationLocation !== ApplicationLocationType.Web;
                const url = (this.currentPage = location.href);

                this.checkSurveyAvailability();
                // SHOW HEADER LOGIC
                this.show = url.indexOf('/u/') === -1 && url.indexOf(RoutesPaths.AssetDetail.replace(':id', '')) === -1;
                if (!this.show) {
                    return;
                }

                this.showMiniCart = this.showMiniCartPages.find((x) => url.indexOf(x) !== -1) !== undefined;
                this.showBack = this.hideShowBack.find((x) => url.indexOf(x) !== -1) === undefined;
            })
        );
    }

    showConfirmModal(messageKey: string, operationKey: string, functionOnConfirm: () => any): void {
        this.modalConfirm = new ModalConfirm(
            this.translateSrv.instant(messageKey, { operation: this.translateSrv.instant(operationKey) }),
            functionOnConfirm
        );
        this.refModalConfirm.show();
    }

    private checkSurveyAvailability() {
        this.surveyService.showSurveyButton(this.currentPage).then((response) => {
            this.isSurveyAvailable = response;
        });
    }

    openSurveyModal() {
        this.surveyService.openSurvey(this.currentPage).then(() => this.checkSurveyAvailability());
    }

    async goBack(): Promise<void> {
        await this.routerSrv.back();
    }

    public get headersElements(): HeaderDropdownlist[] {
        if (this.toggleSrv.isSwitchInE2EEnabled) {
            return this.headerSrv.headersElements;
        }
    }
}

class ModalConfirm {
    title: string;
    message: string;
    confirmBtn: string;
    onConfirmFunction: () => any;
    constructor(m?: string, f?: () => any, t = 'Attenzione', b = 'Conferma') {
        this.message = m;
        this.onConfirmFunction = f;
        this.title = t;
        this.confirmBtn = b;
    }
}

import { Injectable, ElementRef } from '@angular/core';
import { Indirizzo } from '../../../modules/switch-in/order-entry/models/indirizzi';
import * as moment from 'moment';
import { ApiService } from './api.service';
import { LoadingService } from './loading.service';
import { CNL } from '../../models/egon/egon-response';
import { EgonResponse } from '../../../modules/switch-in/order-entry/models/egon-response';
import { EglQuotePAVLight } from '../../models/apttus/tables/pav/egl-quote-p-a-v-light';
import { PrivateConfigurationService } from './private-configuration.service';
import { getFullAddressStringFromObj } from '../../functions/string-format.functions';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    constructor(private api: ApiService, private configSrv: PrivateConfigurationService) {}

    /**
     * @description: converte una stringa base64 in un ArrayBuffer
     * @param base64string: stringa base64
     * @return: ArrayBuffer
     */
    base64ToArrayBuffer(base64string: string): ArrayBuffer {
        if (base64string) {
            let tmp = base64string;
            const arr = tmp.split('base64,');
            if (arr.length === 2) {
                tmp = arr[1];
            }

            const binaryString = window.atob(tmp);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes.buffer;
        }
        return null;
    }

    /**
     * Trasforma una stringa in base64 o un arrayBuffer in un oggetto di tipo File
     * @param body stringa base64 oppure ArrayBuffer
     * @param nameAndExt Nome file con estenzione
     * @param mime mimetype del file
     */
    arrayBufferToFileObj(body: ArrayBuffer | string, nameAndExt: string, mime: string): File {
        if (typeof body === 'string') {
            body = this.base64ToArrayBuffer(body);
        }

        const content = new Blob([body]);
        const res = new File([content], nameAndExt, {
            type: mime,
        });
        return res;
    }

    /**
     * Verifica che il file non superi la dimensione massima settata nell'enviroment
     * @param file File in input da verificare
     */
    isValidSize(file: File): boolean {
        if (this.configSrv.config) {
            return !(file.size >= this.configSrv.config.maxUploadSize);
        }
        return false;
    }

    /**
     * Trasforma il oggetto di tipo File in un base64
     * @param file input file
     */
    async fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    }

    async downloadByURL(url: string): Promise<Blob> {
        const blob = await this.api.getBlobFile<string>(url).catch(() => {});
        return blob;
    }

    toUpper(str: string): string {
        return (str || '').toUpperCase();
    }

    getFileInfoMessage(name: string, size: number, dataOra: Date): string {
        const fileSizeStr = this.formatBytes(size);
        let fileDataOraStr = '';
        if (dataOra) {
            fileDataOraStr = `${moment(dataOra).format('DD/MM/YYYY')} - ${moment(dataOra).format('HH:mm')}`;
        }

        return `
                  Nome: ${name}<br/>
                  Dimensione file: ${fileSizeStr}<br/>
                  Data/ora: ${fileDataOraStr}`.trim();
    }

    /**
     * Rinomina un file
     * @param file File che si vuole rinominare
     * @param newName Nuovo nome CONTENENTE l'estenzione se 'useSameExt' === false
     * @param useSameExt Rinomina il file copiando l'estenzione dal file in input
     */
    renameFile(file: File, newName: string, useSameExt: boolean): File {
        if (file) {
            if (useSameExt) {
                // uso la stessa estenzione del file in input
                const fileNameNoExt = newName.replace(/\.[^/.]+$/, ''); // rimuovo il l'esenzione dal nuovoFileName
                const oldExt = `.${file.name.split('.').pop()}`;
                newName = `${fileNameNoExt}${oldExt}`;
            }

            return new File([file], newName, {
                type: file.type,
                lastModified: new Date().getTime(),
            });
        }
        return null;
    }

    openSignatureApp(codicePlico: string, grafoSignApp: string, delay: number): void {
        if (!codicePlico || !grafoSignApp) {
            return;
        }
        if (delay === 0) {
            delay = 1000;
        }

        LoadingService.show(`Apertura app di firma per plico ${codicePlico}...`);
        setTimeout(() => {
            const openAppCmd = grafoSignApp.replace('{contractCode}', codicePlico);
            location.href = openAppCmd;
            LoadingService.hide();
        }, delay);
    }

    convertDateD365ToApt(date: Date): string {
        if (date) {
            let dataString = date.toString().toLowerCase();
            dataString = dataString.replace('/date(', '').replace(')/', '').split('+')[0];
            const epoch = +dataString;
            const newData = moment(new Date(epoch)).format('YYYY-MM-DD');
            return newData;
        }

        return '';
    }

    scrollToFirstInvalidControl(componentRef?: ElementRef): void {
        setTimeout(() => {
            const selectors = 'form .ng-invalid, form .is-invalid, form.ng-invalid, form.is-invalid';
            let firstInvalidControl = null;
            if (componentRef) {
                firstInvalidControl = componentRef.nativeElement.querySelector(selectors);
            } else {
                firstInvalidControl = document.querySelector(selectors);
            }
            if (firstInvalidControl) {
                window.scroll({
                    top: this.getTopOffset(firstInvalidControl),
                    left: 0,
                    behavior: 'smooth',
                });
            }
        });
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }

    /**
     * Routine che restituisce  una grandezza di bytes normalizzata con un numerdo di cifre dopo la virgola specifico
     * @param byte il valore da normalizzare espresso in bytes
     * @param decimals il numero di cifre dopo la virgola
     */
    formatBytes(bytes: number, decimals = 2): string {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return '' + parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * Metodo che recupera i dati dell'indirizzo dal pav e compone una stringa unica contenente l' indirizzo
     * @param pav: Il product attibute value contenete i dati relativi al prodotto nel carrello.
     * Restituisce una stringa contenente l'indirizzo di fornitura.
     */
    getIndirizzoFornituraByPAV(pav: EglQuotePAVLight): string {
        if (pav?.egl_Address__c) {
            const i = new Indirizzo();
            i.cap = pav.egl_Postal_Code__c;
            i.civico = pav.egl_Civic_Number__c;
            i.comune = pav.egl_City__c;
            i.prov = pav.egl_Province__c;
            i.via = pav.egl_Address__c;
            i.toponomastica = pav.egl_product_address1_prefix__c;
            return getFullAddressStringFromObj(i);
        }
        return '';
    }

    /**
     * Return initils by a full name (e.g. MARIO ROSSI return MR)
     * @param cleanedName full name string
     */
    getInitials(completeName: string): string {
        if (!completeName) return '';

        const cleanedName = completeName.replace(/[^a-zA-Z- ]/g, '');
        const isSingleName = cleanedName.split(' ').length === 1;
        if (isSingleName) {
            if (cleanedName.length < 2) {
                return cleanedName.toUpperCase();
            } else {
                return cleanedName.substring(0, 2).toUpperCase();
            }
        } else {
            let initials = cleanedName.match(/\b\w/g);
            return initials.slice(0, 2).join('').toUpperCase();
        }
    }

    getValueFromHash(valueName: string): string {
        const hash = location.hash;
        if (hash.length !== 0) {
            const url = new URL(`https://${location.host}?${hash.slice(1)}`);
            const value = url.searchParams.get(valueName);
            if (value !== null) {
                return value;
            }
        }

        return '';
    }

    /**
     * Return the distributor code from a POD, it extracts the third, fourth and fifth character from POD eg: 018 from IT018E10006581
     * @param pod string
     * @returns string
     */
    getDistributorCode(pod: string): string {
        if (pod && pod.length > 5) {
            return pod.substring(2, 5);
        }
        return '';
    }

    /**
     * Return blurred pod string, for privacy concern
     * @param pod string
     * @returns blurred Pod according rule
     */
    blurringPod(pod: string): string {
        if (pod) {
            const podArray = pod.split('');
            podArray.splice(3, 2, '*', '*');
            podArray.splice(9, 3, '*', '*', '*');
            const result = podArray.join('');
            return result;
        }
        return '';
    }

    /**
     * Return blurred pdr string, for privacy concern
     * @param pdr string
     * @returns blurred Pdr according rule
     */
    blurringPdr(pdr: string): string {
        if (pdr) {
            const pdrArray = pdr.split('');
            pdrArray.splice(4, 3, '*', '*', '*');
            pdrArray.splice(9, 3, '*', '*', '*');
            const result = pdrArray.join('');
            return result;
        }
        return '';
    }

    /**
     * Return blurred iban string, for privacy concern
     * @param iban string
     * @returns blurred Pdr according rule
     */
    blurringIban(iban: string): string {
        if (iban) {
            const result = '***' + iban.slice(-4);
            return result;
        }
        return '';
    }

    /**
     * Add a number of working days (Monday-Friday) to input Date
     * @param originalDate date which to add days
     * @param numDaysToAdd number of workdays to add
     * @returns JS Date with days added
     */
    addBusinessDays(originalDate: moment.MomentInput, numDaysToAdd: number): string {
        return this.addMomentBusinessDays(originalDate, numDaysToAdd).format('YYYY-MM-DD');
    }
    addMomentBusinessDays(originalDate: moment.MomentInput, numDaysToAdd: number): moment.Moment {
        const Sunday = 0;
        const Saturday = 6;
        let daysRemaining = numDaysToAdd;

        const targetDate = moment(originalDate);

        while (daysRemaining > 0) {
            targetDate.add(1, 'days');
            if (![Sunday, Saturday].includes(targetDate.day())) {
                daysRemaining--;
            }
        }
        return targetDate;
    }
}

import { DocTypeOpenText } from '../enums/shared/doc-types-open-text';
import { ApiService } from '../services/shared/api.service';
import { GenerateDocumentOT } from '../models/app/generate-document-ot-response';
import { Injectable } from '@angular/core';
import { ApttusDoOperationResponse } from '../models/apex-rest/doi-resubmission';
import { AptOperationType } from '../enums/apttus/apt-operation-type';
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { RetrieveDataSalesupResponse, RetrieveEsitiDataResponse } from '../models/app/recupera-dati-salesup.response';
import { RetrieveDataSalesupRequest } from '../models/app/recupera-dati-salesup.request';
import { Store } from '@ngrx/store';
import { ApexApi, ApiMngApi, ApttusApi, BaseProvider } from './base-provider';
import { QueryOptions } from '@congacommerce/core';
import { EglBoReason } from '../models/apttus/tables/quote/egl-bo-reason';
import { ApttusQueryResponse } from '../models/apttus/request-response/apttus-query-response';
import { catchError, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ApttusUpsertRequest } from '../models/apttus/request-response/apttus-upsert-request';
import { SalesupStateResponse } from '../models/apex-rest/salesup-state-response';
import { RecuperaDatiRegistrazioneRequest } from '../models/app/recupera-dati-registrazione.request';
import { RecuperaDatiRegistrazioneResponse } from '../models/app/recupera-dati-registrazione.response';
import { MailBlacklistRequest, MailBlacklistResponse } from '../models/apex-rest/mail-blacklist';
import { BaseApexApiResponse } from '../interfaces/base-apex-api-respose';
import { PrivateConfigurationService } from '../services/shared/private-configuration.service';
import { selectFlowType } from '../../store/selectors/order-entry.selectors';
import { EglState } from '../../store/reducers';
import { RetrieveCreditCheckRequest } from '../models/app/credit-check.request';
import { StatusCancellationRequest } from '../../modules/status/cancellation/models/status-cancellation.request';
import { StatusCancellationResponse } from '../../modules/status/cancellation/models/status-cancellation.response';
import { CheckItemsRequest } from '../models/apex-rest/check-item-request';
import { CheckItemsResponse } from '../models/apex-rest/check-item-response';
import { FeatureToggleService } from '../services/shared/feature-toggle.service';
import { CheckAttributeItem, CheckAttributeResponse } from '../models/apex-rest/checkattribute-response';
import * as _ from 'lodash';
import { ScontiListResponse, ScontoListItem } from '../models/apex-rest/scontilist-response';
import { selectUserState } from '../../store/selectors/user.selectors';
import { StandaloneDiscountResponse } from '../models/app/standalone-discount-response';
import { Customer360Response } from '../models/app/customer-360';
import { LoggerService } from '../services/shared/logger.service';

@Injectable({ providedIn: 'root' })
export class CommonProvider extends BaseProvider {
    constructor(
        private api: ApiService,
        protected configSrv: PrivateConfigurationService,
        private store: Store<EglState>,
        private toggleService: FeatureToggleService,
        private loggerService: LoggerService
    ) {
        super(configSrv);
    }

    async generateDocumentOT(apttusQuoteId: string, documentType: DocTypeOpenText): Promise<GenerateDocumentOT> {
        const flowType = await this.store.select(selectFlowType).pipe(take(1)).toPromise();
        const stubbedFlowTypes = (this.configSrv.config.plicoApiStubbedFor || []).map((ft: string) => ft.toUpperCase());
        const isApiStubbed =
            stubbedFlowTypes.indexOf('ALL') !== -1 || stubbedFlowTypes.indexOf(flowType.toUpperCase()) !== -1;
        const api = isApiStubbed ? ApiMngApi.StubGenerateDocOT : ApiMngApi.GenerateDocOT;
        if (!isApiStubbed) {
            return this.api
                .postAsync<GenerateDocumentOT>(this.getApiMngApiUrl(api), {
                    id: apttusQuoteId,
                    docType: documentType,
                })
                .toPromise();
        } else {
            return this.api
                .postAsync<GenerateDocumentOT>(this.getApiMngApiUrl(api), {
                    quoteId: apttusQuoteId,
                    docType: documentType,
                })
                .toPromise();
        }
    }

    generateDocumentOT_obs(apttusQuoteId: string, documentType: DocTypeOpenText): Observable<GenerateDocumentOT> {
        return this.store.select(selectFlowType).pipe(
            take(1),
            mergeMap((flowType) => {
                const stubbedFlowTypes = (this.configSrv.config.plicoApiStubbedFor || []).map((ft: string) =>
                    ft.toUpperCase()
                );
                const isApiStubbed =
                    stubbedFlowTypes.indexOf('ALL') !== -1 || stubbedFlowTypes.indexOf(flowType.toUpperCase()) !== -1;
                const api = isApiStubbed ? ApiMngApi.StubGenerateDocOT : ApiMngApi.GenerateDocOT;

                if (!isApiStubbed) {
                    return this.api.postAsync<GenerateDocumentOT>(this.getApiMngApiUrl(api), {
                        id: apttusQuoteId,
                        docType: documentType,
                    });
                } else {
                    return this.api.postAsync<GenerateDocumentOT>(this.getApiMngApiUrl(api), {
                        quoteId: apttusQuoteId,
                        docType: documentType,
                    });
                }
            })
        );
    }

    private async genericDoApttusAction(params?: any): Promise<ApttusDoOperationResponse> {
        return this.api
            .postAsync<ApttusDoOperationResponse>(this.getApexApiUrl(ApexApi.DoApttusAction), params)
            .toPromise()
            .catch(() => {
                return null;
            });
    }

    // verifica l'univocita di contract code
    async isContractCodeValid(contractCode: string): Promise<boolean> {
        const req = {
            ContractCode: {
                Code: contractCode,
                Operation: AptOperationType[7],
            },
        };
        const resp = await this.genericDoApttusAction(req);
        return resp && resp.Result === '001';
    }

    /**
     * @description: recupera lo state (Redux) da una tabella su SF
     * @param productconfigurationid: è l'id del carrello a cui è associato lo state
     */
    getSalesUpState(productconfigurationid: string): Observable<SalesupStateResponse> {
        const options = {
            headers: new HttpHeaders({
                'no-loading': 'true',
            }),
        };
        return this.api.getAsync<SalesupStateResponse>(
            this.getApexApiUrl(ApexApi.GetSalesUpState),
            null,
            { productconfigurationid },
            0,
            options
        );
    }

    /**
     * @description: salva lo state (Redux) su una tabella su SF
     * @param productconfigurationid: è l'id del carrello a cui è associato lo state
     * @param productconfigurationid: è lo state Rudux
     */
    saveSalesUpState(
        productconfigurationid: string,
        state: string
    ): Observable<{ Status: string; Result: string; ErrorMessage: string }> {
        const options = {
            headers: new HttpHeaders({
                'no-loading': 'true',
            }),
        };
        return this.api
            .postAsync<{ Status: string; Result: string; ErrorMessage: string }>(
                this.getApexApiUrl(ApexApi.SaveSalesUpState),
                { productconfigurationid, state },
                undefined,
                options
            )
            .pipe(
                tap((resp) => {
                    if (resp?.Result !== '001') {
                        throw new Error(resp?.ErrorMessage || 'SalesUP state failed');
                    }
                    this.loggerService.info(`SalesUP state saved ${new Date().toLocaleTimeString()}`);
                })
            );
    }

    /**
     * @description: invoca la hadless API 'QUERY'
     * @param q: query da eseguire
     */
    query<T>(q: string): Observable<T> {
        return this.api.getAsync<T>(this.getApttusApiUrl(ApttusApi.QueryData), null, { q }, 0);
    }

    /**
     * @description: search CF or PIVA on customer base
     * @param fieldName: 'CF' | 'PIVA' | 'CODCONTO' | 'CODCLIENTE'
     * @param fieldValue: value to search
     * @param source: 'H' for HomePage, 'O' for OrderEntry
     * @param skipCC: skip Credit Check
     * @param skipInsolutoNDS: skip controllo Insoluto NDS
     */
    getSalesUpClientData = (
        fieldName: 'CF' | 'PIVA' | 'CODCONTO' | 'CODCLIENTE',
        fieldValue: string,
        source: 'H' | 'O',
        skipCC: boolean,
        skipInsolutoNDS: boolean,
        skipCF?: boolean
    ): Observable<RetrieveDataSalesupResponse> => {
        const req: RetrieveDataSalesupRequest = { fieldName, fieldValue, source, skipCC, skipInsolutoNDS, skipCF };
        const endpoint = this.toggleService?.isSwitchInE2EEnabled
            ? ApiMngApi.RetrieveCustomerData
            : ApiMngApi.RetrieveDataSalesUp;
        return this.api.postAsync<RetrieveDataSalesupResponse>(this.getApiMngApiUrl(endpoint), req).pipe(
            tap((res) => {
                if (res instanceof Error) {
                    this.loggerService.error(null, res?.message);
                    throw res;
                }
                return res;
            })
        );
    };

    /**
     * @description: credit check by CF or PIVA for specific users catagory
     * @param req
     */
    creditCheckFromDDL(req: RetrieveCreditCheckRequest): Observable<RetrieveEsitiDataResponse> {
        try {
            return this.api.postAsync<RetrieveEsitiDataResponse>(
                this.getApiMngApiUrl(ApiMngApi.RetrieveDataCreditCheck),
                req
            );
        } catch (error) {
            return of(null);
        }
    }

    /**
     * @description: Esegue la hadless api di query
     * @param entityName: nome dell'entità
     * @param queryOptions: body della request
     * @return: Observable<T>
     */
    queryHadless<T>(entityName: string, queryOptions: QueryOptions): Observable<T> {
        return this.api.postAsync<T>(
            this.getApttusApiUrl(ApttusApi.Query).replace('{entityName}', entityName),
            queryOptions
        );
    }

    /**
     * @description: Esegue la headless api generica di Upsert
     * @param entityName nome dell'entità
     * @param body body della request
     * @return: Observable<T>
     */
    genericUpsertHeadless<T>(entityName: string, body: any[]): Observable<T> {
        return this.api.putAsync<T>(this.getApttusApiUrl(ApttusApi.Generic).replace('{entityName}', entityName), body);
    }

    /**
     * @description Recupera da Apttus i dati delle motivazioni di offerta cancellata/rifiutata
     * @returns Observable<EglBoReason[]>
     */
    getBoReasons(type: 'CANCELLA' | 'RIFIUTA'): Observable<EglBoReason[]> {
        const table = 'egl_bo_reason__c';
        //TODO Lorenzo - Commentato in attesa del SDK '21
        // const queryOptions = {
        //     cacheStrategy: 'freshness',
        //     conditions: [
        //         {
        //             field: 'egl_reason_type__c',
        //             filterOperator: 'Equal',
        //             val: type,
        //             value: type,
        //             apiName: table,
        //         },
        //     ],
        // };
        // return this.commonPrv
        //     .queryHadless<ApttusHadlessQueryResponse<EglBoReason>>(table, queryOptions as QueryOptions)
        //     .pipe(
        //         take(1),
        //         filter((res) => !!res && !!res.data),
        //         map((res: ApttusHadlessQueryResponse<EglBoReason>) => res.data)
        //     );

        const query = `SELECT Id, egl_reason_title__c, egl_reason_description__c, egl_reason_type__c
                       FROM ${table}
                       WHERE egl_reason_type__c = '${type}'`;
        return this.query<ApttusQueryResponse<EglBoReason>>(query).pipe(
            filter((res: ApttusQueryResponse<EglBoReason>) => !!res && !!res.records),
            map((res: ApttusQueryResponse<EglBoReason>) =>
                res.records.map(
                    ({ Id, egl_reason_title__c, egl_reason_description__c, egl_reason_type__c }) =>
                        ({ Id, egl_reason_title__c, egl_reason_description__c, egl_reason_type__c } as EglBoReason)
                )
            )
        );
    }

    /**
     * @description Esegue l'insert o l'update dell'entità EglBoReason
     * @param data La richiesta che contiene i record da aggiornare/inserire
     * @returns Observable<HttpResponse<any>>
     */
    upsertBoReasons(data: ApttusUpsertRequest<EglBoReason>): Observable<HttpResponse<any>> {
        return this.api.patchAsync(
            this.appConfig.endpoints.apttus.baseUrl,
            'services/data/v50.0/composite/sobjects/egl_bo_reason__c',
            'Id',
            data
        );
    }

    /**
     * @description: Esegue la delete tramite l'api BOReasonDelete
     * @param param: parametro della request
     * @return: Observable<T>
     */
    deleteBoReasons(id: string): Observable<BaseApexApiResponse> {
        const param = {
            id: id,
        };
        return this.api.deleteAsync<BaseApexApiResponse>(this.getApexApiUrl(ApexApi.Delete), param);
    }

    /**
     * @description Effattua chiamata per restituire i dati di registrazione
     * @param request: La richiesta che contiene i record da aggiornare/inserire
     * @returns Observable<HttpResponse<any>>
     */
    retrieveRegistrationData(request: RecuperaDatiRegistrazioneRequest): Promise<RecuperaDatiRegistrazioneResponse> {
        return this.api
            .postAsync<RecuperaDatiRegistrazioneResponse>(
                this.getApiMngApiUrl(ApiMngApi.RetrieveRegistrationData),
                request
            )
            .toPromise()
            .catch(() => {
                return null;
            });
    }

    /**
     * @description Effettua chiamata per controllare se la mail fornita in ingresso è in blacklist
     * @param mail La mail da controllare
     * @param hideLoading Nasconde lo spinner di caricamento
     * @returns Observable<MailBlacklistResponse>
     */
    isEmailBlacklisted(mail: string, hideLoading = true): Observable<MailBlacklistResponse> {
        const options = {
            headers: new HttpHeaders(),
        };
        if (hideLoading) {
            options.headers = options.headers.append('no-loading', 'true');
        }
        const request = new MailBlacklistRequest([mail]);
        return this.api
            .postAsync<MailBlacklistResponse>(this.getApexApiUrl(ApexApi.MailBlacklist), request, 0, options)
            .pipe(catchError(() => of(null)));
    }

    /**
     * @description Effettua un Cancel o un Amend di un ordine
     * @param req object con i parametri della request
     * @returns Promise<StatusCancellationResponse>
     */
    cancelOrAmend(req: StatusCancellationRequest): Observable<StatusCancellationResponse> {
        return this.api.postAsync<StatusCancellationResponse>(this.getApiMngApiUrl(ApiMngApi.CancelOrAmend), req);
    }

    checkItems(req: CheckItemsRequest): Observable<CheckItemsResponse> {
        return this.api.postAsync<BaseApexApiResponse>(this.getApexApiUrl(ApexApi.CheckItems), req);

        // return of({
        //     status: StatusApexResponse.Success,
        //     result: 'OK',
        // });
    }

    /**
     * @description: consente di recuperare l'elenco degli attributi variati per un carrello di variazione commerciale in base al suo identificativo
     * @param productconfigurationid: identificativo del carrello per il quale si desidera recuperare gli attributi variati.
     */
    getModifiedAttributes(productconfigurationid: string): Observable<CheckAttributeResponse> {
        const options = {
            headers: new HttpHeaders({
                'no-loading': 'true',
            }),
        };
        return this.api
            .getAsync<CheckAttributeResponse>(
                this.getApexApiUrl(ApexApi.GetModifiedAttributes),
                null,
                { productconfigurationid },
                0,
                options
            )
            .pipe(
                mergeMap((response) => {
                    response.Items = !_.isNil(response?.Message)
                        ? JSON.parse(response.Message).map((c: any) => {
                              return {
                                  ...c,
                                  newValue: c.newValue == 'Y' ? 'Si' : c.newValue == 'N' ? 'No' : c.newValue,
                              } as CheckAttributeItem;
                          })
                        : null;
                    return of(response);
                })
            );
    }

    /**
     * @description: Recupera i dati del cliente dal servizio APIGEE Customer360.
     * @param fieldName: 'CF' | 'PIVA'
     * @param fieldValue: value to search
     */
    getCustomer360Data = (fieldName: 'CF' | 'PIVA', fieldValue: string): Observable<Customer360Response> => {
        const req = { fieldName, fieldValue };
        try {
            return this.api.postAsync<Customer360Response>(
                this.getApiMngApiUrl(ApiMngApi.RetrieveDataCustomer360),
                req
            );
        } catch (error) {
            return of(null);
        }
    };

    /**
     * @deprecated API in dismissione e mantenuta solo per il periodo di transizione tra Siebel e Customer360
     * Usarla solo per per motivi di compatibilità.
     * RIMUOVIMI DOPO IL PERIODO DI TRANSIZIONE
     * @description: Recupera i dati del cliente da Siebel.
     * @param fieldName: 'CF' | 'PIVA' | 'CODCONTO' | 'CODCLIENTE'
     * @param fieldValue: value to search
     * @param source: 'H' for HomePage, 'O' for OrderEntry
     * @param skipCC: skip Credit Check
     * @param skipInsolutoNDS: skip controllo Insoluto NDS
     */
    recuperaDatiSalesUp = (
        fieldName: 'CF' | 'PIVA' | 'CODCONTO' | 'CODCLIENTE',
        fieldValue: string,
        source: 'H' | 'O',
        skipCC: boolean,
        skipInsolutoNDS: boolean,
        skipCF?: boolean
    ): Observable<RetrieveDataSalesupResponse> => {
        const req: RetrieveDataSalesupRequest = { fieldName, fieldValue, source, skipCC, skipInsolutoNDS, skipCF };
        try {
            return this.api.postAsync<RetrieveDataSalesupResponse>(
                this.getApiMngApiUrl(ApiMngApi.RetrieveDataSalesUp),
                req
            );
        } catch (error) {
            return of(null);
        }
    };

    /**
     * @description: consente di recuperare l'elenco degli sconti disponibili per un determinato cliente in base al codice fiscale o Partita Iva
     * @param cf: codice fiscale per il quale si desidera recuperare l'elenco degli sconti disponibili.
     */
    getScontiList(cfOrPiva: string): Observable<ScontiListResponse> {
        const options = {
            headers: new HttpHeaders({
                'no-loading': 'true',
            }),
        };
        return this.api
            .getAsync<ScontiListResponse>(
                this.getApexApiUrl(ApexApi.GetScontiList),
                null,
                { identificativo: cfOrPiva },
                0,
                options
            )
            .pipe(
                mergeMap((response: ScontiListResponse) => {
                    if (response.Status === '002') {
                        this.loggerService.error(
                            'Error while retrieving discount list from Apttus.',
                            response.Message,
                            null,
                            false,
                            false
                        );
                        response.Items = [];
                    } else
                        response.Items = !_.isNil(response?.Message)
                            ? JSON.parse(response.Message).map((c: any) => {
                                  return {
                                      ...c,
                                  } as ScontoListItem;
                              })
                            : null;
                    return of(response);
                })
            );
    }

    createStandaloneDiscountCart(assetLineItemId: string, productId: string): Observable<StandaloneDiscountResponse> {
        return this.store.select(selectUserState).pipe(
            take(1),
            switchMap((userState) => {
                const body = {
                    AssetId: assetLineItemId,
                    ProductId: productId,
                    User: userState.agentInfo.DomainName,
                };
                return this.api.postAsync<StandaloneDiscountResponse>(
                    this.getApiMngApiUrl(ApiMngApi.StandaloneDiscount),
                    body
                );
            })
        );
    }
}

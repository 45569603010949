import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { setUrlHash } from './store/actions/app.actions';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import { InternetConnectionService } from './common/services/app/internet-connection-service';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from './common/services/shared/logger.service';
import { Cart, CartService, UserService } from '@congacommerce/ecommerce';
import { D365Service } from './common/services/d365/d365.service';
import { ApttusService } from './common/services/apttus/apttus.service';
import { LoadingService } from './common/services/shared/loading.service';
import { distinctUntilChanged, filter, map, mergeMap, skip, take } from 'rxjs/operators';
import { EglCartExtended } from './common/models/apttus/tables/cart/egl-cart-extended';
import { EglState } from './store/reducers';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalComponent } from './common/components/modal/modal.component';
import { RoutesPaths } from './common/config/routes-paths';
import { selectAgentInfo, selectLead, selectUserProfile, selectUserState } from './store/selectors/user.selectors';
import { Lead } from './common/models/user/lead';
import { ACondition, APageInfo, ASort } from '@congacommerce/core';
import { combineLatest, Observable, of } from 'rxjs';
import { D365CustomerSegment } from './common/enums/d365/d365-customer-segment';
import { setCartSegment, setLead } from './store/actions/user.actions';
import { AgentInfo } from './common/models/user/agent';
import { UserState } from './store/models/user-state';
import { Contact } from './common/models/user/contact';
import { OrderEntryState } from './store/models/order-entry-state';
import { EglCartLight } from './common/models/apttus/tables/cart/egl-cart-light';
import { EglCartLightService } from './common/services/apttus/tables/cart/egl-cart-light.service';
import { SalesupStateResponse } from './common/models/apex-rest/salesup-state-response';
import { CommonProvider } from './common/providers/common-provider';
import * as _ from 'lodash';
import { AptSalesProcess } from './common/enums/apttus/apt-sales-process';
import { UserProfile } from './common/models/user/user-profile';
import { convertSegmentD365toApt } from './common/functions/remap.functions';
import { AppInsightsService } from './common/services/shared/app-insights.service';
import * as moment from 'moment';
import { selectHasDevBar } from './store/selectors/app.selectors';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { UtilityService } from './common/services/shared/utility.service';
import { activationCartFromUrl } from './common/functions/misc.functions';
import { selectSalesProcess } from './store/selectors/order-entry.selectors';
import { EglSalesupStateService } from './common/services/apttus/tables/egl-salesup-state.service';
import { OAuthService } from './common/services/oauth/oauth-services';
@Component({
    selector: 'egl-root',
    template: `
        <ng-container>
            <egl-dev-bar></egl-dev-bar>
            <div [ngStyle]="{ 'padding-top': paddingDev$ | async }">
                <router-outlet *ngIf="!inLoading"></router-outlet>
            </div>
        </ng-container>
        <ng-template #modal>
            <egl-modal
                [modalRef]="modalRef"
                [title]="'Abbandona carrello'"
                [description]="modalDescription"
                [txtBtnSx]="deleteCartText"
                [txtBtnCn]="'Salva per dopo'"
                [txtBtnDx]="closeButtonText"
                (btnSxClick)="onClearCartClick()"
                (btnCnClick)="onSaveClick()"
            >
            </egl-modal>
        </ng-template>
    `,
})
export class AppComponent implements OnInit {
    @ViewChild('modal', { static: true }) modal: TemplateRef<ModalComponent>;
    modalRef: BsModalRef;
    modalDescription: string;
    inLoading = true;
    segmentToBe: D365CustomerSegment;
    cartId: string;
    userState: UserState;
    orderEntryState: OrderEntryState;
    leadFromCRM: Lead;
    contactFromCRM: Contact;
    userProfile: UserProfile;
    paddingDev$ = this.store.select(selectHasDevBar).pipe(map((x) => (x ? '18px' : '0px')));
    insideOrderEntry: boolean;
    deleteCartText = this.translateService.instant('ORDER_ENTRY.CART_MODAL.RESUME_DELETE');
    closeButtonText = this.translateService.instant('ORDER_ENTRY.CART_MODAL.RESUME_CLOSE');
    allowedRestoreSalesProcess = [AptSalesProcess.SwitchIn, AptSalesProcess.AttivazioneSemplice];

    private readonly NO_RESTORE_CART = [
        RoutesPaths.BackOffice,
        'asset/detail',
        'cp/',
        RoutesPaths.Offline,
        RoutesPaths.BulkOrderBasePath,
    ];

    constructor(
        private translate: TranslateService,
        private intenetConnectionSrv: InternetConnectionService,
        private store: Store<EglState>,
        private cartLightSrv: EglCartLightService,
        private modalSrv: BsModalService,
        private logger: LoggerService,
        private d365Srv: D365Service,
        private apttusSrv: ApttusService,
        private userSrv: UserService,
        private commonPrv: CommonProvider,
        private appInsightsSrv: AppInsightsService,
        private cartSrv: CartService,
        private translateService: TranslateService,
        private utilityService: UtilityService,
        private stateSrv: EglSalesupStateService,
        private authSrv: OAuthService
    ) {
        moment.locale(environment.defaultLanguage);
        registerLocaleData(localeIt, environment.defaultLanguage);

        this.logger.info('Location hash: ' + location.hash || 'null');
        this.store.dispatch(setUrlHash({ hash: location.hash }));
    }

    /**
     * @description: Metodo dell'interfaccia OnInit
     */
    async ngOnInit(): Promise<void> {
        this.userProfile = (await this.store.select(selectUserProfile).pipe(take(1)).toPromise()) as UserProfile;

        if (parent.window !== window) {
            this.intenetConnectionSrv.init();
            await this.checkUserAndUrlParams();
            this.setTranslate();
        }

        this.apttusSrv.showCartModal.subscribe(() => {
            this.openDropCartModal();
        });

        this.store
            .select(selectSalesProcess)
            .pipe(
                skip(1), // for catch only dispateched events e not the default initial value
                filter((salesProcess) => !!salesProcess),
                mergeMap((salesProcess) => this.cartLightSrv.updateCartSalesProcess(salesProcess))
            )
            .subscribe();
        await this.authSrv.msalLogin();
    }

    /**
     * @description: Recupero ultimo carrello pending
     */
    private getCurrentCart(): void {
        if (
            (location.pathname.includes('carts/') && !location.pathname.includes('carts/active')) ||
            activationCartFromUrl()
        ) {
            this.logger.info(`Recovery of existing cart Id: ${activationCartFromUrl()}`);
            this.inLoading = false;
            return;
        }

        this.logger.info('Loading Agent Info');
        let startGetSupState;
        this.store
            .select(selectAgentInfo)
            .pipe(
                filter(
                    (agentInfo: AgentInfo) =>
                        !!agentInfo && !!agentInfo.Agent && !!agentInfo.Agency && !!agentInfo.VirtualAgents
                ),
                take(1),
                mergeMap((agentInfo: AgentInfo) => {
                    this.segmentToBe = this.segmentToBe || agentInfo.UserConfiguration.LastUsedCustomerSegment;
                    this.store.dispatch(setCartSegment({ payload: this.segmentToBe }));

                    if (!agentInfo.CanSell) {
                        this.logger.warn('Agent not enabled to sell, skipping retrive cart');
                    }

                    this.logger.info('Agent Info Loaded. Call to getMyCart');

                    if (location.pathname.indexOf('cp/allproducts') !== -1) {
                        this.logger.warn('Richiesta CP Commerciale');
                        return this.apttusSrv.createNewCart(
                            new EglCartExtended(),
                            convertSegmentD365toApt(this.segmentToBe)
                        );
                    }

                    LoadingService.update('Recupero carrello in corso');
                    return this.cartSrv.getMyCart().pipe(
                        take(1),
                        map((x: EglCartExtended) => x)
                    );
                }),
                mergeMap((cart: EglCartExtended) => {
                    if (activationCartFromUrl()) {
                        return of(cart);
                    }

                    if (
                        !cart ||
                        cart.egl_leadid ||
                        cart.egl_customer_type !== convertSegmentD365toApt(this.segmentToBe) ||
                        !this.allowedRestoreSalesProcess.includes(cart.egl_sales_process)
                    ) {
                        this.logger.warn('Carrello non recuperabile: ' + cart?.Id || 'null');
                        // IL CARRELLO CORRENTE RISULTA ASSOCIATO AD UN LEAD OPPURE HA UN SEGMENTO DIVERSO:
                        // CREO UN NUOVO CARRELLO
                        return this.apttusSrv.createNewCart(
                            new EglCartExtended(),
                            convertSegmentD365toApt(this.segmentToBe)
                        );
                    }

                    return of(cart);
                }),
                mergeMap((cart: EglCartExtended) => {
                    this.cartId = cart.Id;
                    startGetSupState = new Date().getTime();
                    return combineLatest([of(cart), this.commonPrv.getSalesUpState(cart.Id)]).pipe(take(1));
                }),
                LoadingService.loaderOperator('Recupero profilo in corso')
            )
            .subscribe(
                ([cart, supState]) => {
                    const endGetSupState = new Date().getTime();
                    this.appInsightsSrv.logMetric(`retrive-sup-state`, (endGetSupState - startGetSupState) / 1000);
                    const foundState = !_.isEmpty(supState.State);
                    let customer = '';
                    if (foundState) {
                        const state: EglState = JSON.parse(supState.State);

                        this.dispatchCurrentCartState(state);
                        this.userState = state.user;
                        this.orderEntryState = state.orderEntry;
                        const contactOrLead = this.userState?.contact || this.userState?.lead;
                        if (contactOrLead) {
                            customer += 'associato a ';
                            customer +=
                                contactOrLead.egl_customersegmentcode === D365CustomerSegment.Microbusiness
                                    ? `${contactOrLead.lastname} (${contactOrLead.egl_vatcode})`
                                    : `${contactOrLead.firstname}  ${contactOrLead.lastname} (${contactOrLead.egl_taxcode})`;
                        }
                    }
                    this.modalDescription = `Esiste un carrello ${
                        customer?.trim() || 'anonimo'
                    }.</br>Come vuoi procedere?`;
                    this.deleteCartText = this.translateService.instant('ORDER_ENTRY.CART_MODAL.RESUME_DELETE');
                    this.closeButtonText = this.translateService.instant('ORDER_ENTRY.CART_MODAL.RESUME_CLOSE');
                    if (cart.egl_sales_process === AptSalesProcess.SwitchIn && cart?.LineItems?.length > 0) {
                        this.modalRef = this.modalSrv.show(this.modal);
                    }
                },
                () => {},
                () => {
                    this.inLoading = false;
                }
            );
    }

    /**
     * @description: Dispatch dello state recuperato
     */
    dispatchCurrentCartState(retrievedState: EglState): void {
        if (!this.insideOrderEntry) {
            this.stateSrv.dispatchState(retrievedState);
        }
    }

    /**
     * @description: Cancella il carrello corrente e ne crea uno nuovo
     */
    onClearCartClick(): void {
        const navigateTo = this.insideOrderEntry ? [RoutesPaths.Dashboard] : null;
        this.apttusSrv.clearAndCreateNewCart(navigateTo);
    }

    /**
     * @description: Salva il carrello corrente e ne crea uno nuovo
     */
    onSaveClick(): void {
        const navigateTo = this.insideOrderEntry ? [RoutesPaths.Dashboard] : null;
        const newC = new EglCartExtended();
        this.apttusSrv
            .createNewCart(newC, convertSegmentD365toApt(this.segmentToBe), false, navigateTo, false, true)
            .subscribe();
    }

    /**
     * @description: Init translator
     */
    private setTranslate(): void {
        this.translate.setDefaultLang(environment.defaultLanguage);
        const sub = this.translate.use(environment.defaultLanguage).subscribe(
            () => {
                // this.translateCacheSrv.init();
            },
            (err) => {
                this.logger.error(null, 'Translator', err, false);
            },
            () => {
                sub?.unsubscribe();
            }
        );
    }

    /**
     * @description:
     */
    private async getLeadForCP(): Promise<void> {
        if (location.pathname.indexOf(RoutesPaths.CP) === -1) {
            return;
        }
        const userType = this.utilityService.getValueFromHash('userType');
        const userGuid = this.utilityService.getValueFromHash('userGuid');
        if (!userGuid) {
            this.logger.error('Errore', 'Non è presente il GUID in queryString', null, false);
            return;
        }

        if (userType !== 'lead') {
            return;
        }
        // L'ENTRY POINT è UN LEAD DA D365
        const lead = await this.d365Srv.getLead(userGuid);
        if (lead) {
            this.segmentToBe = lead.egl_customersegmentcode;
            this.store.dispatch(setCartSegment({ payload: this.segmentToBe }));
            this.store.dispatch(setLead({ l: lead, fcrm: lead !== null }));
        }
    }

    /**
     * @description: Verifico l'utente corrente ed eventuali parametri (token, lead ecc...)
     */
    private async checkUserAndUrlParams(): Promise<void> {
        this.logger.info('Check current user');
        const startCheckIsLoggedIn = new Date().getTime();
        const isLoggedIn = await this.userSrv.isLoggedIn().pipe(take(1)).toPromise();

        const endCheckIsLoggedIn = new Date().getTime();
        this.appInsightsSrv.logMetric(`check-is-logged-in`, (endCheckIsLoggedIn - startCheckIsLoggedIn) / 1000);
        this.logger.info('User is logged in?', null, isLoggedIn);
        if (isLoggedIn) {
            try {
                // Richiesta apertura BO o Scheda Asset
                if (
                    this.NO_RESTORE_CART.filter((x) => location.pathname.indexOf(x) !== -1).length > 0 ||
                    !this.userProfile.canSell
                ) {
                    this.getLeadForCP();
                    this.inLoading = false;
                    // LoadingService.abort();
                    return;
                }
                const hash = location.hash;
                if (hash.length !== 0) {
                    const url = new URL(`https://${location.host}?${hash.slice(1)}`);
                    const userType = url.searchParams.get('userType');
                    const userGuid = url.searchParams.get('userGuid');
                    // const category = url.searchParams.get('leadtopic');
                    const customerSegment = url.searchParams.get('customerSegment');

                    if (userType === 'lead') {
                        // L'ENTRY POINT è UN LEAD DA D365
                        const lead = await this.d365Srv.getLead(userGuid, +customerSegment); // passo il customer segment recuperato dall'url
                        if (lead) {
                            this.segmentToBe = lead.egl_customersegmentcode;
                            this.store.dispatch(setCartSegment({ payload: this.segmentToBe }));
                            this.store.dispatch(setLead({ l: lead, fcrm: lead !== null }));
                        }
                        let startSearch;
                        this.store
                            .select(selectLead)
                            .pipe(
                                filter((l: Lead) => l !== undefined),
                                take(1),
                                mergeMap((l: Lead) => {
                                    this.leadFromCRM = l;
                                    if (l) {
                                        startSearch = new Date().getTime();
                                        const cart$ = this.cartLightSrv.where(
                                            [
                                                new ACondition(
                                                    EglCartLight,
                                                    'egl_sales_process',
                                                    'NotEqual',
                                                    AptSalesProcess.CambioProdotto
                                                ),
                                                new ACondition(EglCartLight, 'egl_leadid', 'Equal', l.egl_code),
                                                new ACondition(
                                                    EglCartLight,
                                                    'Apttus_Config2__BusinessObjectRefId',
                                                    'Equal',
                                                    null
                                                ),
                                            ],
                                            'AND',
                                            null,
                                            [new ASort(EglCartLight, 'LastModifiedDate', 'DESC')],
                                            new APageInfo(1, 1)
                                        ) as Observable<[EglCartLight]>;
                                        return cart$.pipe(
                                            take(1),
                                            map((cart: [EglCartLight]) => cart[0])
                                        );
                                    }
                                    return of(null);
                                }),
                                mergeMap((cart: Cart) => {
                                    if (cart) {
                                        const endSearch = new Date().getTime();
                                        this.appInsightsSrv.logMetric(
                                            `search-lead-cart`,
                                            (endSearch - startSearch) / 1000
                                        );
                                        // ESISTE GIà UN CARRELLO ASSOCIATO AL LEAD
                                        LoadingService.update('Carrello trovato. Ripristino...');
                                        CartService.setCurrentCartId(cart.Id);
                                        this.cartSrv.refreshCart();
                                        return of(cart);
                                    } else {
                                        // CREO UN NUOVO CARRELLO DA ASSOCIARE AL LEAD
                                        LoadingService.update('Nessun carrello trovato');
                                        const newCart = new EglCartExtended();
                                        newCart.egl_leadid = this.leadFromCRM.egl_code;
                                        newCart.egl_tag = this.leadFromCRM.egl_tag;

                                        return this.apttusSrv
                                            .createNewCart(newCart, convertSegmentD365toApt(this.segmentToBe))
                                            .pipe(take(1));
                                    }
                                }),
                                mergeMap((cart?: any) => {
                                    if (cart instanceof EglCartLight) {
                                        LoadingService.update('Verifico sessioni precedenti...');
                                        return this.commonPrv.getSalesUpState(cart.Id).pipe(take(1));
                                    }
                                    return of({});
                                }),
                                LoadingService.loaderOperator('Ricerca carrelli associati al LEAD')
                            )
                            .subscribe((res?: SalesupStateResponse) => {
                                this.inLoading = false;
                                const foundState = !_.isEmpty(res.State);
                                if (foundState) {
                                    this.logger.warn('State found. Dispatch');
                                    const state = JSON.parse(res.State) as EglState;
                                    this.userState = state.user;
                                    this.orderEntryState = state.orderEntry;
                                    this.dispatchCurrentCartState(state);
                                }
                            });
                    } else if (userType === 'account') {
                        // TODO: VERIFICARE SE L'ACCOUNT DEVE FUNZIONARE COME IL LEAD
                        this.contactFromCRM = await this.d365Srv.getContact(userGuid);
                        if (this.contactFromCRM) {
                            this.segmentToBe = this.contactFromCRM.egl_customersegmentcode;
                        }
                        this.getCurrentCart();
                    } else {
                        this.getCurrentCart();
                    }
                } else {
                    this.getCurrentCart();
                }
            } catch (e) {
                this.inLoading = false;
                LoadingService.abort();
                this.logger.error(null, 'An error occurred', e, true);
            }
        } else {
            this.inLoading = false;
            LoadingService.update('Autenticazione in corso');
            this.logger.warn('Attempt login');
        }
    }

    openDropCartModal(): void {
        this.store
            .select(selectUserState)
            .pipe(take(1))
            .subscribe((userState) => {
                const contactOrLead = userState.contact || userState.lead;
                let customer = '';
                if (contactOrLead) {
                    customer += 'associato a ';
                    customer +=
                        contactOrLead.egl_customersegmentcode === D365CustomerSegment.Microbusiness
                            ? `${contactOrLead.lastname} (${contactOrLead.egl_vatcode})`
                            : `${contactOrLead.firstname}  ${contactOrLead.lastname} (${contactOrLead.egl_taxcode})`;
                }
                this.modalDescription = `Stai per abbandonare il carrello ${
                    customer?.trim() || 'anonimo'
                }.</br>Come vuoi procedere?`;

                this.deleteCartText = this.translateService.instant('ORDER_ENTRY.CART_MODAL.DROP_DELETE');
                this.closeButtonText = this.translateService.instant('ORDER_ENTRY.CART_MODAL.DROP_CLOSE');

                this.modalRef = this.modalSrv.show(this.modal);
                this.insideOrderEntry = true;
            });
    }
}

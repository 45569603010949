import { Injectable } from '@angular/core';
import { ClientConstraintRuleService, ConstraintRuleService, ProductOptionService } from '@congacommerce/ecommerce';
import { filter, get, uniqBy, map as _map, isNil, concat, some, compact, isEmpty } from 'lodash';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class EglClientConstraintRuleService extends ClientConstraintRuleService {
    eglProductOptionService: ProductOptionService;
    eglCrService: ConstraintRuleService;
    constructor(productOptionService: ProductOptionService, crService: ConstraintRuleService) {
        super(productOptionService, crService);
        this.eglProductOptionService = productOptionService;
        this.eglCrService = crService;
    }

    getConstraintRulesForProducts(bundleProduct, cartItem) {
        this.loadAlertMessages([]);
        this.appliedRuleActionInfoRec = [];
        if (!isNil(get(cartItem, 'AssetLineItem'))) return of([]);
        let _productList = [bundleProduct];
        if (bundleProduct.HasOptions) {
            const options = _map(this.eglProductOptionService.getProductOptions(bundleProduct), (c) =>
                get(c, 'ComponentProduct')
            );
            _productList = concat(bundleProduct, options);
        }
        const rules$ = this.eglCrService.getConstraintRulesForProducts(uniqBy(_productList, 'Id'), false, true);
        return rules$.pipe(
            map((rules) => {
                return filter(compact(uniqBy(rules, 'Id')), (rule) => {
                    if (isEmpty(get(rule, 'ConstraintRuleActions')) || isNil(get(rule, 'ConstraintRuleActions')))
                        return false;
                    else {
                        let valid = false;
                        _productList.forEach((product) => {
                            const conditions = isNil(get(rule, 'ConstraintRuleConditions'))
                                ? []
                                : get(rule, 'ConstraintRuleConditions', []);
                            const actions = isNil(get(rule, 'ConstraintRuleActions'))
                                ? []
                                : get(rule, 'ConstraintRuleActions', []);
                            const productValid =
                                conditions.filter(
                                    (condition) =>
                                        (condition.ProductScope === 'Product' && product.Id === condition.ProductId) ||
                                        (condition.ProductScope === 'Product Family' &&
                                            product.Family === condition.ProductFamily) ||
                                        (condition.ProductScope === 'Product Group' &&
                                            filter(
                                                get(product, 'ProductGroups', []),
                                                (groupMember) => groupMember.ProductGroupId === condition.ProductGroupId
                                            ).length > 0)
                                ).length > 0;
                            if (productValid) {
                                valid = true;
                                const ruleProducts = get(rule, '_metadata.productList', []);
                                ruleProducts.push(product);
                                rule.set('productList', ruleProducts);
                            }
                            const actionProductValid =
                                actions.filter(
                                    (action) =>
                                        (action.ProductScope === 'Product' && product.Id === action.ProductId) ||
                                        (action.ProductScope === 'Product Family' &&
                                            product.Family === action.ProductFamily) ||
                                        (action.ProductScope === 'Product Group' &&
                                            filter(
                                                get(product, 'ProductGroups', []),
                                                (groupMember) => groupMember.ProductGroupId === action.ProductGroupId
                                            ).length > 0)
                                ).length === actions.length;
                            if (actionProductValid) {
                                valid = true;
                                const ruleProducts = get(rule, '_metadata.actionProductList', []);
                                ruleProducts.push(product);
                                rule.set('actionProductList', ruleProducts);
                            }
                        });
                        return valid;
                    }
                });
            }),
            map((rules) => filter(rules, (r) => r.get('actionProductList')))
        );
    }
}

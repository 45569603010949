import { String } from 'lodash';

export class VoltageLevel {
    code: string;
    value: number;

    constructor(code?: string, value?: number) {
        this.code = code;
        this.value = isNaN(value) ? null : value;
    }
}

export class VoltageLevelActivation extends VoltageLevel {
    transcodingValue: number;
    constructor(code?: string, value?: number, transcodingValue?: number) {
        super(code, value);
        this.transcodingValue = isNaN(transcodingValue) ? null : transcodingValue;
    }
}

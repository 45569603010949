import { EventEmitter, Injectable } from '@angular/core';
import { Cart, CartItem, CartService, ItemGroup, LineItemService, User, UserService } from '@congacommerce/ecommerce';
import { EglCartExtended } from '../../models/apttus/tables/cart/egl-cart-extended';
import { CartInfo, OrderEntryState, ProductInfo } from '../../../store/models/order-entry-state';
import { AptProductFamily } from '../../enums/apttus/apt-product-family';
import { AptProductType } from '../../enums/apttus/apt-product-type';
import {
    resetOrderEntry,
    setCartInfo,
    setCombinedSale,
    setCommodityCartId,
    setOrderEntryState,
    setPlicoUrl,
    setProductsInfo,
} from '../../../store/actions/order-entry.actions';
import { Store } from '@ngrx/store';
import {
    catchError,
    delay,
    filter,
    finalize,
    map,
    mergeMap,
    retry,
    retryWhen,
    scan,
    share,
    take,
    takeWhile,
    tap,
} from 'rxjs/operators';
import { LoggerService } from '../shared/logger.service';
import { AppInsightsService } from '../shared/app-insights.service';
import { LoadingService } from '../shared/loading.service';
import { BehaviorSubject, combineLatest, MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { UtilityService } from '../shared/utility.service';
import { Router } from '@angular/router';
import { selectFullState, selectUserOrderState } from '../../../store/selectors/common.selectors';
import { UserState } from '../../../store/models/user-state';
import { AgentInfo, VirtualAgent } from '../../models/user/agent';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';
import { EglCartLightService } from './tables/cart/egl-cart-light.service';
import * as _ from 'lodash';
import { AptCustomerType } from '../../enums/apttus/apt-customer-type';
import { selectAgentInfo, selectContactLead, selectCurrentVirtualAgent } from '../../../store/selectors/user.selectors';
import { EglCartLight } from '../../models/apttus/tables/cart/egl-cart-light';
import { EglState } from '../../../store/reducers';
import { EglCartItemLight } from '../../models/apttus/tables/cart/egl-cart-item-light';
import { EglPAVLightService } from './tables/egl-pav-light.service';
import { EglCartPAVLight } from '../../models/apttus/tables/pav/egl-cart-p-a-v-light';
import { RoutesPaths } from '../../config/routes-paths';
import { EglQuotePAVLight } from '../../models/apttus/tables/pav/egl-quote-p-a-v-light';
import { EglQuoteLight } from '../../models/apttus/tables/quote/egl-quote-light';
import { OrderEntryProvider } from '../../../modules/common/order-entry/providers/order-entry-provider';
import { CacheService, ConfigurationService, PlatformService, QueryOptions } from '@congacommerce/core';
import { D365ChannelCode } from '../../enums/d365/d365-channel-code';
import { PrivacyType } from '../../enums/shared/privacy-question-type';
import { D365_APT_SIGNATURE_MODE_MAP } from '../../map/apttus-signature-map';
import * as moment from 'moment';
import { D365ConfirmationType } from '../../enums/d365/d365-confirmation-type';
import { D365SignatureType } from '../../enums/d365/d365-signature-type';
import { AptBillType } from '../../enums/apttus/apt-bill-type';
import { Dictionary } from '../../interfaces/dictionary';
import { AptSignatureMode } from '../../enums/apttus/apt-signature-mode';
import { EglQuoteLightService } from './tables/quote/egl-quote-light.service';
import { DocTypeOpenText } from '../../enums/shared/doc-types-open-text';
import { StatusResponse } from '../../interfaces/base-api-response';
import { CommonProvider } from '../../providers/common-provider';
import { CpProvider } from '../../../modules/change-product/provider/cp-provider';
import { ApttusHadlessQueryResponse } from '../../models/apttus/request-response/apttus-hadless-query-response';
import { AptQuoteStatus } from '../../enums/apttus/apt-quote-status';
import {
    resetUserState,
    setCartSegment,
    setContact,
    setCustomerInfo,
    setLead,
} from '../../../store/actions/user.actions';
import { AptSalesProcess } from '../../enums/apttus/apt-sales-process';
import {
    selectActivation,
    selectCpData,
    selectDeferredSaleStatus,
    selectOrderEntryState,
    selectSalesProcess,
} from '../../../store/selectors/order-entry.selectors';
import { AptLineStatus } from '../../enums/apttus/apt-line-status';
import { ApttusQueryResponse } from '../../models/apttus/request-response/apttus-query-response';
import { ApttusAddress } from '../../models/apttus/tables/address/cap';
import { ZipCodeItem } from '../../components/egon-autocomplete/egon-autocomplete.component';
import { FeatureToggleService } from '../shared/feature-toggle.service';
import { CreateQuoteEntitiesResponse } from '../../../modules/common/order-entry/models/create-quote-entities-response';
import { convertSegmentAptToD365, convertSegmentD365toApt, getAgentD365toApt } from '../../functions/remap.functions';
import { ACTIVATION_SALES_PROCESSES } from '../../map/mapping.config';
import {
    isAdministrativeSwitchIn,
    isAdministrativeSwitchInWithCeasedAsset,
    isChangeProduct,
    isDomiciliationStandAlone,
} from '../../functions/verifications.functions';
import { TelemetryMetricService } from '../app/telemetry-metric.service';
import { filterActivePackageProducts } from '../../functions/misc.functions';
import { AptConfirmMode } from '../../enums/apttus/apt-confirm-mode';
import { D365_APT_CONFIRM_MODE_MAP } from '../../map/apttus-confirm-mode-map';
import { FlowType } from '../../../store/models/flow-type';

@Injectable({
    providedIn: 'root',
})
export class ApttusService {
    public showCartModal = new EventEmitter();
    onCreateNewCartCompleted: EventEmitter<string> = new EventEmitter<string>();
    onSetStateFromCartCompleted = new EventEmitter<{ success: boolean }>();
    onUpdatePavsCompleted = new EventEmitter<{ success: boolean; pavsId?: string[] }>();
    private currentOwner: User;
    private orderEntryState: OrderEntryState;
    private userState: UserState;
    private selectedVirtualAgent: VirtualAgent;
    private segment: D365CustomerSegment;
    // tslint:disable-next-line:variable-name
    private _cartId: string;
    private lastSaveQuote: number;
    /// VARIABILI PER IL PROCESSO DI CREAZIONE DELLA QUOTE
    quoteId: string;
    quoteName: string;
    orderEntry: OrderEntryState;
    user: UserState;
    agent: VirtualAgent;
    emitterQuoteService = new EventEmitter<QuoteServiceEvent>();
    beginLoadingQuoteCreation: number;
    endLoadingQuoteCreation: number;
    businessEvt: Dictionary<any> = {};
    private readonly skipPlicoGeneration = [
        AptSignatureMode.Cartaceo,
        AptSignatureMode.Doi,
        AptSignatureMode.DoiSenzaVocalOrderLight,
        AptSignatureMode.NessunaFirma,
        null,
        undefined,
    ];
    private itemRemovalInProgress = new BehaviorSubject<boolean>(false);
    currentSubject = this.itemRemovalInProgress.asObservable();

    private configRulesLoaded = new BehaviorSubject<boolean>(false);
    configRulesSubject$ = this.configRulesLoaded.asObservable();

    constructor(
        private userSrv: UserService,
        private cartSrv: CartService,
        private cartLightSrv: EglCartLightService,
        private router: Router,
        private store: Store<EglState>,
        private logger: LoggerService,
        private appInsightSrv: AppInsightsService,
        private eglPAVLightSrv: EglPAVLightService,
        private utilitySrv: UtilityService,
        private appInsightsSrv: AppInsightsService,
        private pltSrv: PlatformService,
        private cacheSrv: CacheService,
        private orderEntryPrv: OrderEntryProvider,
        private configurationSrv: ConfigurationService,
        private eglQuoteLightSrv: EglQuoteLightService,
        private commonPrv: CommonProvider,
        private cpPrv: CpProvider,
        private featureToggle: FeatureToggleService,
        private telemetrySrv: TelemetryMetricService
    ) {
        this.setCurrentOwner();
        this.lastSaveQuote = Date.now();
    }

    /**
     * @description: Setta lo state (Redux) a partire dal carrello corrente
     * @param fromCart: nullable, carrello di partenza
     */
    setStateFromCurrentCart(fromCart?: EglCartLight | EglCartExtended): void {
        this.store
            .select(selectFullState)
            .pipe(
                take(1),
                filter(
                    (res: { orderEntry: OrderEntryState; user: UserState; selectedVirtualAgent: VirtualAgent }) =>
                        res.selectedVirtualAgent !== null
                ),
                mergeMap(
                    (state: { orderEntry: OrderEntryState; user: UserState; selectedVirtualAgent: VirtualAgent }) => {
                        if (!state) return;
                        this.orderEntryState = state.orderEntry;
                        this.userState = state.user;
                        this.selectedVirtualAgent = state.selectedVirtualAgent;
                        if (state.user.lead && state.user.lead.egl_customersegmentcode) {
                            this.segment = state.user.lead.egl_customersegmentcode;
                        } else if (state.user.contact && state.user.contact.egl_customersegmentcode) {
                            this.segment = state.user.contact.egl_customersegmentcode;
                        } else {
                            this.segment = state.user.agentInfo.UserConfiguration.LastUsedCustomerSegment;
                        }
                        return fromCart
                            ? of(fromCart)
                            : this.cartLightSrv.getMyCart().pipe(
                                  filter((c: EglCartLight) => !!c && !c.IsPricePending),
                                  take(1)
                              );
                    }
                ),
                mergeMap((cart: EglCartLight) => {
                    const upd1 = this.mapChannelAndAgencyForCart(cart);
                    const upd2 = this.mapSegment(cart);
                    const cart$ = upd1 || upd2 ? this.cartSrv.update([cart], false) : of([cart]);
                    return cart$.pipe(
                        filter((c: [EglCartLight]) => !!c),
                        take(1),
                        map((c: [EglCartLight]) => c[0])
                    );
                })
            )
            .subscribe(
                (cart: EglCartLight) => {
                    if (this._cartId !== cart.Id) {
                        this.setCartId(cart.Id);
                        this.appInsightSrv.setUserId(cart.Id);
                        this.appInsightSrv.customLogEvent(
                            'mon_startDataentry',
                            'dataentry',
                            this.currentOwner ? this.currentOwner.Username : 'Nuovo Carrello'
                        );
                    }

                    this.setCartInfo(cart);

                    const cartItems = cart.LineItems;
                    const newitemsCount = !_.isEmpty(cartItems) ? cartItems.length : 0;
                    if (newitemsCount === 0) {
                        this.logger.warn('LineItem of Cart are NULL or EMPTY');
                        this.store.dispatch(setProductsInfo({ payload: new Array<ProductInfo>() }));
                    } else {
                        this.saveProductsInfoToState(cartItems);
                    }
                },
                (err) => {
                    this.logger.error(null, 'selectUserOrderState', err, true);
                }
            );
    }

    /**
     * Aggiornamento di tutti di un blocco di PAV in input
     * @param pavs: PAV da aggiornare
     */
    async updatePavs(pavs: EglQuotePAVLight[]) {
        const loaderFinalizer = LoadingService.loaderOperator();
        const telemetryFinalizer = this.telemetrySrv.rxTelemetry('Update PAV');

        this.eglPAVLightSrv
            .update(pavs)
            .pipe(take(1), telemetryFinalizer, loaderFinalizer)
            .subscribe(
                (res: [EglQuotePAVLight]) => {
                    this.cartSrv.refreshCart();
                    if (res && res.length > 0) {
                        this.logger.warn('PAV aggiornati con successo');
                        this.onUpdatePavsCompleted.emit({ success: true, pavsId: res.map((x) => x.Id) });
                    } else {
                        this.logger.error(null, 'Errore aggiornamento PAV', '', true);
                        this.onUpdatePavsCompleted.emit({ success: false });
                    }
                },
                (err) => {
                    this.logger.error(null, 'Errore aggiornamento PAV', err, true);
                    this.onUpdatePavsCompleted.emit({ success: false });
                }
            );
    }

    /**
     * @deprecated The method should not be used. Use CartService
     *
     * @description: restituisce un carrello per cart id
     * @param cartId: se non è valorizzato prende il cartId corrente
     * @return: Observable<Cart>
     */
    getCartViaHadless(cartId?: string, queryOptions?: QueryOptions): Observable<EglCartLight> {
        const qo =
            queryOptions ||
            ({
                expressionOperator: 'AND',
                view: 'detail',
                expand: true,
                cacheStrategy: 'freshness',
                conditions: [
                    {
                        field: 'Id',
                        filterOperator: 'Equal',
                        val: cartId || CartService.getCurrentCartId(),
                        value: cartId || CartService.getCurrentCartId(),
                        apiName: 'Apttus_Config2__ProductConfiguration__c',
                    },
                    {
                        field: 'Status',
                        filterOperator: 'Equal',
                        val: 'New',
                        value: 'New',
                        apiName: 'Apttus_Config2__ProductConfiguration__c',
                    },
                ],
                filters: [],
                joins: [],
                children: [
                    {
                        field: 'Apttus_Config2__LineItems__r',
                        filters: [],
                        children: null,
                        lookups: [
                            {
                                field: 'Apttus_Config2__AttributeValueId__c',
                                lookups: null,
                                children: null,
                            },
                            {
                                field: 'Apttus_Config2__ProductId__c',
                                lookups: null,
                                children: null,
                            },
                            {
                                field: 'egl_service_address_id__c',
                                lookups: null,
                                children: null,
                            },
                            {
                                field: 'egl_shipment_address_id__c',
                                lookups: null,
                                children: null,
                            },
                            {
                                field: 'Apttus_Config2__LocationId__c',
                                lookups: null,
                                children: null,
                            },
                        ],
                    },
                ],
                lookups: [
                    {
                        field: 'egl_primary_contact__c',
                        filters: [],
                        children: null,
                    },
                ],
                aggregateFields: [],
            } as QueryOptions);
        return this.commonPrv
            .queryHadless<ApttusHadlessQueryResponse<EglCartLight>>('Apttus_Config2__ProductConfiguration__c', qo)
            .pipe(
                take(1),
                filter((res) => !!res && !!res.data && !!res.data),
                map((res: ApttusHadlessQueryResponse<EglCartLight>) => res.data.find((c) => c.Id === cartId))
            );
    }

    /**
     * Cancella e crea un nuovo carrello
     * @param navigateTo: indica se dopo l'operazione bisogna fare redirect
     */
    clearAndCreateNewCart(navigateTo: any, customerType?: AptCustomerType, finallyHideLoading?: boolean): void {
        const telemetryFinalizer = this.telemetrySrv.rxTelemetry('Empty Cart');

        this.cartSrv
            .getMyCart()
            .pipe(
                take(1),
                map((cart) => cart as EglCartExtended),
                tap((currentCart) => this.cartSrv.deleteCart(currentCart)),
                mergeMap((currentCart) =>
                    this.createNewCart(
                        new EglCartExtended(),
                        customerType || currentCart?.egl_customer_type,
                        false,
                        navigateTo
                    ).pipe(share(), retry(3))
                ),
                telemetryFinalizer,
                LoadingService.loaderOperator('Svuoto il carrello')
            )
            .subscribe(
                (newCart: EglCartExtended) => {
                    // Lo fa già la createNewCart
                    //this.onCreateNewCartCompleted.emit(newCart.Id);

                    if (finallyHideLoading === undefined || finallyHideLoading === true) {
                        this.store.dispatch(resetOrderEntry());
                        this.store.dispatch(resetUserState());
                    }
                },
                (err) => {
                    this.logger.error(null, err.message, true, true);
                }
            );
    }

    /**
     * @description: Crea un nuovo carrello
     * @param cart: istanza carrello da creare
     * @param customerType: segmento carrello
     * @param skipActivation: se il carrello da creare deve essere attivato
     * @param navigateTo: se dopo la creazione deve essere effettuata una navigazione
     * @param reloadApp: se dopo la creazione deve essere ricaricata la pagina
     */
    createNewCart(
        cart: EglCartExtended = new EglCartExtended(),
        customerType?: AptCustomerType,
        skipActivation: boolean = false,
        navigateTo: any = null,
        reloadApp: boolean = false,
        clearState: boolean = false,
        showLoading: boolean = true
    ): Observable<EglCartExtended> {
        this.logger.info('Creating new cart...');

        return combineLatest([
            this.store.select(selectCurrentVirtualAgent).pipe(take(1)),
            this.store.select(selectAgentInfo).pipe(take(1)),
            this.store.select(selectSalesProcess).pipe(take(1)),
        ]).pipe(
            filter(([virtualAgent, agentInfo]) => !!virtualAgent && !!agentInfo),
            map(
                ([{ VirtualAgency }, { Agent, UserConfiguration }, salesProcess]: [
                    VirtualAgent,
                    AgentInfo,
                    AptSalesProcess
                ]) => ({
                    virtualAgency: VirtualAgency,
                    agent: Agent,
                    salesProcess,
                    customerType: customerType || convertSegmentD365toApt(UserConfiguration?.LastUsedCustomerSegment),
                })
            ),
            map(
                ({ virtualAgency, agent, salesProcess, customerType }): EglCartExtended =>
                    Object.assign(cart, {
                        egl_agency_code: virtualAgency?.Code,
                        egl_sales_channel: virtualAgency?.Channel?.Code,
                        egl_sales_process: salesProcess,
                        egl_DAG_code: agent?.Code,
                        egl_customer_type: customerType,
                    })
            ),
            mergeMap((newCart) => this.cartSrv.createNewCart(newCart).pipe(take(1))),
            this.telemetrySrv.rxTelemetry('create-new-cart'),
            tap((newCart) => this.logger.info(`Activating cart id ${newCart?.Id}...`)),
            tap(() => {
                if (!skipActivation) {
                    this.cartSrv.refreshCart();
                }
            }),
            tap((activatedCart) => {
                this.logger.info(`Cart ${activatedCart?.Id} activated`);
                if (customerType) {
                    this.store.dispatch(setCartSegment({ payload: convertSegmentAptToD365(customerType) }));
                }
            }),
            tap(() => {
                if (clearState) {
                    this.clearState();
                }
            }),
            tap((activatedCart) => {
                this.onCreateNewCartCompleted.emit(activatedCart?.Id);
            }),
            LoadingService.loaderOperator('Creo un nuovo carrello'),
            finalize(() => {
                if (reloadApp) {
                    this.router.navigate([RoutesPaths.Dashboard]).then((res: boolean) => {
                        window.location.reload();
                    });
                } else if (navigateTo) {
                    this.router.navigate(navigateTo);
                }
            })
        ) as Observable<EglCartExtended>;
    }

    /**
     * Cancella il cart item passato come parametro
     */
    deleteCartItem(cartItem: CartItem): Observable<void> {
        return this.cartSrv.removeCartItem(cartItem);
    }

    /**
     * @description: Routine di creazione Quote per processo di SWITCH-IN
     * @return: void
     * @deprecated USARE LA SAVE QUOTE WRAPPER all'interno del cart-to-quote service
     */
    saveQuote(): void {
        if (!this.canSaveQuote()) {
            return;
        }
        this.logger.warn('Performance: inside create quote method');
        try {
            this.beginLoadingQuoteCreation = new Date().getTime();
            this.currentOwner = this.owner;

            combineLatest([
                this.store.select(selectFullState),
                this.store.select(selectCpData),
                this.store.select(selectDeferredSaleStatus),
                this.store.select(selectContactLead),
            ])
                .pipe(
                    LoadingService.loaderOperator('Recupero informazioni in corso'),
                    takeWhile(([fullState]) => fullState.orderEntry.creditCheckStatus?.scorecardCallInProgress, true)
                )
                .subscribe(([fullState, cpData, isDeferredSale, contactLead]) => {
                    this.orderEntry = fullState.orderEntry;
                    this.userState = fullState.user;

                    const { scorecardCallInProgress, canProceed: ccCanProceed } =
                        this.orderEntry.creditCheckStatus || {};
                    const isSkipCC = this.skipCC(fullState.orderEntry.flowType);
                    // Proseguo se non c'è una chiamata a scorecard in corso (flusso SW_IN), oppure se sono in regime di cambio prodotto
                    if ((!scorecardCallInProgress && ccCanProceed) || isSkipCC) {
                        this.user = fullState.user;
                        this.agent =
                            fullState.user.agentInfo.VirtualAgents.find((x) => x.IsCurrentSelected) ||
                            new VirtualAgent();
                        this.segment = fullState.user.cartSegment;

                        const q = new EglQuoteLight();
                        q.egl_code_NewDom = this.orderEntry.numeroDomiciliazione || null;
                        q.Proposal_Name = this.getQuoteName();
                        q.egl_iscustomercommodity = !!this.orderEntry.selectFornitureAttiveSelezionate;
                        q.egl_contract_code = this.orderEntry.numeroPlico
                            ? this.orderEntry.numeroPlico.code
                            : 'NOT_FOUND';
                        q.egl_delayed_insertion = isDeferredSale;
                        this.mapOrderNumber(q);
                        this.mapCreditCheckResult(q, isSkipCC);
                        this.mapChannelAndAgencyForQuote(q);
                        this.mapCfKo(q);
                        this.mapPrivacyAndBilling(q);
                        this.mapLeadCampaign(q);
                        this.mapFirmaAndMP3Info(q);
                        this.mapQuoteDeliveryChannel(q);
                        this.mapQuoteOtherProps(q);
                        this.mapQuoteStatus(q);
                        this.mapQuoteDocument(q);
                        this.mapQuoteAttachment(q);
                        this.mapContact(q);
                        this.trackToAppInsights();

                        this.convertCartToQuote(q)
                            .pipe(take(1), LoadingService.loaderOperator('Creazione offerta in corso'))
                            .subscribe(
                                (newQuote: EglQuoteLight) => {
                                    this.quoteId = newQuote.Id;
                                    this.quoteName = newQuote.Name;
                                    this.finalizeFlow(newQuote);
                                },
                                (err) => {
                                    this.emitStatusQuote(this.quoteId, this.quoteName, QuoteEventStatus.GenericError);
                                    this.logger.error(null, 'convertCartToQuote', err, true);
                                },
                                () => {
                                    localStorage.removeItem('account');
                                }
                            );
                    }
                });
        } catch (e) {
            this.logger.error(null, 'Error INATTESO CREAZIONE QUOTE ', e);
            this.emitStatusQuote(this.quoteId, this.quoteName, QuoteEventStatus.GenericError);
        }
    }
    mapContact(quote: EglQuoteLight) {
        if (this.featureToggle.isSwitchInE2EEnabled && this.userState.contact) {
            if (!!this.userState.contact.mobilephone) {
                quote.egl_contact_telephone1__c = `${this.userState.contact.prefixMobilephone}${this.userState.contact.mobilephone}`;
            }
            if (!!this.userState.contact.telephone2) {
                quote.egl_contact_telephone2__c = `${this.userState.contact.prefixTelephone2}${this.userState.contact.telephone2}`;
            }
        }
    }

    mapOrderNumber(q: EglQuoteLight) {
        this.orderEntry?.infoProdotti?.forEach((info: ProductInfo) => {
            if (info?.configuration?.orderNumber) {
                q.egl_selling_order_number__c = info.configuration.orderNumber;
            }
        });
    }

    /**
     * @description: Routine di creazione Quote per processo di Cambio Prodotto Delibera e amministrativo
     * @param cart: carrello
     * @return: void
     * @deprecated USARE LA SAVE QUOTE WRAPPER all'interno del cart-to-quote service
     */
    saveQuoteForCP(): void {
        if (!this.canSaveQuote()) {
            return;
        }
        this.logger.warn('Performance: inside create quote method');
        try {
            this.beginLoadingQuoteCreation = new Date().getTime();
            this.currentOwner = this.owner;
            this.store
                .select(selectUserOrderState)
                .pipe(
                    take(1),
                    tap((s) => {
                        this.user = s.user;
                        this.agent =
                            s.user?.agentInfo?.VirtualAgents?.find((x) => x.IsCurrentSelected) || new VirtualAgent();
                        this.segment = s.user?.cartSegment;
                    }),
                    map((s) => {
                        const cart = new EglCartLight();
                        cart.Id = s.orderEntry?.cartInfo?.cartId;
                        cart.egl_contract_code = s.orderEntry?.numeroPlico?.code;

                        const q = new EglQuoteLight();
                        q.Proposal_Name = this.getQuoteName();
                        q.egl_contract_code = cart.egl_contract_code;
                        this.mapQuoteStatus(q);
                        this.mapQuoteAttachment(q);
                        this.trackToAppInsights();

                        return { cart, quote: q };
                    }),
                    mergeMap(({ cart, quote }) =>
                        this.convertCartToQuoteForCP(cart, quote).pipe(
                            take(1),
                            LoadingService.loaderOperator('Creazione offerta in corso')
                        )
                    ),
                    LoadingService.loaderOperator('Recupero informazioni in corso')
                )
                .subscribe(
                    (newQuote: EglQuoteLight) => {
                        this.quoteId = newQuote.Id;
                        this.quoteName = newQuote.Name;
                        this.finalizeFlow(newQuote);
                    },
                    (err) => {
                        this.emitStatusQuote(this.quoteId, this.quoteName, QuoteEventStatus.GenericError);
                        this.logger.error(null, 'convertCartToQuote', err, true);
                    },
                    () => {
                        localStorage.removeItem('account');
                    }
                );
        } catch (e) {
            this.logger.error(null, 'Error INATTESO CREAZIONE QUOTE ', e);
            this.emitStatusQuote(this.quoteId, this.quoteName, QuoteEventStatus.GenericError);
        }
    }

    /**
     * @description: Routine di creazione Quote per processo di Cessazione
     * @param cart: carrello
     * @return: void
     * @deprecated USARE LA SAVE QUOTE WRAPPER all'interno del cart-to-quote service
     */
    saveQuoteForTERM(): void {
        if (!this.canSaveQuote()) {
            return;
        }
        this.logger.warn('Performance: inside create quote method');
        try {
            LoadingService.show('Recupero informazioni in corso');
            const cart = new EglCartLight();
            cart.Id = CartService.getCurrentCartId();
            this.beginLoadingQuoteCreation = new Date().getTime();
            this.currentOwner = this.owner;
            this.store
                .select(selectFullState)
                .pipe(take(1))
                .subscribe((fullState) => {
                    this.user = fullState.user;
                    this.agent =
                        fullState.user.agentInfo.VirtualAgents.find((x) => x.IsCurrentSelected) || new VirtualAgent();
                    this.segment = fullState.user.cartSegment;
                    this.orderEntry = fullState.orderEntry;
                });
            const q = new EglQuoteLight();
            q.Proposal_Name = this.getQuoteName();
            q.egl_contract_code = cart.egl_contract_code ? cart.egl_contract_code : this.orderEntry?.numeroPlico?.code;
            q.egl_quote_status = AptQuoteStatus.Confermato;

            /** START -  NON VA FATTO QUI! E' LOGICA DI BACKEND, AL MASSIMO PUOI SETTARE STATUS E SUBSTATUS!!!!! */
            if (this.orderEntry.termination.isMortisCausa) {
                q.egl_quote_status = AptQuoteStatus.Firmato;
                q.egl_mp3_vocal_order = 'CARICATO';
                q.egl_storage_plico = 'INVIATO';
            }
            q.egl_confirm_mode = AptConfirmMode.NessunaConferma;
            /** END - NON VA FATTO QUI! E' LOGICA DI BACKEND, AL MASSIMO PUOI SETTARE STATUS E SUBSTATUS!!!!! */

            this.mapChannelAndAgencyForQuote(q);
            this.mapQuoteAttachment(q);

            LoadingService.update('Creazione offerta in corso...');
            this.convertCartToQuoteForTERM(cart, q)
                .pipe(take(1))
                .subscribe(
                    (newQuote: EglQuoteLight) => {
                        LoadingService.update('Finalizzazione offerta...');
                        this.quoteId = newQuote.Id;
                        this.quoteName = newQuote.Name;
                        this.finalizeFlow(newQuote);
                    },
                    (err) => {
                        this.emitStatusQuote(this.quoteId, this.quoteName, QuoteEventStatus.GenericError);
                        this.logger.error(null, 'convertCartToQuote', err, true);
                    },
                    () => {
                        localStorage.removeItem('account');
                        LoadingService.hide();
                    }
                );
        } catch (e) {
            this.logger.error(null, 'Error INATTESO CREAZIONE QUOTE ', e);
            this.emitStatusQuote(this.quoteId, this.quoteName, QuoteEventStatus.GenericError);
        }
    }

    /**
     * @author Ricupero ft. Felice & Bazan
     */
    private getEffectiveDateAndImmediate({
        salesProcess,
        entity,
        customerEffectiveInfo,
    }: {
        salesProcess: AptSalesProcess;
        entity: CreateQuoteEntitiesResponse;
        customerEffectiveInfo?: {
            date: Date;
            immediateEffect?: boolean;
        };
    }): ActivationDateAndImmediateEffect {
        const DATE_STRATEGIES: {
            [key in AptSalesProcess | 'DEFAULT']?: () => {
                effectiveDate: moment.Moment | Date | string;
                immediateEffect: boolean;
            };
        } = {
            // per VOLTURA CON SWITCH la data è pari alla data attuale
            [AptSalesProcess.VariazioneCommerciale]: () => ({
                effectiveDate: new Date(),
                immediateEffect: !!entity?.ImmediateEffect,
            }),
            // per VOLTURA CON SWITCH la data è pari alla data attuale + 2gg lavorativi
            [AptSalesProcess.VolturaConSwitchIn]: () => ({
                effectiveDate: this.utilitySrv.addMomentBusinessDays(new Date(), 2),
                immediateEffect: !!entity?.ImmediateEffect,
            }),
            [AptSalesProcess.SwitchIn]: () => null, // In caso di CAMBIO PRODOTTO esecuzione anticipata viene calcolata restituita da Apttus, per ribaltarla sulla quote. Nello SWITCH-IN da D365
            DEFAULT: () =>
                entity?.EffectiveDate
                    ? {
                          effectiveDate: moment(entity?.EffectiveDate),
                          immediateEffect: !!entity?.ImmediateEffect,
                      }
                    : null,
        };

        // Attivazioni
        ACTIVATION_SALES_PROCESSES.forEach((salesProcess) => {
            DATE_STRATEGIES[salesProcess] = () => ({
                effectiveDate: customerEffectiveInfo.date,
                immediateEffect: !!customerEffectiveInfo.immediateEffect,
            });
        });

        const effectiveDateAndImmediate = (DATE_STRATEGIES[salesProcess] || DATE_STRATEGIES['DEFAULT'])();

        if (!effectiveDateAndImmediate?.effectiveDate) {
            return null;
        }

        const effectiveDate = moment(effectiveDateAndImmediate?.effectiveDate);

        return effectiveDate.isValid()
            ? {
                  ...effectiveDateAndImmediate,
                  effectiveDate,
              }
            : null;
    }

    /**
     * @description: Custom Convert Cart to Quote V2
     * @param quote: istanza della Quote da creare
     * @return: Observable<EglQuoteLight>
     */
    private convertCartToQuote(quote: EglQuoteLight): Observable<EglQuoteLight> {
        this.logger.warn('Call to GetMyCart(Light Version)');

        const retrievedCart: EglCartLight = new EglCartLight();
        //return (this.eglCartFinalizeService.get([CartService.getCurrentCartId()])).pipe(
        return this.cartSrv.getMyCart().pipe(
            take(1),
            this.telemetrySrv.rxTelemetry('GetMyCart(Extended version)'),
            mergeMap((cart) => {
                Object.assign(retrievedCart, cart);
                const telemetryFinalizer2 = this.telemetrySrv.rxTelemetry(
                    'Get Current User and CreateQuoteEntitiesResponse'
                );
                return combineLatest([
                    this.currentOwner ? of(this.currentOwner) : this.userSrv.me(),
                    this.orderEntryPrv.createQuoteEntities(_.get(cart, 'Id')),
                    this.cartLightSrv.getMyCart(),
                    this.store.select(selectActivation),
                ]).pipe(
                    take(1),
                    LoadingService.loaderOperator('Creazione Account, Contatto e Spedizione'),
                    telemetryFinalizer2
                );
            }),
            mergeMap(([user, entities, cart, activation]) => {
                this.store.dispatch(setCommodityCartId({ commodityCartId: entities.CartId }));
                this.store.dispatch(setCombinedSale({ combinedSale: entities.CombinedSaleInsurance }));

                quote.egl_flowunlock = false;
                quote.egl_sales_process = entities.SalesProcess || cart.egl_sales_process;
                quote.egl_sales_process_legacy = entities.SalesProcessLegacy;
                quote.OwnerId = user.Id;
                quote.egl_migration_mastercode__c = entities.MigrationMastercode;

                if (entities.Status === 'Failure') {
                    this.logger.error(entities.ErrorMessage);
                    return of(null);
                }

                const effectiveDateandImmediate = this.getEffectiveDateAndImmediate({
                    salesProcess: quote.egl_sales_process as AptSalesProcess,
                    entity: entities,
                    customerEffectiveInfo: {
                        date: activation?.actDate,
                        immediateEffect: activation?.immediateEffect,
                    },
                });

                if (effectiveDateandImmediate) {
                    quote.egl_Effective_date__c = effectiveDateandImmediate.effectiveDate.format('YYYY-MM-DD');
                    quote.egl_immediate_effect__c = effectiveDateandImmediate.immediateEffect;
                }
                this.setAccount(entities?.AccountId);
                quote.BillToAccountId = entities.AccountId;
                quote.ShipToAccountId = entities.AccountId;
                quote.Primary_ContactId = entities.ContactId;
                quote.LocationId = entities.AccountLocationId;
                quote.AutoActivateOrder = false;
                quote.PriceListId = retrievedCart.PriceListId;
                quote.egl_leadid = retrievedCart.egl_leadid; // LEAD ID
                if (this.configurationSrv.get('enableMultiCurrency')) {
                    quote.CurrencyIsoCode = user.DefaultCurrencyIsoCode;
                }
                quote.Approval_Stage = 'Draft';
                quote.strip();
                const telemetryFinalizer3 = this.telemetrySrv.rxTelemetry(
                    (quote: EglQuoteLight) => `Quote (${quote?.Id}) response received`
                );
                return this.pltSrv.apiService
                    .post(`/carts/${CartService.getCurrentCartId()}/proposal`, quote, EglQuoteLight)
                    .pipe(LoadingService.loaderOperator('Creazione Quote'), telemetryFinalizer3);
            }),
            mergeMap((quote: EglQuoteLight) => {
                this.cacheSrv.refresh(Cart);
                CartService.deleteLocalCart();
                return combineLatest([
                    of(quote),
                    this.createNewCart(new EglCartExtended(), null, false, null, false, false, false),
                ]).pipe(map(([quote]) => quote));
            }),
            LoadingService.loaderOperator('Aggiornamento carrello...')
        );
    }

    /**
     * @description: Routine creazione quote per CP
     * @param quote: istanza della Quote da creare
     * @return: Observable<EglQuoteLight>
     */
    private convertCartToQuoteForCP(cart: EglCartLight, quote: EglQuoteLight): Observable<EglQuoteLight> {
        this.logger.warn('Call to GetMyCart(Light Version)');
        const telemetryFinalizer = this.telemetrySrv.rxTelemetry('Get Current User and CreateQuoteEntitiesResponse');
        return combineLatest([
            this.currentOwner ? of(this.currentOwner) : this.userSrv.me(),
            this.orderEntryPrv.createQuoteEntities(cart.Id),
        ]).pipe(
            take(1),
            telemetryFinalizer,
            tap(([, entities]) => this.setAccount(entities?.AccountId)),
            map(([user, entities]) => {
                /// END LOG METRIC
                quote.egl_Effective_date__c = moment(entities.EffectiveDate).format('YYYY-MM-DD');
                quote.egl_immediate_effect__c = entities.ImmediateEffect;
                quote.egl_flowunlock = false;
                quote.egl_sales_process = entities.SalesProcess;
                quote.egl_sales_process_legacy = entities.SalesProcessLegacy;
                quote.egl_signed_date = entities.SignedDate;
                quote.OwnerId = user.Id;
                quote.BillToAccountId = entities.AccountId;
                quote.ShipToAccountId = entities.AccountId;
                quote.Primary_ContactId = entities.ContactId;
                quote.LocationId = entities.AccountLocationId;
                quote.AutoActivateOrder = false;
                quote.PriceListId = cart.PriceListId;
                quote.egl_leadid = cart.egl_leadid; // LEAD ID
                if (this.configurationSrv.get('enableMultiCurrency')) {
                    quote.CurrencyIsoCode = user.DefaultCurrencyIsoCode;
                }
                quote.Approval_Stage = 'Draft';
                quote.strip();
                return quote;
            }),
            tap(() => LoadingService.update('Creazione Quote')),
            mergeMap((quote) => {
                const telemetryFinalizer2 = this.telemetrySrv.rxTelemetry(
                    ({ Id }: EglQuoteLight) => `Quote (${Id}) response received`
                );
                return this.cpPrv.createProposal(cart.Id, quote).pipe(
                    map(({ data }) => data),
                    telemetryFinalizer2
                );
            }),
            LoadingService.loaderOperator('Aggiornamento carrello')
        );
    }

    /**
     * @description: Routine creazione quote per TERM
     * @param quote: istanza della Quote da creare
     * @return: Observable<EglQuoteLight>
     */
    private convertCartToQuoteForTERM(cart: EglCartLight, quote: EglQuoteLight): Observable<EglQuoteLight> {
        const start1 = new Date().getTime();
        let start2 = null;
        let start4 = null;
        this.logger.warn('Call to GetMyCart(Light Version)');
        return combineLatest([
            this.currentOwner ? of(this.currentOwner) : this.userSrv.me(),
            this.orderEntryPrv.createQuoteEntities(cart.Id),
        ]).pipe(
            take(1),
            mergeMap(([user, entities]) => {
                const end2 = new Date().getTime();
                this.appInsightsSrv.logMetric(
                    `Performance: Get Current User and CreateQuoteEntitiesResponse`,
                    (end2 - start2) / 1000
                );
                /// END LOG METRIC
                quote.egl_old_MobilePhone__c = this.orderEntry.termination.termContacts[0].changedContact
                    ? this.orderEntry.termination.termContacts[0].oldContact
                    : null;
                quote.egl_old_Email__c = this.orderEntry.termination.termContacts[1].changedContact
                    ? this.orderEntry.termination.termContacts[1].oldContact
                    : null;
                quote.egl_telephone__c = `${this.userState.contact.prefixMobilephone}${this.userState.contact.mobilephone}`;
                quote.egl_email__c = this.userState.contact.emailaddress1;
                quote.egl_mortis_causa__c = this.orderEntry.termination.isMortisCausa;
                quote.egl_favorite_communication_channel__c = this.userState.contact.preferredContact;
                quote.egl_Effective_date__c = moment(entities.EffectiveDate).format('YYYY-MM-DD');
                quote.egl_immediate_effect__c = !!entities.ImmediateEffect;
                quote.egl_flowunlock = false;
                quote.egl_sales_process = entities.SalesProcess;
                quote.egl_signed_date = entities.SignedDate;
                quote.OwnerId = user.Id;
                this.setAccount(entities?.AccountId);
                quote.BillToAccountId = entities.AccountId;
                quote.ShipToAccountId = entities.AccountId;
                quote.Primary_ContactId = entities.ContactId;
                quote.LocationId = entities.AccountLocationId;
                quote.AutoActivateOrder = false;
                quote.PriceListId = cart.PriceListId;
                quote.egl_leadid = cart.egl_leadid; // LEAD ID
                if (this.configurationSrv.get('enableMultiCurrency')) {
                    quote.CurrencyIsoCode = user.DefaultCurrencyIsoCode;
                }
                quote.Approval_Stage = 'Draft';
                quote.strip();
                LoadingService.update('Creazione Quote');
                this.logger.warn('Performance: Going to create or update the quote', false);
                return this.cpPrv.createProposal(cart.Id, quote);
            }),
            mergeMap((res: { title: string; status: number; data: EglQuoteLight }) => {
                const end4 = new Date().getTime();
                this.appInsightsSrv.logMetric(
                    `Performance: Quote (${res.data.Id}) response received`,
                    (end4 - start4) / 1000
                );
                return of(res.data);
            }),
            LoadingService.loaderOperator('Aggiornamento carrello')
        );
    }

    /**
     * @description: genera il nome della Quote
     * @return: string o undefined
     */
    private getQuoteName(): string {
        if (this.segment === D365CustomerSegment.Microbusiness && this.orderEntry.anagraficaMb) {
            return `${this.orderEntry.anagraficaMb.companyName} - ${moment().format('DD/MM/YYYY HH:MM:ss')}`;
        } else if (this.user.contact || this.user.lead) {
            return `${(this.user.contact || this.user.lead).firstname} ${
                (this.user.contact || this.user.lead).lastname
            } - ${moment().format('DD/MM/YYYY HH:mm:ss')}`;
        }
    }

    private mapCreditCheckResult(q: EglQuoteLight, skip: boolean): void {
        if (skip) {
            this.logger.warn('Skip mapping Credit Check per Cambio prodotto');
        } else {
            this.logger.warn('Try to map Credit Check');
            if (q && this.orderEntry?.creditCheckStatus) {
                const {
                    blacklist,
                    blacklist2,
                    newBlacklist,
                    whitelist,
                    unsolvedNds,
                    cribis,
                    unsolvedIbanNds,
                    unsolvedTaxCodeNdsResult,
                } = this.orderEntry.creditCheckStatus.ccDetails;
                const { scipafi, sdi } = this.orderEntry.creditCheckStatus.taxVatDetails;
                // Cribis, Cerved e Scorecard al fine delle nostre operazioni sono sinonimi
                q.egl_cribis_status = cribis || '';
                q.egl_backlist_status = blacklist || '';
                q.egl_blacklist_2_status = blacklist2 || '';
                q.egl_blacklist_3_status = newBlacklist || '';
                q.egl_whitelist_status = whitelist || '';
                q.egl_scipafi = scipafi || '';
                q.egl_sdi_status = sdi || '';
                q.egl_unsolved = unsolvedNds;
                q.egl_unsolved_iban = unsolvedIbanNds;
                q.egl_unsolved_fc_vat = unsolvedTaxCodeNdsResult;
            }
            this.logger.info('Mapping Credit Check completed');
        }
    }

    private mapCfKo(q: EglQuoteLight): void {
        if (this.orderEntry && this.orderEntry.invalidCf) {
            q.egl_previous_fiscalcode = this.orderEntry.invalidCf.siebelOldCf;
            q.egl_customer_code = this.orderEntry.invalidCf.siebelCustomerCode;
        }
    }

    private mapChannelAndAgencyForQuote(q: EglQuoteLight): void {
        this.logger.info('Try to map ChannelAndAgencyForQuote');

        if (q && this.agent && this.agent.VirtualAgency) {
            q.egl_user_profile = getAgentD365toApt(this.user.agentInfo.Type);
            // TODO: fare un update quando viene editato da BO con i dati correnti
            // che potrebbero essere stai aggionati (AgencyBranch da recuperare in caso di update)
            q.egl_sales_agent = this.agent.AgentCode;
            q.egl_agency_code = this.agent.VirtualAgency.Code;
            q.egl_user_code = this.user.agentInfo.Agent.Code;
            q.egl_sales_channel = this.agent.VirtualAgency.Channel.Code;
            q.egl_employee_lastname = this.user.agentInfo.Agent.Name;
            q.egl_number_sign_RUI_agency = this.agent.VirtualAgency.RuiEnrollmentNumber;
            if (this.agent.VirtualAgency.RuiEnrollmentDate) {
                q.egl_date_sign_RUI_agency = this.utilitySrv.convertDateD365ToApt(
                    this.agent.VirtualAgency.RuiEnrollmentDate
                );
            }

            q.egl_company_name = this.user.agentInfo.Agency.Name;
            q.egl_address_Agency = this.agent.VirtualAgency.RegisteredOfficeAddress;
            q.egl_system_origin =
                this.agent.VirtualAgency.Channel.Code === D365ChannelCode.TelesellingInbound
                    ? 'CALL CENTER'
                    : 'AGENZIA';

            q.egl_salesapp_code = q.egl_contract_code;

            //   q.egl_phone_agency = this.agent.VirtualAgency.RegisteredOfficeTelephone;
            q.egl_sales_agent_blacklist = this.user.agentInfo.Blacklisted;

            // Fix per #64605, salvataggio corretto di egl_instore_sale__c in base a quanto settato dal profilo utente
            if (this.selectedVirtualAgent?.VirtualAgency?.Channel?.CanSetIsInStore) {
                q.egl_instore_sale = this.selectedVirtualAgent.IsInStore ? 'Si' : 'No';
            }

            if (this.orderEntry.branchAgenziaAgente) {
                q.egl_intermediary_firstname = this.orderEntry.branchAgenziaAgente.ResponsibleFirstName;
                q.egl_seat_name = this.orderEntry.branchAgenziaAgente.Name;
                q.egl_mail_seat = this.orderEntry.branchAgenziaAgente.Email;
                q.egl_Pec_seat = this.orderEntry.branchAgenziaAgente.Pec;
                q.egl_intermediary_lastname = this.orderEntry.branchAgenziaAgente.ResponsibleLastName;
                q.egl_address_seat = this.orderEntry.branchAgenziaAgente.AgencyBranchAddress;
                q.egl_pec_agency = this.orderEntry.branchAgenziaAgente.Pec;
                q.egl_phone_seat = this.orderEntry.branchAgenziaAgente.Telephone;
                if (this.orderEntry.branchAgenziaAgente.ResponsibleRuiEnrollmentDate) {
                    q.egl_date_sign_RUI_intermediary = this.utilitySrv.convertDateD365ToApt(
                        this.orderEntry.branchAgenziaAgente.ResponsibleRuiEnrollmentDate
                    );
                }
                if (this.orderEntry.branchAgenziaAgente.ResponsibleRuiEnrollmentNumber) {
                    q.egl_number_sign_intermediary = this.orderEntry.branchAgenziaAgente.ResponsibleRuiEnrollmentNumber;
                }
            }

            q.egl_Operational_seats =
                this.orderEntry.agencyBranchForMonitoring || this.orderEntry.branchAgenziaAgente?.Name;
            q.egl_Co_Code = this.agent.CurrentCode;

            if (this.orderEntry.impersonatedAgent) {
                // FIRMA CARTECEA
                const impersonated = this.orderEntry.impersonatedAgent;
                q.egl_user_code = impersonated.RealAgentCode || q.egl_user_code;
                q.egl_Co_Code = impersonated.CurrentCode || q.egl_Co_Code;
                q.egl_sales_agent = impersonated.AgentCode || q.egl_sales_agent;
                q.egl_employee_lastname = impersonated.AgentName || q.egl_employee_lastname;

                if (impersonated.IsInStore) {
                    // presente solo per firma cartacea
                    q.egl_instore_sale = impersonated.IsInStore || q.egl_instore_sale;
                }
            }
        } else {
            this.logger.warn('Mapping Channel And Agency SKIPPED');
        }
        this.logger.warn('Mapping Channel And Agency completed');
    }

    private mapPrivacyAndBilling(q: EglQuoteLight): void {
        this.logger.warn('Try to Map PRIVACY');
        if (q && this.orderEntry.privacyTrattDatiPers) {
            q.egl_privacy_marketanalysis = this.orderEntry.privacyTrattDatiPers[PrivacyType.AnalisiMercato];
            q.egl_privacy_personal_data = this.orderEntry.privacyTrattDatiPers[PrivacyType.TrattDatiPersonali];
            q.egl_privacy_self_initiative = this.orderEntry.privacyTrattDatiPers[PrivacyType.InizPromozionaliEgl];
            q.egl_privacy_thirdparties_initiative = this.orderEntry.privacyTrattDatiPers[PrivacyType.InizPromoTerzeP];
            this.businessEvt.privacy1 = this.orderEntry.privacyTrattDatiPers[PrivacyType.AnalisiMercato];
            this.businessEvt.privacy2 = this.orderEntry.privacyTrattDatiPers[PrivacyType.TrattDatiPersonali];
            this.businessEvt.privacy3 = this.orderEntry.privacyTrattDatiPers[PrivacyType.InizPromozionaliEgl];
            this.businessEvt.privacy4 = this.orderEntry.privacyTrattDatiPers[PrivacyType.InizPromoTerzeP];
            this.logger.warn('End mappping PRIVACY');
        }
    }

    private mapLeadCampaign(q: EglQuoteLight): void {
        if (q && this.user.lead && this.user.lead.campaignId) {
            q.egl_campaign_id = this.user.lead.campaignId;
            q.egl_campaign_name = this.user.lead.campaignName;
            q.egl_campaign_code = this.user.lead.campaignCode;
            q.egl_campaign_type_code = this.user.lead.campaignTypeCode;
        }
    }

    private mapFirmaAndMP3Info(q: EglQuoteLight): void {
        if (q && this.orderEntry.firma) {
            this.logger.warn('Try to Map SIGNATURE and MP3');
            q.egl_signature_mode = D365_APT_SIGNATURE_MODE_MAP[this.orderEntry.firma.d365SignatureType];
            q.egl_signed_date = this.orderEntry.firma.signedDate
                ? moment(this.orderEntry.firma.signedDate).format('YYYY-MM-DD')
                : null;
            q.egl_checkcall_blocking = this.orderEntry.firma.confirmationType === D365ConfirmationType.TacitApprovalSMS;
            q.egl_confirm_mode = D365_APT_CONFIRM_MODE_MAP[this.orderEntry.firma.confirmationType];

            const checkMp3 = [D365SignatureType.VocalOrder, D365SignatureType.Doi];
            if (checkMp3.includes(this.orderEntry.firma.d365SignatureType)) {
                if (this.orderEntry.mp3Info && this.orderEntry.mp3Info.uploaded) {
                    q.egl_mp3_sent = this.orderEntry.mp3Info.uploaded;
                    q.egl_mp3_date = this.orderEntry.mp3Info.uploadDate;
                }
            }
            this.logger.warn('End mappping SIGNATURE and MP3');
        }
    }

    private mapQuoteStatus(q: EglQuoteLight): void {
        this.logger.warn('Try to Map STATE MODEL');
        if (q) {
            q.egl_quote_status = this.orderEntry.quoteStateModel.status;
            q.egl_quote_substatus = this.orderEntry.quoteStateModel.subStatus;
            this.logger.warn('End mapping STATE MODEL');
        }
    }

    private mapQuoteDeliveryChannel(q: EglQuoteLight): void {
        q.egl_delivery_channel = 'Stampa';
        const prod = (this.orderEntry.infoProdotti || []).find((x) => x.status !== AptLineStatus.Cancelled);
        if (prod?.configuration?.billType === AptBillType.Digitale) {
            q.egl_delivery_channel = 'Digitale';
        }
    }

    private mapQuoteDocument(q: EglQuoteLight): void {
        if (q) {
            q.egl_document_type = this.orderEntry.fotoDocumenti?.tipoDocumento;
            q.egl_document_realease = this.orderEntry.fotoDocumenti?.rilasciatoIl;
            q.egl_document_code = this.orderEntry.fotoDocumenti?.numeroDocumento;
            q.egl_doc_issuing_body = this.orderEntry.fotoDocumenti?.emessoDa;
            q.egl_real_estate_ownership = this.orderEntry.realEstateOwnership;
        }
    }

    /**
     *  @description: Mappa il campo egl_attachmentToArchive sulla quote per il processo di archiviazione a nuovo (US 58677)
     *  @return: void
     */
    private mapQuoteAttachment(q: EglQuoteLight): void {
        if (q) {
            q.egl_attachmentToArchive = !!this.orderEntry.fotoDocumenti;
        }
    }

    private mapQuoteOtherProps(q: EglQuoteLight): void {
        if (this.segment === D365CustomerSegment.Microbusiness && this.orderEntry.anagraficaMb) {
            q.egl_address_registered_office = this.orderEntry.anagraficaMb.companyAddress.nomeCompleto;
        }
    }

    /**
     *  @description: Method to generate Plico and unlock Apttus flow
     *  @param quote: istanza della quote da finalizzare
     *  @return: voi
     */
    finalizeFlow(quote: EglQuoteLight): void {
        this.finalizeFlow_obs(quote)
            .pipe(mergeMap(() => this.store.select(selectOrderEntryState).pipe(map(({ plicoUrl }) => plicoUrl))))
            .subscribe(
                (plicoUrl) => {
                    if (this.skipPlicoGeneration.includes(quote.egl_signature_mode)) {
                        this.emitStatusQuote(quote.Id, quote.Name, QuoteEventStatus.UnlockedFlow);
                    } else {
                        this.emitStatusQuote(quote.Id, quote.Name, QuoteEventStatus.PlicoGenerated, plicoUrl);
                    }
                },
                () => {
                    this.emitStatusQuote(quote.Id, quote.Name, QuoteEventStatus.GenericError);
                }
            );
    }

    /**
     * @description: Emette l'esito dello stato della creazione della quote
     * @param qId: ID Quote
     * @param statusQ: stato
     * @param plicoUrl: nullable, rapprensenta url del plico
     */
    emitStatusQuote(qId: string, qName: string, statusQ: QuoteEventStatus, plicoUrl?: string): void {
        this.emitterQuoteService.emit({
            quoteId: qId,
            quoteName: qName,
            status: statusQ,
            urlPlicoOT: plicoUrl,
        });
    }

    private trackToAppInsights(): void {
        const user = this.user.contact || this.user.lead;
        this.businessEvt.codFisc = user ? user.egl_taxcode : '';
        this.businessEvt.channel = this.agent.VirtualAgency.Channel.Code;
        this.businessEvt.email = user ? user.emailaddress1 : '';
        this.businessEvt.tel = user ? user.telephone1 : '';
        if (this.orderEntry?.infoProdotti) {
            const prod = (this.orderEntry.infoProdotti || []).find((x) => x.status !== AptLineStatus.Cancelled);
            this.businessEvt.digitalComm = prod.configuration.billType;
            this.businessEvt.codProd = JSON.stringify(
                (this.orderEntry.infoProdotti || []).map((p: ProductInfo) => p.code || p.sku)
            );
        }
        this.appInsightsSrv.customLogEvent('mon_confirmBasket', 'confirmBasket', this.businessEvt);
    }

    /* getter */

    /**
     * @return: current cart id
     */
    get cartId(): string {
        return this._cartId;
    }

    /**
     * @return: current User
     */
    get owner(): User {
        return this.currentOwner;
    }

    /* setter */

    /**
     * @description: Salva nello state le informazioni minimali dei prodotti presenti nel carrello
     * @param allLineItems: line items Primary
     */
    private saveProductsInfoToState(allLineItems: EglCartItemLight[]): void {
        const groups = LineItemService.groupItems(allLineItems as unknown as CartItem[]);
        const productsInfo = new Array<ProductInfo>();
        groups.forEach((item: ItemGroup, i: number) => {
            const mailnLine = item.MainLine as unknown as EglCartItemLight;
            const currentP = mailnLine.Product;
            const addProduct = productsInfo.find((p: ProductInfo) => p.id === currentP.Id) === undefined;

            if (addProduct) {
                if (currentP) {
                    const pav = (mailnLine.AttributeValue as EglCartPAVLight) || new EglCartPAVLight();
                    const infoProdotto = new ProductInfo(
                        currentP.Id,
                        mailnLine.Id,
                        currentP.ProductType as AptProductType,
                        currentP.Family as AptProductFamily,
                        currentP.Name,
                        currentP.ProductCode,
                        currentP.egl_address_type,
                        pav,
                        currentP.egl_privacy_required
                    );
                    infoProdotto.uniqueProductCode = currentP.egl_unique_product_code;
                    infoProdotto.status = mailnLine.LineStatus;

                    infoProdotto.discountsPromo = item.Options.filter(filterActivePackageProducts).map(
                        (i: CartItem) => i.Option.Name
                    );

                    // #59510 - La vendita posticipata è fattibile solo su CP Commerciale e SwitchIn.
                    // Evito di mettere nello state l'informazione che indica se il prodotto è scaduto se si tratta di quello "Cancellato"
                    const deferredSaleFlowType = [FlowType.SwitchIn, FlowType.CPCommerciale];
                    if (
                        deferredSaleFlowType.indexOf(this.orderEntryState.flowType) !== -1 &&
                        infoProdotto.status !== AptLineStatus.Cancelled
                    ) {
                        infoProdotto.isExpired = currentP.egl_is_expired;
                    }
                    infoProdotto.isAtNew = !!currentP.Status;
                    if (mailnLine.egl_supply_code) {
                        infoProdotto.supplyCode = mailnLine.egl_supply_code;
                    }
                    this.addOptionsAndConfigurations(pav, infoProdotto);

                    productsInfo.push(infoProdotto);
                }
            }
        });
        this.logger.info('saveProductsInfoToState - refresh products');
        if (isAdministrativeSwitchInWithCeasedAsset(this.orderEntryState.flowType)) {
            this.store.dispatch(setProductsInfo({ payload: productsInfo, responsabilita: true }));
        } else {
            this.store.dispatch(setProductsInfo({ payload: productsInfo }));
        }
        this.onSetStateFromCartCompleted.emit({ success: true });
    }

    /**
     * @description: Map channel and agency to Cart
     * @param c: Cart istance
     * @return: boolean if mapping require cart update
     */
    private mapChannelAndAgencyForCart(c: EglCartLight): boolean {
        this.logger.info('Try to map Channel And Agency');
        let toUpdate = false;
        if (
            c &&
            this.selectedVirtualAgent?.VirtualAgency &&
            (c.egl_agency_code !== this.selectedVirtualAgent.VirtualAgency.Code ||
                c.egl_sales_channel !== this.selectedVirtualAgent.VirtualAgency.Channel.Code)
        ) {
            c.egl_agency_code = this.selectedVirtualAgent.VirtualAgency.Code;
            c.egl_sales_channel = this.selectedVirtualAgent.VirtualAgency.Channel.Code;
            toUpdate = true;
            this.logger.info('Mapping Channel And Agency completed');
        } else {
            this.logger.warn('Mapping Channel And Agency SKIPPED');
        }
        return toUpdate;
    }

    /**
     * @description: Map segment to Cart
     * @param c: Cart istance
     * @return: boolean if mapping require cart update
     */
    private mapSegment(c: EglCartLight): boolean {
        let toUpdate = false;
        const segmentToBe = convertSegmentD365toApt(this.segment);
        if (c && !c.egl_customer_type && c.egl_customer_type !== segmentToBe) {
            c.egl_customer_type = segmentToBe;
            toUpdate = true;
        }
        return toUpdate;
    }

    /**
     * @description: set current owner as current user
     * @return: void
     */
    private setCurrentOwner(): void {
        this.userSrv
            .me()
            .pipe(take(1))
            .subscribe((u: User) => {
                this.currentOwner = u;
            });
    }

    setCartId(cartId: string): void {
        this._cartId = cartId;
    }

    isItemRemovalInProgress(input: boolean): void {
        this.itemRemovalInProgress.next(input);
    }

    areConfigRulesLoaded(input: boolean): void {
        this.configRulesLoaded.next(input);
    }

    addOptionsAndConfigurations(pav: EglCartPAVLight, infoProdotto: ProductInfo) {
        if (pav['egl_green_option__c'] !== undefined) {
            infoProdotto.optionsConfig.push(`Opzione verde: ${pav['egl_green_option__c'] === 'Y' ? 'Si' : 'No'}`);
        }
        if (pav.egl_Invoice_Shipping_Method__c === AptBillType.Digitale) {
            infoProdotto.optionsConfig.push('Bolletta digitale');
        } else {
            infoProdotto.optionsConfig.push('Bolletta cartacea');
        }
    }

    /**
     * Retrieve the list of cities related to zip code
     * @param cap: zip inserted by user
     */
    getCitiesFromCap(cap: Number): Observable<ZipCodeItem[]> {
        let respArray: ZipCodeItem[] = [];
        const query = `select id, Name, egl_parentterritory__r.egl_parentterritory__r.Name from egl_territory__c where Name like '%25${cap}%25' order by CreatedDate desc`;
        const obs = this.commonPrv.query<ApttusQueryResponse<ApttusAddress>>(query);
        return obs.pipe(
            map((res: ApttusQueryResponse<ApttusAddress>) => {
                res?.records.forEach((element) => {
                    let zip: string, city: string, prov: string;
                    [city, zip] = element.Name.split(' - ');
                    prov = element.egl_parentterritory__r.egl_parentterritory__r.Name;
                    respArray.push({
                        zip: zip,
                        city: city,
                        prov: prov,
                        fullName: this.formatFullNameFromZIP(zip, city, prov),
                    });
                });
                return respArray;
            })
        );
    }

    /**
     * Take data in input and format the string to show
     * @param zip: zip code
     * @param city: city
     * @param prov: province
     */
    formatFullNameFromZIP(zip: string, city: string, prov: string) {
        let result = `${zip}, ${city.toUpperCase()}`;
        if (prov) {
            result = result + ` (${prov.toUpperCase()})`;
        }
        return result;
    }

    /**
     * Method to set account on Quote before createProposal
     * @param accountId
     */
    private setAccount(accountId: string) {
        // L'HTTP ITERCEPTOR DELL'SDK AGGIUNGE UN EXTRA-PARAM NELL'HEADER DELLA
        // RICHIESTA SE NEL LOCALSTORAGE ESISTE IL VALORE X-ACCOUNT
        if (accountId) {
            localStorage.setItem('account', accountId);
        }
    }

    private setCartInfo(cart: EglCartLight): void {
        const cartInfo = new CartInfo();
        cartInfo.cartId = cart.Id;
        cartInfo.ownerDagCode = cart.egl_DAG_code;
        this.store.dispatch(setCartInfo({ p: cartInfo }));
    }

    private clearState(): void {
        this.store.dispatch(setOrderEntryState({ s: new OrderEntryState() }));
        this.store.dispatch(setContact({ c: null }));
        this.store.dispatch(setLead({ l: null }));
        this.store.dispatch(setCustomerInfo({ c: null }));
    }

    // fix 99763 plichi duplicati
    /**
     * @description: verifica che tra il salvataggio di una quote ed un altra intercorrino 30 secondi
     * @return: boolean
     */
    private canSaveQuote(): boolean {
        const timestamp = Date.now();
        if (timestamp > this.lastSaveQuote + 30000) {
            this.lastSaveQuote = timestamp;
            return true;
        } else {
            this.lastSaveQuote = timestamp;
            this.logger.warn('SaveQuote called twice');
            return false;
        }
    }

    private skipCC(flowType: FlowType) {
        return (
            flowType === FlowType.VariazioneCommerciale ||
            flowType === FlowType.ScontoStandalone ||
            flowType === FlowType.VariazioneTecnicaVerifiche ||
            isDomiciliationStandAlone(flowType) ||
            isChangeProduct(flowType) ||
            isAdministrativeSwitchIn(flowType)
        );
    }

    finalizeFlow_obs(quote: EglQuoteLight): Observable<EglQuoteLight> {
        return (
            this.skipPlicoGeneration.includes(quote.egl_signature_mode)
                ? of(null).pipe(
                      tap(() => {
                          this.logger.warn(
                              `Skip plico generation. Quote signature_mode was: ${quote.egl_signature_mode}`,
                              false
                          );
                      })
                  )
                : this.recursiveCallToOpenText_obs(quote)
        ).pipe(
            mergeMap(() => this.unlockFlow_obs(quote)),
            LoadingService.loaderOperator('Finalizzazione offerta')
        );
    }

    /**
     * @description: Attiva lo state model lato Apttus
     * @param quote: [quote] creata
     * @return: void
     */
    private unlockFlow_obs(quote: EglQuoteLight): Observable<EglQuoteLight> {
        if (!quote) return of(null);
        this.logger.info('Try to unlock flow');
        quote.egl_flowunlock = true;
        return this.eglQuoteLightSrv.update([quote], false).pipe(
            map((res) => ((res || [])[0] || quote) as EglQuoteLight),
            tap((quote) => this.logger.info(quote.egl_flowunlock ? 'Quote flow enabled' : 'NO Quote flow enabled')),
            catchError((err) => {
                this.logger.error(null, 'Error update quote on unlockFlow_obs', err, false);
                throw new Error('Error update quote on unlockFlow_obs');
            }),
            this.telemetrySrv.rxTelemetry('Update Quote')
        );
    }

    private retryWithDelay<T>(maxAttempts: number, delayMs: number, logMessage?: string): MonoTypeOperatorFunction<T> {
        return (source: Observable<T>): Observable<T> =>
            source.pipe(
                retryWhen((errors) =>
                    errors.pipe(
                        scan((acc, err) => ({ attempt: acc?.attempt + 1, err }), { attempt: 1, err: null }),
                        tap(({ attempt, err }) => {
                            if (logMessage) {
                                this.logger.warn(`${logMessage} ${attempt}/${maxAttempts}`);
                            }
                            if (attempt >= maxAttempts) {
                                throw err;
                            }
                        }),
                        delay(delayMs)
                    )
                )
            );
    }

    /**
     * @description: Metodo ricorsivo generazione plico. A causa del set 'async' lato Apttus
     * le informazioni della quote potrebbero non essere disponibili al momento
     * della richiesta di generazione, causando eccezione. Questo metodo ritenta
     * ricorsivamente, per un max di 15 volte ogni 3 sec, di generare il plico
     */
    recursiveCallToOpenText_obs(quote: EglQuoteLight, maxAttempts: number = 15): Observable<string> {
        return this.commonPrv.generateDocumentOT_obs(quote.Id, DocTypeOpenText.Plico).pipe(
            map((res) => {
                if (res?.status === StatusResponse.Success && res?.response?.fileUrl) {
                    return res?.response?.fileUrl;
                }
                throw new Error(`plico document not generated`);
            }),
            tap((fileUrl) => {
                this.store.dispatch(setPlicoUrl({ payload: fileUrl }));
            }),
            this.retryWithDelay(maxAttempts, 3000, 'Errore generazione plico'),
            this.telemetrySrv.rxTelemetry('Plico Generated'),
            LoadingService.loaderOperator('Generazione plico in corso')
        );
    }
}

export interface QuoteServiceEvent {
    quoteId: string;
    quoteName: string;
    status: QuoteEventStatus;
    urlPlicoOT: string;
}

export enum QuoteEventStatus {
    PlicoGenerated,
    GenericError,
    UnlockedFlow,
}

interface ActivationDateAndImmediateEffect {
    effectiveDate: moment.Moment;
    immediateEffect: boolean;
}
function replay(arg0: number): import('rxjs').OperatorFunction<any, unknown> {
    throw new Error('Function not implemented.');
}

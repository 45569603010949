import { AField, AObject, ATable } from '@congacommerce/core';
import { Type } from 'class-transformer';
import { EglProductLight } from '../product/egl-product-light';
import { EglCartPAVLight } from '../pav/egl-cart-p-a-v-light';
import { Address } from '../address/address';
import { EglAccountLocationLight } from '../user/egl-account-location-light';
import { AptLineStatus } from '../../../../enums/apttus/apt-line-status';

@ATable({
    sobjectName: 'Apttus_Config2__LineItem__c',
    aqlName: null,
})
export class EglCartItemLight extends AObject {
    constructor() {
        super();
        this.Product = null;
        this.IsPrimaryLine = null;
        this.AttributeValue = null;
        this.Product = null;
    }

    @AField({ soql: 'IsPrimaryLine' })
    IsPrimaryLine: boolean;

    @AField({ soql: 'Apttus_Config2__LocationId__r', expand: 'deep' })
    @Type(() => EglAccountLocationLight)
    Location: EglAccountLocationLight;

    @AField({
        soql: 'Apttus_Config2__AttributeValueId__r',
        expand: 'shallow',
    })
    @Type(() => EglCartPAVLight)
    AttributeValue: EglCartPAVLight;

    @AField({ soql: 'LineStatus' })
    LineStatus: AptLineStatus;

    @AField({ soql: 'LineType' })
    LineType: string;

    @AField({ soql: 'ParentBundleNumber' })
    ParentBundleNumber: number;

    @AField({ soql: 'LineNumber' })
    LineNumber: number;

    @AField({
        soql: 'Apttus_Config2__ProductId__r',
        expand: 'shallow',
    })
    @Type(() => EglProductLight)
    Product: EglProductLight;

    @AField({
        soql: 'egl_service_address_id__r',
        expand: 'shallow',
    })
    @Type(() => Address)
    egl_service_address_: Address; // indirizzo di spedizione (solo SMART-HOME)

    @AField({
        soql: 'egl_shipment_address_id__r',
        expand: 'shallow',
    })
    @Type(() => Address)
    egl_shipment_address: Address; // indirizzo di fornitura

    @AField({
        soql: 'Apttus_Config2__OptionId__r',
        expand: 'shallow',
    })
    @Type(() => EglProductLight)
    Option: EglProductLight;

    @AField({ soql: 'egl_supply_code' })
    egl_supply_code: string;
}

import { AField, AObject, ATable } from '@congacommerce/core';
import { Type } from 'class-transformer';
import { Address } from '../address/address';
import { EglOptionId } from '../order/option-id';
import { EglAccountLight } from '../user/egl-account-light';

@ATable({
    sobjectName: 'Apttus_Config2__AssetLineItem__c',
    aqlName: null,
})
export class EglAssetLineItem extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'egl_Conto_Cliente' })
    egl_Conto_Cliente: string;

    @AField({ soql: 'egl_pdf' })
    egl_pdf: string;

    @AField({ soql: 'egl_POD_PDR' })
    egl_POD_PDR: string;

    @AField({ soql: 'egl_isTerminable' })
    egl_isTerminable: string;

    @AField({
        soql: 'egl_service_address_id',
        expand: 'shallow',
    })
    @Type(() => Address)
    egl_service_address: Address;

    @AField({
        soql: 'Apttus_Config2__AccountId',
        expand: 'shallow',
    })
    @Type(() => EglAccountLight)
    Apttus_Config2__AccountId: EglAccountLight;

    @AField({
        soql: 'Apttus_Config2__OptionId',
        expand: 'shallow',
    })
    @Type(() => EglOptionId)
    Apttus_Config2__OptionId: EglOptionId;
}

import { AvaConfiguration } from './ava-configurations';
import versionInfo from '../../version-info.json';

export const environment: AvaConfiguration = {
    appVersion: `${versionInfo.version}.pre-prod3`,
    buildNumber: versionInfo.buildNumber,
    production: false,
    defaultImageSrc: './assets/images/default.png',
    defaultCountry: 'IT',
    defaultLanguage: 'it',
    enableErrorLogging: false,
    enableErrorReporting: false,
    enableMultiCurrency: false,
    enableQueryLogs: true,
    enablePerformanceLogs: true,
    defaultCurrency: 'EUR',
    bufferTime: 10,
    maxBufferSize: 5,
    disableBuffer: false,
    subqueryLimit: 10,
    disableCache: false,
    encryptResponse: false,
    cartRetryLimit: 3,
    productIdentifier: 'Id',
    type: 'Salesforce',
    debounceTime: 1000,
    proxy: 'https://apttus-proxy.herokuapp.com',
    useIndexedDB: true,
    expandDepth: 8,
    skipPricing: true,
    skipRules: false,
    pricingMode: 'turbo',
    packageNamespace: 'Apttus_WebStore',
    storefront: 'EGL',
    organizationId: '00D7a00000053l6', // preprod 3
    endpoint: 'https://preprod3-egl-apttus.cs189.force.com', // preprod 3
    auth: {
        clientId: '76d56f4b-77d7-46a4-b175-222d4a37f98c',
        tenantId: 'c16e514b-893e-4a01-9a30-b8fef514a650',
        redirectUri: 'https://salesapp-evq3.enigaseluce.com',
    },
};

/* tslint:disable:variable-name */
import { AField, AObject, ATable } from '@congacommerce/core';
import { AptCustomerType } from '../../../../enums/apttus/apt-customer-type';
@ATable({
    sobjectName: 'Account',
    aqlName: null,
})
export class EglAccountLight extends AObject {
    constructor() {
        super();
        this.Name = null;
        this.OwnerId = null;
        this.egl_BillingPostalCode = null;
        this.egl_BillingCity = null;
        this.egl_customer_address = null;
        this.egl_customer_address_line2 = null;
        this.BillingStreet = null;
        this.egl_customer_address_prefix = null;
        this.egl_customer_address_province = null;
        this.egl_customer_ateco2007 = null;
        this.egl_customer_fulladdress = null;
        this.egl_customer_street_number = null;
        this.egl_customer_streetmap_code = null;
        this.egl_Customer_Type = null;
        this.BillingStateCode = null;
        this.ShippingStateCode = null;
        this.egl_customer_name = null;
        this.egl_legal_form = null;
        this.egl_customer_segment = null;
        this.egl_customer_taxcode = null;
        this.egl_customer_fiscalcode = null;
    }

    //////////////////////////////////////////////////////////// CAMPI OTB
    @AField({ soql: 'Name' })
    Name: string;

    @AField({ soql: 'OwnerId' })
    OwnerId: string;

    //////////////////////////////////////////////////////////// END CAMPI OTB

    @AField({ soql: 'egl_BillingPostalCode' })
    egl_BillingPostalCode: string;

    @AField({ soql: 'egl_BillingCity' })
    egl_BillingCity: string;

    @AField({ soql: 'egl_customer_address' })
    egl_customer_address: string;

    @AField({ soql: 'egl_customer_address_line2' })
    egl_customer_address_line2: string;

    @AField({ soql: 'BillingStreet' })
    BillingStreet: string;

    @AField({ soql: 'egl_customer_address_prefix' })
    egl_customer_address_prefix: string;

    @AField({ soql: 'egl_customer_address_province' })
    egl_customer_address_province: string;

    @AField({ soql: 'egl_customer_ateco2007' })
    egl_customer_ateco2007: string;

    @AField({ soql: 'egl_customer_fulladdress' })
    egl_customer_fulladdress: string;

    @AField({ soql: 'egl_customer_street_number' })
    egl_customer_street_number: string;

    @AField({ soql: 'egl_customer_streetmap_code' })
    egl_customer_streetmap_code: number;

    @AField({ soql: 'egl_Customer_Type' })
    egl_Customer_Type: AptCustomerType; // 'Residenziale' | 'Microbusiness';

    @AField({ soql: 'BillingStateCode' })
    BillingStateCode: string;

    @AField({ soql: 'ShippingStateCode' })
    ShippingStateCode: string;

    //////////////////////////////////////////////////////////// MICROBUSINESS

    @AField({ soql: 'egl_customer_name' }) // Ragione Sociale
    egl_customer_name: string;

    @AField({ soql: 'egl_legal_form' }) // Forma Giuridica
    egl_legal_form: string;

    @AField({ soql: 'egl_customer_segment' }) // Segmento
    egl_customer_segment: string;

    @AField({ soql: 'egl_customer_taxcode' }) // PIVA
    egl_customer_taxcode: string;

    @AField({ soql: 'egl_customer_fiscalcode' }) // CF
    egl_customer_fiscalcode: string;

    //////////////////////////////////////////////////////////// END MICROBUSINESS
}

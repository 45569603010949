/* tslint:disable:variable-name */
import { ATable, AField, AObject } from '@congacommerce/core';
import { Type } from 'class-transformer';
import { EglProductLight } from '../product/egl-product-light';
import { EglQuotePAVLight } from '../pav/egl-quote-p-a-v-light';
import { AptLineStatus } from '../../../../enums/apttus/apt-line-status';

@ATable({
    sobjectName: 'Apttus_Proposal__Proposal_Line_Item__c',
    aqlName: null,
})
export class EglQuoteLineItemLight extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'IsPrimaryLine' })
    IsPrimaryLine: boolean;

    @AField({ soql: 'Apttus_QPConfig__LocationId__c' })
    Apttus_QPConfig__LocationId: string;

    @AField({ soql: 'egl_digitalcomm' })
    egl_digitalcomm: boolean;

    @AField({ soql: 'egl_immediate_effect' })
    egl_immediate_effect: boolean;

    @AField({ soql: 'egl_iscontextualsale' })
    egl_iscontextualsale: boolean;

    @AField({ soql: 'egl_commodity_typeofsale' })
    egl_commodity_typeofsale: string;

    @AField({ soql: 'AdjustedPrice' })
    AdjustedPrice: number;

    @AField({ soql: 'egl_precheck_description' })
    egl_precheck_description: string;

    @AField({ soql: 'egl_precheck_result' })
    egl_precheck_result: string;

    @AField({
        soql: 'Apttus_QPConfig__AttributeValueId__c',
        expand: 'shallow',
    })
    @Type(() => EglQuotePAVLight)
    AttributeValue: EglQuotePAVLight;

    @AField({
        soql: 'Apttus_Proposal__Product__c',
        expand: 'shallow',
    })
    @Type(() => EglProductLight)
    Product: EglProductLight;

    @AField({ soql: 'ConfigurationId' })
    ConfigurationId: string;

    @AField({ soql: 'egl_precheck_description__c', aql: 'precheckDescription' })
    precheckDescription: string;

    @AField({ soql: 'egl_precheck_result__c', aql: 'precheckResult' })
    precheckResult: string;

    @AField({ soql: 'LineStatus' })
    LineStatus: AptLineStatus;
}

import { Dictionary } from '../../../../common/interfaces/dictionary';
import { ItemMap } from '../../../../common/models/app/item-map';
import { RoutesPaths } from '../../../../common/config/routes-paths';
import { SegoeMDL2Font } from '../../../../common/enums/shared/segoe-mdl2-font';

//TODO Lorenzo - Come metto le label nel file delle lingue?
export const progressRouteMap = () => {
    const map: Dictionary<ItemMap> = {};
    map[`${RoutesPaths.TroubleShootingCommodityPower}`] = {
        label: 'CONDIZIONI DI VENDIBILITA',
        percent: 2,
        bigIcon: SegoeMDL2Font.ContactInfo,
    };
    map[`${RoutesPaths.TroubleShootingCommodityGas}`] = {
        label: 'CONDIZIONI DI VENDIBILITA',
        percent: 4,
        bigIcon: SegoeMDL2Font.ContactInfo,
    };
    map[RoutesPaths.TroubleShootingManutenzione] = {
        label: 'CONDIZIONI DI VENDIBILITA',
        percent: 10,
        bigIcon: SegoeMDL2Font.ContactInfo,
    };
    map[RoutesPaths.ModalitaAcquisizione] = {
        label: 'TIPO ACQUISIZIONE',
        percent: 12,
        bigIcon: SegoeMDL2Font.ContactInfo,
    };
    map[RoutesPaths.DatiGenerici] = {
        label: '<b>DATI ANAGRAFICI 1</b> di 2',
        percent: 15,
        bigIcon: SegoeMDL2Font.ContactInfo,
    };
    map[RoutesPaths.DatiContatto] = {
        label: '<b>DATI ANAGRAFICI 2</b> di 2',
        percent: 15,
        bigIcon: SegoeMDL2Font.ContactInfo,
    };
    map[RoutesPaths.Indirizzi] = {
        label: 'DATI FORNITURA - 1 di 2',
        percent: 20,
        bigIcon: SegoeMDL2Font.Rulers,
    };
    map[RoutesPaths.CodiceAteco] = {
        label: 'DATI FORNITURA - 1b di 2',
        percent: 25,
        bigIcon: SegoeMDL2Font.Rulers,
    };

    map[RoutesPaths.ConsumoLuce] = {
        label: 'DATI FORNITURA - 1 di 2',
        percent: 35,
        bigIcon: SegoeMDL2Font.Rulers,
    };
    map[RoutesPaths.PotenzaImpegnata] = {
        label: 'DATI FORNITURA - 1 di 2',
        percent: 35,
        bigIcon: SegoeMDL2Font.Rulers,
    };
    map[RoutesPaths.DatiPod] = {
        label: 'DATI FORNITURA - 2 di 2',
        percent: 35,
        bigIcon: SegoeMDL2Font.Rulers,
    };
    map[RoutesPaths.DecretoCasa] = {
        label: 'Decreto casa',
        percent: 35,
        bigIcon: SegoeMDL2Font.Rulers,
    };
    map[RoutesPaths.ConsumoGas] = {
        label: 'DATI FORNITURA - 2 di 2',
        percent: 35,
        bigIcon: SegoeMDL2Font.Rulers,
    };
    map[RoutesPaths.DatiPdr] = {
        label: 'DATI FORNITURA - 2 di 2',
        percent: 35,
        bigIcon: SegoeMDL2Font.Rulers,
    };

    map[RoutesPaths.DatiPagamento] = {
        label: 'DATI PAGAMENTO',
        percent: 45,
        bigIcon: SegoeMDL2Font.Connector,
    };

    map[RoutesPaths.Riepilogo] = { label: 'RIEPILOGO', percent: 50 };

    map[RoutesPaths.SceltaFirma] = {
        label: 'FIRMA',
        percent: 55,
        bigIcon: SegoeMDL2Font.Label,
    };

    map[RoutesPaths.TipoEsecuzione] = {
        label: 'ESECUZIONE ANTICIPATA',
        percent: 75,
        bigIcon: SegoeMDL2Font.LightningBolt,
    };
    map[RoutesPaths.PrivacyDatiPersonali] = {
        label: 'PRIVACY E CONSENSI',
        percent: 82,
        bigIcon: SegoeMDL2Font.Label,
    };
    map[RoutesPaths.PrivacyConsensi] = {
        label: 'PRIVACY E CONSENSI',
        percent: 82,
        bigIcon: SegoeMDL2Font.Label,
    };
    map[RoutesPaths.FirmaPlico] = {
        label: 'PLICO',
        percent: 85,
        bigIcon: SegoeMDL2Font.Label,
    };
    map[RoutesPaths.FirmaPreviaStampa] = {
        label: 'FIRMA - Previa stampa',
        percent: 85,
        bigIcon: SegoeMDL2Font.Label,
    };
    map[RoutesPaths.FirmaVocalOrder] = {
        label: 'FIRMA - Vocal order',
        percent: 85,
        bigIcon: SegoeMDL2Font.Label,
    };
    map[RoutesPaths.FirmaCartacea] = {
        label: 'FIRMA - Contratto cartaceo',
        percent: 85,
        bigIcon: SegoeMDL2Font.Label,
    };
    map[RoutesPaths.FirmaGrafometrica] = {
        label: 'FIRMA - Grafometrica',
        percent: 85,
        bigIcon: SegoeMDL2Font.Label,
    };
    map[RoutesPaths.FirmaDoiFull] = {
        label: 'FIRMA - DOI',
        percent: 85,
        bigIcon: SegoeMDL2Font.Label,
    };
    map[RoutesPaths.UploadDocumento] = {
        label: 'UPLOAD DOCUMENTI',
        percent: 85,
        bigIcon: SegoeMDL2Font.WebCam,
    };
    map[RoutesPaths.SceltaContatto] = {
        label: 'CERTIFICAZIONE CONTATTO',
        percent: 95,
        bigIcon: SegoeMDL2Font.Fingerprint,
    };
    map[RoutesPaths.VerificaCodice] = {
        label: 'CERTIFICAZIONE CONTATTO',
        percent: 95,
        bigIcon: SegoeMDL2Font.Fingerprint,
    };
    map[RoutesPaths.VariazioneTecnica] = {
        label: 'Richiesta Variazione',
        percent: 75,
        bigIcon: SegoeMDL2Font.Fingerprint,
    };
    map[RoutesPaths.FirmaOk] = { label: 'FIRMA', percent: 100 };

    //CESSAZIONE
    map[RoutesPaths.TerminationCosts] = { label: 'Costi di cessazione', percent: 15 };
    map[RoutesPaths.TerminationMortisCausa] = { label: 'Cessazione per mortis causa', percent: 30 };
    map[RoutesPaths.TerminationContacts] = { label: 'Contatti del richiedente', percent: 45 };
    map[RoutesPaths.TerminationDate] = { label: 'Data cessazione', percent: 63 };
    map[RoutesPaths.TerminationAppointment] = {
        label: 'Indicazioni per il tecnico (in caso di appuntamento)',
        percent: 80,
    };
    // map[RoutesPaths.TerminationResume] = { label: 'Riepilogo', percent: 95 };

    //PARTNERSHIP FASTWEB
    map[RoutesPaths.PsFastwebPersonalData] = {
        label: 'Partnership con Fastweb - 1 di 2',
        percent: 50,
    };
    map[RoutesPaths.PsFastwebActiveContracts] = { label: 'Partnership con Fastweb - 2 di 2', percent: 100 };

    //SUSPENSION
    map[RoutesPaths.SuspensionForm] = { label: 'Inserimento dati - 1 di 1', percent: 100 };
    map[RoutesPaths.SuspensionCancelForm] = { label: 'Inserimento dati - 1 di 1', percent: 100 };

    // TODO ACTIVATION
    map[RoutesPaths.TroubleShootingAttivazione] = { label: 'Attivazione', percent: 50 };

    return map;
};

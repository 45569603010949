import { CurrencyPipe, DatePipe, DecimalPipe, JsonPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AutoFormatPipe } from './auto-format.pipe';

@NgModule({
    imports: [TranslateModule.forChild()],
    exports: [AutoFormatPipe],
    declarations: [AutoFormatPipe],
    providers: [DecimalPipe, CurrencyPipe, DatePipe, JsonPipe, AutoFormatPipe],
})
export class CommonPipesModule {}

import { AField, AObject, ATable } from '@congacommerce/core';

@ATable({
    sobjectName: 'egl_address',
})
export class Address extends AObject {
    @AField({ soql: 'egl_fulladdress' })
    egl_fulladdress: number;

    @AField({ soql: 'egl_postalcode' })
    egl_postalcode: number;

    @AField({ soql: 'egl_city' })
    egl_city: number;

    @AField({ soql: 'egl_province' })
    egl_province: number;
}

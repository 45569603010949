import { AppConfig, Endpoints } from '../models/app/app-config';
import { PrivateConfigurationService } from '../services/shared/private-configuration.service';

export abstract class BaseProvider {
    private endPoints: Endpoints;
    egonAppKey: string;
    appConfig: AppConfig;
    protected constructor(protected configSrv: PrivateConfigurationService) {
        this.appConfig = this.configSrv.config;
        this.endPoints = this.configSrv.config.endpoints;
        this.egonAppKey = this.configSrv.config.appKeys.egonAppKey;
    }

    getApexApiUrl(api: ApexApi): string {
        return `${this.endPoints.apex.baseUrl}/${this.endPoints.apex.apis[api]}`;
    }

    getApiMngApiUrl(api: ApiMngApi): string {
        return `${this.endPoints.apiMgt.baseUrl}/${this.endPoints.apiMgt.apis[api]}`;
    }

    getApttusApiUrl(api: ApttusApi): string {
        return `${this.endPoints.apttus.baseUrl}/${this.endPoints.apttus.apis[api]}`;
    }

    getEgonApiUrl(api: EgonApi): string {
        return `${this.endPoints.egon.baseUrl}/${this.endPoints.egon.apis[api]}`;
    }

    getEgonJsonApiUrl(api: EgonJsonApi): string {
        return `${this.endPoints.egonJson.baseUrl}/${this.endPoints.egonJson.apis[api]}`;
    }
}

export enum ApexApi {
    PaperCheck = 'paperCheck',
    CreateQuoteEntities = 'createQuoteEntities',
    DoApttusAction = 'doApttusAction',
    ManageQuoteList = 'manageQuoteList',
    ActionsFilters = 'getActionsFilters',
    CheckItems = 'checkItems',
    ScoreCard = 'scoreCard',
    ProductsDates = 'productsDates',
    GetSalesUpState = 'getSalesUpState',
    SaveSalesUpState = 'saveSalesUpState',
    MonitorQuote = 'monitorQuote',
    CallToSaleabilityMatrix = 'callToSaleabilityMatrix',
    MailBlacklist = 'mailBlacklist',
    Delete = 'deleteBOReason',
    ChangeCartOwner = 'changeCartOwner',
    CheckCartDual = 'checkCartDual',
    ProductOptsMatrix = 'productOptsMatrix',
    Voltura = 'voltura',
    GetModifiedAttributes = 'getModifiedAttributes',
    GetScontiList = 'getScontiList',
    CommodityForCombinedSale = 'commodityForCombinedSale',
    UpdateAtecoCode = 'updateAtecoCode',
    CheckRataFissa = 'checkRataFissa',
    CheckAsset = 'checkAsset',
    CreateCartFromCart = 'CreateCartFromCart',
}

export enum ApiMngApi {
    GetAssetDetails = 'getAssetDetails',
    GetProductListById = 'getProductListById',
    GetProductDetails = 'getProductDetails',
    CpChangeCommerciale = 'cpChangeCommerciale',
    CheckIban = 'checkIban',
    GenerateDocOT = 'generateDocOT',
    GenerateScriptOT = 'generateScriptOT',
    DownloadMonitorCSV = 'downloadMonitorCSV',
    CheckPdf = 'checkPdf',
    StubGenerateDocOT = 'stubGenerateDocOT',
    StubGenerateScriptOT = 'stubGenerateScriptOT',
    OldCreditCheck = 'oldCreditCheck',
    UploadZipRecordings = 'uploadZipRecordings',
    RetrieveAttachmentList = 'retrieveAttachmentList',
    RetrieveAttachment = 'retrieveAttachment',
    ReplaceAttachmentBO = 'replaceAttachmentBO',
    RetrieveRegistrationData = 'retrieveRegistrationData',
    RetrieveDataSalesUp = 'retrieveDataSalesUp', // old
    RetrieveCustomerData = 'retrieveCustomerData',
    RetrieveDataCreditCheck = 'retrieveDataCreditCheck',
    ComuneAffinityByIstat = 'comuneAffinityByIstat',
    RecuperaDocumentazione = 'recuperaDocumentazione',
    SfaOfferingRetrieve = 'sfaOfferingRetrieve',
    OcrInvia = 'ocrInvia',
    OcrFeedback = 'ocrFeedback',
    UploadIdentityCard = 'uploadIdentityCard',
    CheckPDR = 'checkPDR',
    CheckPOD = 'checkPOD',
    UploadMp3VocalOrder = 'uploadMp3VocalOrder',
    UploadPlicoSigned = 'uploadPlicoSigned',
    UploadAttachments = 'uploadAttachments',
    DeleteDocumentazione = 'deleteDocumentazione',
    UploadSelfCertification = 'uploadSelfCertification',
    CalculateFastwebCluster = 'calculateFastwebCluster',
    CreateSuspendOrder = 'createSuspendOrder',
    CancelSuspendOrder = 'cancelSuspendOrder',
    TerminationCostsRetrieve = 'terminationCostsRetrieve',
    CreateInterruptionOrder = 'createInterruptionOrder',
    CancelInterruptionOrder = 'cancelInterruptionOrder',
    CancelOrAmend = 'cancelOrAmend',
    GetPaymentTools = 'getPaymentTools',
    CheckPDRActivation = 'checkPDRActivation',
    CheckPODActivation = 'checkPODActivation',
    CreateResumeOrder = 'createResumeOrder',
    StandaloneDiscount = 'standalonediscount',
    UploadBulkOrder = 'uploadBulkOrder',
    CheckCoupon = 'checkCoupon',
    RetrieveDataCustomer360 = 'retrieveDataCustomer360',
    CreateQuoteFromCart = 'createCartFromCart',
    CancelOrder = 'cancelOrder',
}

export enum ApttusApi {
    CreateProposal = 'createProposal',
    Update = 'update',
    Query = 'query',
    Generic = 'generic',
    CheckCap = 'checkCap',
    QueryData = 'queryData',
}

export enum EgonApi {
    SuggestCity = 'suggestCity',
    SuggestStreet = 'suggestStreet',
    SuggestNumber = 'suggestNumber',
}

export enum EgonJsonApi {
    Normalize = 'normalize',
    SearchByZip = 'searchByZip',
    SearchByCity = 'searchByCity',
    SearchByStreetNumber = 'searchByStreetNumber',
}

import { PdrInfoLocation } from '../../../../store/models/order-entry-state';
import { BaseApiResponse } from '../../../../common/interfaces/base-api-response';

export class CheckPodPdrActivationResponse extends BaseApiResponse {
    response?: ActivationResponse;
}

export interface ActivationResponse {
    CommonResponse: ActCommonResponse;
    Stato: ActStatus;
    interrogaPODResponse?: PodActResponse;
    interrogaPDRResponse?: PdrActResponse;
    interrogaMisuratoreResponse?: MeterActResponse;
    interrogaIndirizzoResponse?: AddressActResponse;
}

export interface ActCommonResponse {
    ResponseType?: string;
    ResponseCode: string;
    ResponseDesc: string;
}

export interface PodActResponse {
    EsitoRichiesta: {
        identificativiRichiesta: {
            PIVA_UTENTE: string;
            PIVA_DISTR: string;
            COD_PRAT_UTENTE: string;
            COD_PRAT_DISTR: string;
        };
        Ammissibilita?: {
            VERIFICA_AMM?: string;
            COD_CAUSALE?: string;
            MOTIVAZIONE?: string;
        };
        DatiTecnici: {
            Misuratore: {
                MATR_MIS_ATTIVA?: string;
                MISURATORE_ELETTRONICO?: string;
            };
            POD: {
                COD_POD?: string;
                PRESA?: string;
                EnelTel?: string;
                TENSIONE?: string;
                POT_FRANCHIGIA?: string;
                ULTIMA_POT_DISP?: string;
            };
        };
        Fornitura: {
            UbicazioneFornituraPod?: string;
            TOPONIMO?: string;
            VIA?: string;
            CIV?: string;
            CAP?: string;
            LOCALITA?: string;
            PROV?: string;
            SCALA?: string;
            PIANO?: string;
            INTERNO?: string;
            ISTAT?: string;
        };
    };
}

export interface PdrActResponse {
    DatiMessaggio?: MessageInfo;
    EsitoRichiesta?: ResponseInfo;
    DatiPDR?: PdrInfoLocation;
    DatiMisuratore?: MeterInfo;
}

export class MeterActResponse {
    DatiMessaggio?: MessageInfo;
    EsitoRichiesta?: ResponseInfo;
    DatiPDR?: PdrInfoLocation[];
    DatiMisuratore?: MeterInfo[];
}

export interface AddressActResponse {
    DatiMessaggio?: MessageInfo;
    EsitoRichiesta?: ResponseInfo;
    DatiPDR?: PdrInfoLocation[];
}

export interface MessageInfo {
    CodVend?: string;
    UserVend?: string;
    UffVend?: string;
    CodDistr?: string;
}

export interface ResponseInfo {
    CodErrore: string;
    DesErrore: string;
    ServerErrore: string;
}

export interface MeterInfo {
    MatrContEsito: string;
    PDRDistrContEsito?: string;
    ClasseContEsito?: string;
    PortataMinEsito?: string;
    PortataMaxEsito?: string;
    CostrContEsito?: string;
    CalibroContEsito?: string;
    CifreContEsito?: string;
    TipTecnContEsito?: string;
    AnnoFabContEsito?: string;
    StatoContEsito?: string;
    ProprContEsito?: string;
    CorretVolContEsito?: string;
}

export enum ActStatus {
    Disalimentato = 'DISALIMENTATO',
    DisalimentatoA40 = 'DISALIMENTATO - A40',
    Libero = 'LIBERO',
    NonAttivabile = 'NON ATTIVABILE',
    Inesistente = 'INESISTENTE',
    Attivo = 'ATTIVO',
    Errore = 'ERRORE',
    ErroreIndirizzo = 'ERRORE INDIRIZZO', // Custom - Popup P06
}
